<h4 class="mb-3">{{title}}</h4>
<div class="w-100 justify-content-center d-flex my-2">
    <div class="text-center">
        <dx-button-group *ngIf="initialBillingPeriod"
                         [items]="BillingPeriodButtons"
                         keyExpr="billingPeriod"
                         class="pill-button-group"
                         buttonTemplate="buttonTemplate"
                         [selectedItemKeys]="initialBillingPeriod"
                         (onItemClick)="selectionChanged.emit($event.itemData.billingPeriod)">
            <div class="d-block py-1 px-5 rounded-pill"
                 *dxTemplate="let buttonData of 'buttonTemplate'"
                 [attr.data-test]="'payment-frequency-' + buttonData.billingPeriod">
                {{buttonData.text}}
            </div>
        </dx-button-group>
        <div class="row m-0">
            <div class="col-6 offset-6 text-center">
                <small>
                    <b>{{account.pricingModel?.annualDiscountPercentage}}% off!</b>
                </small>
            </div>
        </div>
        <div class="my-3 d-flex flex-column align-items-center">
            <div class="d-flex align-items-end">
                <h1 class="mb-0">{{account.extensions.monthlySubscriptionCost | currency:undefined : 'symbol' : '1.0-0'}}
                </h1>
                <h3 class="mb-0">/month</h3>
            </div>
            <span class="my-2">Annual total: {{account.extensions.annualSubscriptionCost | currency:undefined : 'symbol' : '1.0-0'}}</span>
            <small>HQ is billed in {{account.currency?.shortName ?? 'AUD'}}, excl GST.</small>
        </div>
    </div>
</div>
