import { RawClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { clientConfiguration as prodClientConfiguration } from "@common/configuration/environment.base.prod";
import { clientConfiguration } from "./environment.base";

export const environment = {
    production: true,
    clientConfiguration: Object.assign({}, clientConfiguration, prodClientConfiguration, {
        AltoBaseUri: "https://hq.theadaptway.com",
        AnalyticsProductId: "6eb221e302702cd685b073c682ca43f0b6b382a7",
        ApiBaseUri: "https://hq-api.theadaptway.com",
        GoogleClientId: "799098505879-3p8hsiebaktpehbm2hs568fjs21iqb81.apps.googleusercontent.com",
        SentryDsn: "https://94c020686e854fdb91975bea650c22eb@o96030.ingest.sentry.io/4505033724329984",
        ServiceApiBaseUri: "https://hq-service.theadaptway.com",
        CalendlyBaseUri: "https://calendly.com/adapt-gabe",
    } as Partial<RawClientConfiguration>),
};
