<div *adaptLoadingUntilFirstEmit="let account of account$">
    <div *ngIf="account!.extensions.isTrial; else normalSubscription">
        <p>We'd love to have you on board! Click below to find out more and start your adapt HQ subscription!</p>

        <button adaptButton="default"
                (click)="accountService.showSubscriptionDialog().subscribe()">Start subscription</button>
    </div>

    <ng-template #normalSubscription>
        <div class="row">
            <div class="col-md-4">
                <adapt-configure-payment-method></adapt-configure-payment-method>
            </div>
            <div class="col-md-4">
                <adapt-configure-billing-details></adapt-configure-billing-details>
            </div>
            <div class="col-md-4">
                <adapt-configure-subscription></adapt-configure-subscription>
            </div>
        </div>

        <adapt-display-historical-invoices *ngIf="account"
                                           [organisation]="account.organisation"
                                           [displayAdvancedOptions]="false"></adapt-display-historical-invoices>
    </ng-template>
</div>
