/* eslint-disable max-classes-per-file */
import { CommonModule, DOCUMENT } from "@angular/common";
import { HttpClient, HttpClientModule, HttpRequest } from "@angular/common/http";
import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { provideDialogEventHandler } from "@common/ux/adapt-common-dialog/dialog-event-handler.interface";
import { AjaxRequestInterceptor, config, NamingConvention, Validator } from "breeze-client";
import { AjaxHttpClientAdapter } from "breeze-client/adapter-ajax-httpclient";
import { DataServiceWebApiAdapter } from "breeze-client/adapter-data-service-webapi";
import { ModelLibraryBackingStoreAdapter } from "breeze-client/adapter-model-library-backing-store";
import { UriBuilderODataAdapter } from "breeze-client/adapter-uri-builder-odata";
import { AjaxRequest } from "breeze-client/src/interface-registry";
import { switchMap, tap } from "rxjs/operators";
import { buildInfo } from "../build-info/ADAPT.BuildInfo";
import { BreezeService } from "./breeze.service";
import { EntityPersistentService } from "./entity-persistent.service";

const metadataCacheBustRequestInterceptor: AjaxRequestInterceptor = Object.assign(
    // AjaxRequest interface does not include the "request" param, it's added by AjaxHttpClientAdapter
    (req: AjaxRequest & { request: HttpRequest<null> }) => {
        // add a cache-busting query param for metadata
        if (req.request.url.endsWith("/Metadata")) {
            const params = req.request.params.set("v", buildInfo.sourceHash);
            req.request = req.request.clone({ params });
        }
    },
    // will only run on the first request (i.e. Metadata)
    { oneTime: true },
);

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    providers: [
        {
            provide: BreezeService,
            useFactory: (http: HttpClient, injector: Injector, entityPersistentService: EntityPersistentService) => {
                // breeze configuration that should just be run once
                ModelLibraryBackingStoreAdapter.register();
                UriBuilderODataAdapter.register();

                const ajaxAdapter = AjaxHttpClientAdapter.register(http);
                ajaxAdapter.requestInterceptor = metadataCacheBustRequestInterceptor;

                DataServiceWebApiAdapter.register();

                config.initializeAdapterInstance("modelLibrary", "backingStore", true);
                Validator.messageTemplates.required = "Enter a %displayName%";
                NamingConvention.camelCase.setAsDefault();

                return new BreezeService(injector, entityPersistentService);
            },
            deps: [HttpClient, Injector, EntityPersistentService],
        },
        {
            // initialise commonDataService on app initialise
            provide: APP_INITIALIZER,
            useFactory: (breezeService: BreezeService, commonDataService: CommonDataService, document: Document) => {
                return () => breezeService.initialisation$.pipe(
                    // hide the initial loading spinner once metadata is ready
                    tap(() => document.getElementById("initial-spinner")?.remove()),
                    switchMap(() => commonDataService.initialisation$),
                );
            },
            deps: [BreezeService, CommonDataService, DOCUMENT],
            multi: true,
        },
        provideDialogEventHandler(EntityPersistentService),
    ],
})
export class AdaptCommonDataModule { }
