import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { AdaptCommonModule } from "@common/lib/common.module";
import { registerGuidedTour } from "@common/lib/guided-tour/guided-tour-registrar";
import { provideSubscribeOrganisationPageRoute } from "@common/page-route-providers";
import { AdaptCommonRouteModule } from "@common/route/common-route.module";
import { RouteService } from "@common/route/route.service";
import { RouteEventsService } from "@common/route/route-events.service";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptChangeManagerModule } from "@common/ux/change-manager/change-manager.module";
import { ContentWithSplashImageModule } from "@common/ux/content-with-splash-image/content-with-splash-image.module";
import { AdaptDocumentSelectorModule } from "@common/ux/document-selector/document-selector.module";
import { AdaptSteppedProgressBarModule } from "@common/ux/stepped-progress-bar/stepped-progress-bar.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptEntitySyncModule } from "@org-common/lib/entity-sync/entity-sync.module";
import { AdaptErrorPageModule } from "@org-common/lib/error-page/error-page.module";
import { AdaptEventTrackingModule } from "@org-common/lib/event-tracking/event-tracking.module";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptMeetingsSharedModule } from "@org-common/lib/meetings/meetings-shared.module";
import { AdaptMyOrganisationsModule } from "@org-common/lib/my-organisations/my-organisations.module";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptOrganisationModule } from "@org-common/lib/organisation/organisation.module";
import { AdaptOrgCommonShellModule } from "@org-common/lib/shell/org-common-shell.module";
import { AdaptStrategicGoalsModule } from "@org-common/lib/strategic-goals/strategic-goals.module";
import { CompetitorsAnalysisTour } from "@org-common/lib/strategic-inputs/competitor-analysis-tour";
import { AdaptStrategicInputsModule } from "@org-common/lib/strategic-inputs/strategic-inputs.module";
import { AdaptStrategyBoardModule } from "@org-common/lib/strategy-board/strategy-board.module";
import { AdaptStrategyDashboardPageModule } from "@org-common/lib/strategy-dashboard-page/strategy-dashboard-page.module";
import { OrganisationDiagnosticAuthService } from "@org-common/lib/survey/organisation-diagnostic/organisation-diagnostic-auth.service";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { AdaptCommonTeamsModule } from "@org-common/lib/teams/common-teams.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { IntercomModule } from "@supy-io/ngx-intercom";
import { AdaptAltoAboutModule } from "./about/about.module";
import { AdaptAccountModule } from "./account/account.module";
import { SignupPageRoute } from "./account/signup-page/signup-page.component";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AdaptCoachAccessModule } from "./coach-access/coach-access.module";
import { AdaptConfigurationModule } from "./configuration/configuration.module";
import { AdaptNavigationModule } from "./navigation/navigation.module";
import { AdaptAltoOrganisationModule } from "./organisation/organisation.module";
import { AdaptPersonModule } from "./person/person.module";
import { AdaptPurposeVisionModule } from "./purpose-vision/purpose-vision.module";
import { registerRouteEventsHandler } from "./route-events-handler";
import { AdaptSearchModule } from "./search/search.module";
import { AdaptAltoSidebarModule } from "./sidebar/sidebar.module";
import { AdaptAltoTeamModule } from "./team/team.module";
import { AlignOwnersVisionWrapUpTour } from "./tours/align-ownership-with-vision";
import { AltoShellTour } from "./tours/alto-shell-tour";
import { AttachBullseyeStatementsTour as AttachBullseyeStatementsFeatureTour } from "./tours/attach-bullseye-statements-tour";
import { AttachInputsTour as AttachInputsFeatureTour } from "./tours/attach-inputs-tour";
import { BullseyeWrapUpTour } from "./tours/bullseye-tour";
import { CreateKeyResultsForObjectiveFeatureTour } from "./tours/create-key-results-for-obj-tour";
import { HealthCheckWrapUpTour } from "./tours/health-check-tour";
import { ObjectiveFeatureTour as SetFirstObjectivesWrapUpTour } from "./tours/objective-feature-tour";
import { OwnersWhyWrapUpTour } from "./tours/owners-why-tour";
import { PurposeStatementTour as PurposeStatementWrapUpTour } from "./tours/purpose-statement-tour";
import { RecordAnchorsTour as RecordAnchorsFeatureTour } from "./tours/record-anchors-tour";
import { RecordObjectivesFeatureTour } from "./tours/record-objectives-tour";
import { SetValuesWrapUpTour } from "./tours/set-values-tour";
import { StrategicAnchorsWrapUpTour } from "./tours/strategic-anchors-wrap-up-tour";
import { StrategicGoalsWrapUpTour } from "./tours/strategic-goals-tour";
import { StrategicInputsWrapupTour } from "./tours/strategic-inputs-tour";
import { StrategicThemesWrapUpTour } from "./tours/strategic-themes-tour";
import { SWTTour as SWTFeatureTour } from "./tours/swt-tour";
import { TourIdentifier } from "./tours/tour-identifier.enum";
import { VisionStatementTour as VisionStatementWrapUpTour } from "./tours/vision-statement-tour";
import { AdaptAltoWorkflowsModule } from "./workflows/workflows.module";

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,

        // AppId won't be accessible at this time due to async config fetching
        // (also can't use functions in decorators) so do in constructor instead.
        IntercomModule.forRoot({
            appId: "",
        }),

        AdaptCommonModule.forRoot(),
        AdaptCommonRouteModule,
        AdaptChangeManagerModule,
        AdaptShellModule,
        AdaptButtonModule,
        ContentWithSplashImageModule,
        AdaptDocumentSelectorModule.forRoot(),
        AdaptEventTrackingModule.forRoot(),
        AdaptNavigationModule.forRoot(),
        AdaptAltoSidebarModule,
        AdaptMyOrganisationsModule.forRoot(),

        AdaptOrganisationModule,
        AdaptConfigurationModule,
        AdaptSurveyModule,
        AdaptAuthorisationModule,
        AdaptEntitySyncModule,
        AdaptChangeManagerModule,
        AdaptStrategyBoardModule,
        AdaptStrategicGoalsModule,
        AdaptStrategicInputsModule,
        AdaptStrategyDashboardPageModule,

        AdaptAccountModule,
        AdaptPersonModule,
        AdaptAltoWorkflowsModule,
        AdaptAltoOrganisationModule,
        AdaptAltoTeamModule,
        AdaptSearchModule,
        AdaptSteppedProgressBarModule,
        AdaptObjectivesModule,
        AdaptCommonTeamsModule,
        AdaptKanbanModule,
        AdaptMeetingsSharedModule,
        AdaptPurposeVisionModule,
        AdaptValuesConstitutionModule,
        AdaptAltoAboutModule,
        AdaptOrgCommonShellModule,
        AdaptErrorPageModule,
        AdaptCoachAccessModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [RouteEventsService, RouteService, AdaptCommonDialogService],
            useFactory: registerRouteEventsHandler,
        },
        provideSubscribeOrganisationPageRoute(SignupPageRoute),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    public constructor(
        surveyService: SurveyService,
        authService: AuthorisationService,
    ) {
        surveyService.registerLearnMoreUrlForSurveyType(SurveyType.OrganisationDiagnostic, ImplementationKitService.GetArticleLink(ImplementationKitArticle.HealthCheckFeatureHelp));
        OrganisationDiagnosticAuthService.registerAccessVerifiers(authService);

        registerGuidedTour(TourIdentifier.HealthCheckWrapUpTour, HealthCheckWrapUpTour);
        registerGuidedTour(TourIdentifier.BullseyeWrapUpTour, BullseyeWrapUpTour);
        registerGuidedTour(TourIdentifier.StrategicThemesWrapUpTour, StrategicThemesWrapUpTour);
        registerGuidedTour(TourIdentifier.OwnersWhyWrapUpTour, OwnersWhyWrapUpTour);
        registerGuidedTour(TourIdentifier.PurposeStatementWrapUpTour, PurposeStatementWrapUpTour);
        registerGuidedTour(TourIdentifier.VisionStatementWrapUpTour, VisionStatementWrapUpTour);
        registerGuidedTour(TourIdentifier.SetValuesWrapUpTour, SetValuesWrapUpTour);
        registerGuidedTour(TourIdentifier.AlignOwnersVisionWrapUpTour, AlignOwnersVisionWrapUpTour);
        registerGuidedTour(TourIdentifier.CompetitorsAnalysisTour, CompetitorsAnalysisTour);
        registerGuidedTour(TourIdentifier.StrategicInputsWrapupTour, StrategicInputsWrapupTour);
        registerGuidedTour(TourIdentifier.SWTFeatureTour, SWTFeatureTour);
        registerGuidedTour(TourIdentifier.AttachInputsFeatureTour, AttachInputsFeatureTour);
        registerGuidedTour(TourIdentifier.RecordAnchorsFeatureTour, RecordAnchorsFeatureTour);
        registerGuidedTour(TourIdentifier.StrategicAnchorsWrapUpTour, StrategicAnchorsWrapUpTour);
        registerGuidedTour(TourIdentifier.AttachBullseyeStatementsFeatureTour, AttachBullseyeStatementsFeatureTour);
        registerGuidedTour(TourIdentifier.AltoShellTour, AltoShellTour);
        registerGuidedTour(TourIdentifier.RecordObjectivesFeatureTour, RecordObjectivesFeatureTour);
        registerGuidedTour(TourIdentifier.CreateKeyResultsForObjectiveFeatureTour, CreateKeyResultsForObjectiveFeatureTour);
        registerGuidedTour(TourIdentifier.ObjectiveFeatureTour, SetFirstObjectivesWrapUpTour);
        registerGuidedTour(TourIdentifier.StrategicGoalsWrapUpTour, StrategicGoalsWrapUpTour);
    }
}
