<adapt-collapsible-search-row [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [crumbTemplate]="crumbs">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex align-items-center me-2">
            <adapt-link-person [showImage]="true"
                               [showText]="false"
                               imageSize="sm"
                               [personId]="result.assigneeId"></adapt-link-person>

            <adapt-item-link class="ms-2"
                             [item]="item"
                             [showDialogLink]="false"></adapt-item-link>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">
            <adapt-highlighted-text [needle]="keyword"
                                    [haystack]="result.summary"></adapt-highlighted-text>
            <adapt-display-labels class="ms-2"
                                  [linkDisabled]="true"
                                  [labelLocations]="labelLocations"></adapt-display-labels>
        </div>
    </div>
</adapt-collapsible-search-row>

<ng-template #crumbs
             let-matched="match">
    <ng-container *ngIf="asMatch(matched) as match">
        <ng-container *ngFor="let crumb of match.field; let last = last">
            <span *ngIf="last && isComment(match) && match.extra; else crumbText">
                <adapt-link-person [showImage]="false"
                                   [showText]="true"
                                   [personId]="match.extra.personId"
                                   imageSize="xs"></adapt-link-person>
            </span>
            <ng-template #crumbText><span>{{crumb}}</span></ng-template>
            <span *ngIf="!last"
                  class="px-1">&gt;</span>
        </ng-container>
    </ng-container>
</ng-template>
