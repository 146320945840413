//
// *** THIS IS DUPLICATED IN e2e/common/utility/pathway.ts ***
//

export enum ImplementationKitArticle {
    // Generic articles
    ComingSoon = "2076703",
    DefaultScheduledMeetingConfirmation = "1880271",
    StartSubscription = "2494936",

    // Workflow content, ordered roughly by intended completion order
    OnboardingWelcome = "2315748",
    OnboardingSetUpProfile = "2306448",

    InviteBusinessLeadersOverview = "2319326",
    InviteBusinessLeaderAddLeaders = "2315751",
    InviteBusinessLeaderEnd = "2315752",

    UnderstandJourneyActivityBrief = "2317137",
    UnderstandJourneyActivityBriefPart2 = "2364934",
    UnderstandJourneyActivityBriefPart3 = "2364935",
    UnderstandJourneyOutcomes = "1931002",
    UnderstandJourneyAlternateSignup = "2385027",
    UnderstandJourneyWrapUp = "1931003",

    OrganisationHealthCheckInitiateSurvey = "1868272",
    OrganisationHealthCheckLaunchSurveyIntro = "1923371",
    OrganisationHealthCheckScheduleDebrief = "1868274",
    OrganisationHealthCheckRunDebrief = "1868276",

    OwnersWhyOverview = "2215743",
    DefineOwnersWhyActivityBrief = "2059766",
    DefineOwnersWhyStartBusiness = "2104882",
    DefineOwnersWhyStartBusinessPrefill = "2460044",
    DefineOwnersWhyBackground = "2104889",
    DefineOwnersWhyWhatsImportant = "2104890",
    DefineOwnersWhyStrengthsSkills = "2104891",
    DefineOwnersWhyOutcomes = "2059763",
    DefineOwnersWhyWrapUp = "2059765",

    PurposeOverview = "2215732",
    PurposeOutcomes = "2059692",
    PurposeWrapUp = "2059694",
    PurposeMeetingDescription = "2059701",
    PurposeMeetingPreWork = "2222540",

    ValuesOverview = "2215735",
    SetValuesOutcomes = "2059673",
    SetValuesWrapUp = "2059677",
    ValuesMeetingDescription = "2059609",
    ValuesMeetingPreWork = "2200710",
    CreateValueName = "1878965",
    CreateValueMeaning = "1878968",
    CreateValueBehaviours = "1880291",
    CreateValueReview = "1881940",

    AlignOwnersVisionOverview = "2220835",
    AlignOwnersVisionActivityBrief = "2076642",
    AlignOwnersVisionActivityBriefPart2 = "2496709",
    AlignOwnersVisionEconomicEngine = "2153727",
    AlignOwnersVisionHealthyCulture = "2153728",
    AlignOwnersVisionFinanceAndOwnership = "2153729",
    AlignOwnersVisionOrganisationDesign = "2153730",
    AlignOwnersVisionOutcomes = "2076637",
    AlignOwnersVisionWrapUp = "2076640",

    VisionOverview = ImplementationKitArticle.PurposeOverview,
    CraftVisionOutcomes = "2059724",
    CraftVisionMeetingDescription = "2059745",
    CraftVisionMeetingPreWork = "2200748",
    CraftVisionWrapUp = "2059725",

    ThemesOverview = "2220858",
    IdentifyStrategicThemesOutcomes = "2145630",
    IdentifyStrategicThemesWrapUp = "2145631",
    IdentifyStrategicThemesMeetingDescription = "2222553",
    IdentifyStrategicThemesMeetingPreWork = "2156123",

    InputsOverview = "2220861",
    GatherInputsOutcomes = "1877932",
    GatherInputsWrapUp = "2075420",
    GatherInputsMeetingDescription = "2075432",
    GatherInputsMeetingPreWork = "2154931",

    BullseyeOverview = "2220871",
    DefineBullseyePassionateAbout = "2016767",
    DefineBullseyeGoodAt = "2016768",
    DefineBullseyeCustomerNeed = "2016769",
    DefineBullseyeGetPaidFor = "2016770",
    BullseyeAttachStatements = "2153640",
    BullseyeSetCustomStatement = "2036531",
    BullseyeSetHalfway = "2221871",
    BullseyeSet = "2016772",
    BullseyeOutcomes = "2016762",
    BullseyeWrapUp = "2042061",
    BullseyeMeetingDescription = "2222564",
    BullseyeMeetingPreWork = "2222567",

    StrategicAnchorsOverview = "2220873",
    EditStrategicAnchorIntro = "2223273",
    DevelopStrategicAnchorsOutcomes = "2077860",
    DevelopStrategicAnchorsWrapUp = "2077864",
    DevelopStrategicAnchorsMeetingDescription = "2210221",
    DevelopStrategicAnchorsMeetingPreWork = "2210216",

    StrategicGoalsOverview = "2220876",
    StrategicGoalsOverviewEmbed = "2371772",
    SetStrategicGoalsOutcomes = "2077850",
    SetStrategicGoalsWrapUp = "2077853",
    SetStrategicGoalsMeetingDescription = "2222581",
    SetStrategicGoalsMeetingPreWork = "2210373",

    EstablishCadenceOutcomes = "1877931",
    EstablishCadenceWrapUp = "2009387",
    EstablishCadenceMeetingDescription = "2005485",
    EstablishCadenceMeetingPreWork = "2222595",
    EstablishCadenceIntro = "2397470",
    EstablishCadenceSetCadenceCycle = "2233568",

    EventTypeGuidanceSetFirstObjectives = "2464274",
    EventTypeGuidanceAnnualStrategy = "2464277",
    EventTypeGuidanceQuarterlyStrategy = "2464276",
    EventTypeGuidanceMonthlyStrategy = "2464278",
    EventTypeGuidanceOKRCheckIn = "2464281",

    ApplyGoodStewardshipOutcomes = "2077841",
    ApplyGoodStewardshipWrapUp = "2077844",
    ApplyGoodStewardshipMeetingDescription = "2222584",
    ApplyGoodStewardshipMeetingPreWork = "2222589",

    MeaningfulConversationsWrapUp = "2076660",

    // Platform guidance (page)
    AnalyseCvt = "1672616",
    AnalyseCvtDifferentials = "1672672",
    AnalyseCvtTimeline = "1672677",
    ApplyGoodStewardship = "2151684",
    BuildCulturalLeadership = "1514823",
    //Bullseye = "2075450",
    EffectiveStewardship = "1344169",
    ElaborateKeyFunctions = "1344181",
    EmployeeEngagementSurvey = "1332870",
    ExternalStakeholderDirectory = "1673640",
    HealthCheckFeatureHelp = "2274954",
    IntroducingObjectivesAndKeyResults = "1342659",
    ManageAccess = "1673643",
    ManagePeople = "1673642",
    ResilientBusinessAssessment = "1671322",
    ResilientBusinessGoals = "1672300",
    ResilientBusinessZones = "1514839",
    RoleClarity = "1514833",
    SystemiseYourBusiness = "1344182",
    UnderstandingTheEconomicEngine = "1312777",
    UsingKanbanBoards = "1344154",
    UsingTheAdaptTeamModel = "1514822",
    ValueGuidance = "1590408",
    ValuePurposeVision = "1672292",
    CoachAccess = "2371773",
    StrategyBoard = "2425780",

    TeamsOverview = "2231442",
    WorkOverview = "2231443",
    LabelsOverview = "2231451",
    PersonalProfileCategoriesOverview = "2231455",
    CalendarIntegrationOverview = "2215738",
    BillingOverview = "2215737",
    OrganisationProfileOverview = "2215736",

    TeamProfileOverview = "2231459",
    TeamPeopleOverview = "2231461",

    // Platform guidance (dialog)
    PeerCatchUpConnection = "1672445",
    PeerCatchUpContribution = "1672449",
    PeerCatchUpEngagement = "1672434",
    PeerCatchUpOverall = "1672453",
    PurposeEdit = "2202896",
    StrategicGoalLocationNameEdit = "2077827",
    StrategicGoalMeasurementsEdit = "2236464",
    StrategicGoalDescriptionEdit = "2236474",
    StrategicGoalReviewEdit = "2237935",
    StrategicInputAttach = "2145643",
    CompetitorAnalysisAttach = "2220805",
    StrategicInputSSKDTodo = "2138513",
    StrategicInputStrength = "2138508",
    StrategicInputTrend = "2138512",
    StrategicInputWeakness = "2138510",
    StrategicThemeIntro = "2128784",
    TeamPurposeEdit = "1943857",
    VisionEdit = "2202910",
    KeyResults = "2452437",

    ObjectivesOverview = "2224719",
    ObjectiveReview = "2578818",
    WritingGreatObjectives = "1440491",
    ObjectiveWhyImportant = "2586281",
    ObjectiveHowWeKnowSuccess = "2586285",
    ObjectiveCreationReview = "2586286",
    ObjectiveTitleGuidance = "2586279",
    ObjectiveMetadataGuidance = "2591412",
    ObjectiveDescriptionPrefilled = "2602901",

    CompetitorAnalysisWin = "2216484",
    CompetitorAnalysisLose = "2216486",
    CompetitorAnalysisCompete = "2216489",
    CompetitorAddIntroduction = "2217719",

    MeetingDecisionNote = "2228739",
    MeetingMinuteNote = "2228742",

    CalendarIntegrationPrivateEmail = "2298100",

    CareerValuation = "1481059",
    CulturalIndex = "1514819",
    PeerCatchUp = "1681007",
    Architecture = "1312850",
    LabelsEmbed = "1514849",
    TeamStewardship = "2224725",
}
