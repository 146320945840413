<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.EffectiveStewardship"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<div adaptToolbarContent>
    <button *ngIf="canEditTeamMeeting"
            adaptButtonIcon="add"
            [adaptBlockingClick]="createTeamMeeting"
            data-test="schedule-meetings-button">Schedule a meeting
    </button>
</div>
<ng-container *adaptLoading="!isActivated">
    <adapt-two-column-page *ngIf="hasMeeting; else noMeeting">
        <div left-column>
            <!-- no point showing if you can't create or edit meetings -->
            <adapt-meeting-integration-prompt *ngIf="canEditTeamMeeting"></adapt-meeting-integration-prompt>

            <div class="card">
                <div class="card-body">
                    <dx-scroll-view #meetingListScrollView
                                    showScrollbar="always">
                        <adapt-dashboard-element name="Ongoing meetings"
                                                 *ngIf="ongoingMeetings.length > 0">
                            <dx-list [dataSource]="ongoingMeetings"
                                     selectionMode="single"
                                     (onInitialized)="ongoingMeetingList = $event.component"
                                     (onSelectionChanged)="onSelectionChanged($event)"
                                     (onContentReady)="updateMeetingListSelection()"
                                     (onGroupRendered)="onGroupRendered($event)"
                                     [focusStateEnabled]="false"
                                     class="dx-list-boxed">
                                <adapt-display-meeting-summary *dxTemplate="let meeting of 'item'"
                                                               [selected]="selectedMeeting === meeting"
                                                               (elementSelected)="selectedMeetingElement = $event"
                                                               [meeting]="meeting"
                                                               [preWorkClickable]="false"
                                                               [showIcon]="true"></adapt-display-meeting-summary>
                            </dx-list>
                        </adapt-dashboard-element>

                        <adapt-dashboard-element name="Upcoming meetings"
                                                 *ngIf="upcomingMeetingsRaw.length > 0">
                            <dx-list [dataSource]="upcomingMeetings"
                                     selectionMode="single"
                                     (onInitialized)="upcomingMeetingList = $event.component"
                                     (onSelectionChanged)="onSelectionChanged($event)"
                                     (onContentReady)="updateMeetingListSelection()"
                                     (onGroupRendered)="onGroupRendered($event)"
                                     [focusStateEnabled]="false"
                                     [grouped]="true"
                                     [collapsibleGroups]="true"
                                     class="dx-list-boxed">
                                <div *dxTemplate="let group of 'group'">{{group.key.label}}</div>
                                <adapt-display-meeting-summary *dxTemplate="let group of 'item'"
                                                               [selected]="selectedMeeting === group.meeting"
                                                               (elementSelected)="selectedMeetingElement = $event"
                                                               [meeting]="group.meeting"
                                                               [preWorkClickable]="false"
                                                               [showIcon]="true"
                                                               (meetingStarted)="selectedMeeting = group.meeting"></adapt-display-meeting-summary>
                            </dx-list>
                        </adapt-dashboard-element>

                        <adapt-dashboard-element name="Past meetings"
                                                 *ngIf="endedMeetingsRaw.length > 0">
                            <dx-list [dataSource]="endedMeetings"
                                     selectionMode="single"
                                     (onInitialized)="endedMeetingList = $event.component"
                                     (onSelectionChanged)="onSelectionChanged($event)"
                                     (onContentReady)="updateMeetingListSelection()"
                                     (onGroupRendered)="onGroupRendered($event)"
                                     [focusStateEnabled]="false"
                                     [grouped]="true"
                                     [collapsibleGroups]="true"
                                     [searchEnabled]="true"
                                     searchExpr="meeting.name"
                                     class="dx-list-boxed">
                                <dxo-search-editor-options [onInitialized]="onEndedMeetingsListSearchInitialised"></dxo-search-editor-options>
                                <div *dxTemplate="let group of 'group'">{{group.key.label}}</div>
                                <adapt-display-meeting-summary *dxTemplate="let group of 'item'"
                                                               [selected]="selectedMeeting === group.meeting"
                                                               (elementSelected)="selectedMeetingElement = $event"
                                                               [meeting]="group.meeting"
                                                               [preWorkClickable]="false"
                                                               [showIcon]="true"></adapt-display-meeting-summary>
                            </dx-list>
                        </adapt-dashboard-element>
                    </dx-scroll-view>
                </div>
            </div>
        </div>

        <div right-column>
            <ng-container *ngIf="selectedMeeting">
                <adapt-meeting-details [meeting]="selectedMeeting"
                                       (meetingChange)="updateData$.next()"
                                       (meetingDeleted)="onMeetingDeleted()"></adapt-meeting-details>
            </ng-container>
        </div>
    </adapt-two-column-page>
    <ng-template #noMeeting>
        <adapt-call-to-action learnMoreUrl="https://support.adaptbydesign.com.au/teams/good-team-stewardship">
            <adapt-call-to-action-title>Team meetings</adapt-call-to-action-title>

            <p>
                A good meeting will have clear guidance. As part of this, facilitate the team to determine what that is and capture on the
                team dashboard. For each meeting type, they need clarity on the purpose of the meeting.
            </p>

            <p>
                The frequency and duration of the meeting will clarify an appropriate cadence and time to devote to the meeting. Guide the
                team on what is best for each meeting type and try not to let them shorten the meetings – each is really important.
            </p>

            <!-- Don't have to check for meetings feature as this page won't be loaded without it -->
            <button adaptCallToActionButton
                    *adaptIfAuthorised="editTeamKanban; Entity:team"
                    adaptButtonIcon="add"
                    [adaptBlockingClick]="createTeamMeeting">
                Add team meeting
            </button>
        </adapt-call-to-action>
    </ng-template>
</ng-container>
