import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { combineLatest, merge, Subject } from "rxjs";
import { debounceTime, filter, finalize, startWith, switchMap } from "rxjs/operators";
import { KanbanService } from "../../kanban.service";
import { KanbanAuthService } from "../../kanban-auth.service";
import { KanbanNavigationService } from "../../kanban-navigation.service";
import { KanbanUiService } from "../../kanban-ui.service";

@Component({
    selector: "adapt-item-action-menu",
    templateUrl: "./item-action-menu.component.html",
})
export class ItemActionMenuComponent extends BaseComponent implements OnChanges {
    @Input() public item!: Item;
    @Output() public itemReopened = new EventEmitter<Item>();
    @Output() public itemClosed = new EventEmitter<Item>();
    @Output() public itemMoved = new EventEmitter<Item>();
    @Output() public itemDeleted = new EventEmitter<Item>();

    @Input() public showOpenOption = false;

    // other items in column are needed so moving within column works as expected
    @Input() public columnItems?: Item[];
    @Input() public showColumnMoveOptions = false;

    @Output() public dialogOpened = new EventEmitter<Item>();
    @Output() public dialogClosed = new EventEmitter<Item>();

    @ViewChild("labelMenuTemplate") public set labelMenuTemplateSetter(template: TemplateRef<any>) {
        this.labelMenuItem = LabellingService.getLabelMenuEntry(template);
        this.triggerMenuUpdate$.next();
    }

    public itemMenu: IAdaptMenuItem[] = [];
    private labelMenuItem?: IAdaptMenuItem;
    private triggerMenuUpdate$ = new Subject<void>();

    public constructor(
        private kanbanService: KanbanService,
        private kanbanUiService: KanbanUiService,
        private dialogService: AdaptCommonDialogService,
        private meetingsService: MeetingsService,
        kanbanAuthService: KanbanAuthService,
        kanbanNavService: KanbanNavigationService,
        rxjsBreezeService: RxjsBreezeService,
        authService: AuthorisationService,
    ) {
        super();

        this.triggerMenuUpdate$.pipe(
            startWith(undefined),
            filter(() => !!this.item),
            switchMap(() => combineLatest([
                kanbanNavService.getItemUrl(this.item),
                kanbanAuthService.hasEditAccessToItem(this.item),
                authService.getHasAccess(CommonTeamsAuthService.ViewAnyTeamMeeting),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([itemUrl, canEditItem, canViewAnyMeeting]) => this.setupMenu(itemUrl, canEditItem, canViewAnyMeeting));

        merge(
            // update the menu if items in the same column changed
            rxjsBreezeService.entityTypeChanged(Item).pipe(
                filter((item) => item === this.item || (item.status === this.item.status && this.showColumnMoveOptions)),
            ),
            // update the menu if the board containing the item changes (lastItemIndex will get updated if an item deletes for example)
            rxjsBreezeService.entityTypeChanged(Board).pipe(
                filter((board) => board === this.item?.board),
            ),
        ).pipe(
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerMenuUpdate$.next());
    }

    @HostListener("click", ["$event"])
    public onClick(e: MouseEvent) {
        // This will prevent kanban card click propagation, causing the preview pane to appear, pushing the menu away
        e.stopPropagation();
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.item || changes.columnItems) {
            this.triggerMenuUpdate$.next();
        }
    }

    private setupMenu(itemUrl: string, canEditItem: boolean, canViewAnyMeeting: boolean) {
        const items: IAdaptMenuItem[] = [];

        if (this.showOpenOption) {
            items.push({
                text: "Open action",
                icon: "fal fa-fw fa-folder-open",
                onClick: () => {
                    this.dialogOpened.emit(this.item);
                    this.kanbanUiService.openItem(this.item).pipe(
                        finalize(() => this.dialogClosed.emit(this.item)),
                    ).subscribe();
                },
            });
        }

        if (canEditItem) {
            if (this.item.status === ItemStatus.Closed) {
                if (!this.item.board?.team || (this.item.board?.team && this.item.board?.team.isActive())) {
                    items.push({
                        beginGroup: true,
                        text: "Re-open action",
                        icon: "fal fa-fw fa-inbox-out",
                        onClick: this.reopenItem,
                    });

                    items.push({
                        text: "Copy action URL to clipboard",
                        icon: "fal fa-fw fa-copy",
                        onClick: () => window.navigator.clipboard.writeText(`${window.location.origin}${itemUrl}`),
                    });
                }
            } else {
                const moveToMenu: IAdaptMenuItem = {
                    beginGroup: true,
                    text: "Move action to...",
                    icon: "fal fa-fw fa-up-down-left-right",
                    items: [],
                };

                if (this.showColumnMoveOptions && this.columnItems) {
                    // sort items in column by rank so we can get the current position
                    this.columnItems.sort(SortUtilities.getSortByFieldFunction<Item>("rank"));

                    const index = this.columnItems.indexOf(this.item);
                    if (index >= 0) {
                        if (index !== 0) {
                            moveToMenu.items!.push({
                                text: "Top of column",
                                icon: "fal fa-fw fa-arrow-up-to-line",
                                onClick: () => this.moveItemInColumn(this.columnItems!, 0),
                            });
                        }

                        const end = this.columnItems.length - 1;
                        if (index !== end) {
                            moveToMenu.items!.push({
                                text: "Bottom of column",
                                icon: "fal fa-fw fa-arrow-down-to-line",
                                onClick: () => this.moveItemInColumn(this.columnItems!, end),
                            });
                        }
                    }
                }

                moveToMenu.items!.push({
                    beginGroup: true,
                    text: "Another board",
                    icon: "fal fa-fw fa-share",
                    onClick: this.moveItem,
                });
                items.push(moveToMenu);

                items.push({
                    text: "Add link",
                    icon: "fal fa-fw fa-paperclip",
                    onClick: this.addItemLink,
                });

                if (this.labelMenuItem) {
                    items.push(this.labelMenuItem);
                }

                if (canViewAnyMeeting) {
                    items.push({
                        text: "Set meeting association",
                        icon: "fal fa-fw fa-link",
                        onClick: this.toggleMeetingAssociation,
                    });
                }

                items.push({
                    text: "Copy action URL to clipboard",
                    icon: "fal fa-fw fa-copy",
                    onClick: () => window.navigator.clipboard.writeText(`${window.location.origin}${itemUrl}`),
                });

                if (this.showOpenOption && (!this.item.board?.team || (this.item.board?.team && this.item.board?.team.isActive()))) {
                    items.push({
                        beginGroup: true,
                        text: "Archive action",
                        icon: "fal fa-fw fa-archive",
                        onClick: this.closeItem,
                    });
                }

                items.push({

                    text: "Duplicate action",
                    icon: "fal fa-fw fa-clone",
                    onClick: this.cloneItem,
                });

                items.push({
                    beginGroup: true,
                    text: "Delete action",
                    icon: "fal fa-fw fa-trash-alt",
                    onClick: this.deleteItem,
                });
            }
        }

        if (items.length) {
            this.itemMenu = [{
                text: "",
                icon: MenuComponent.SmallRootMenu.icon,
                items,
            }];
        } else {
            this.itemMenu = [];
        }
    }

    @Autobind
    private moveItem() {
        return this.kanbanUiService.openMoveItemDialog(this.item).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.itemMoved.emit(this.item));
    }

    private moveItemInColumn(column: Item[], newIndex: number) {
        // need to move the item in the column array first, then update the rank based off that.
        SortUtilities.moveItemInArray(column, column.indexOf(this.item), newIndex);
        this.item.rank = this.kanbanService.getProposedKanbanItemRank(column, newIndex);

        return this.kanbanService.saveEntities([this.item]).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.itemMoved.emit(this.item));
    }

    @Autobind
    private toggleMeetingAssociation() {
        this.meetingsService.getMeetingItemsForItem(this.item.itemId).pipe(
            switchMap((meetingItems) => {
                if (!meetingItems || meetingItems.length < 1) {
                    return this.kanbanUiService.openLinkMeetingAgendaItemDialog(this.item);
                } else {
                    return this.kanbanUiService.removeMeetingItem(meetingItems[0]);
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    private addItemLink() {
        // always save after add here as this menu won't be visible if item is newly added and not committed
        return this.kanbanUiService.openAddItemLinkDialog(this.item, true).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    private cloneItem() {
        return this.kanbanUiService.cloneItem(this.item);
    }

    @Autobind
    private deleteItem() {
        return this.kanbanUiService.deleteItem(this.item).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.itemDeleted.emit(this.item));
    }

    @Autobind
    private closeItem() {
        return this.dialogService.openConfirmationDialog({
            title: "Archive action?",
            message: "Are you sure you wish to archive this action? This will cause it to be hidden in the standard actions view.",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).pipe(
            switchMap(() => {
                this.item.status = ItemStatus.Closed;
                return this.kanbanService.saveEntities([this.item]);
            }),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.itemClosed.emit(this.item));
    }

    @Autobind
    private reopenItem() {
        this.item.status = ItemStatus.ToDo;
        this.kanbanService.saveEntities([this.item])
            .subscribe(() => this.itemReopened.emit(this.item));
    }
}
