<adapt-toolbar-title *ngIf="(valuesConstitution$ | async)?.lastUpdated as lastUpdated">
    <adapt-entity-timestamp class="text-muted"
                            [timestamp]="lastUpdated"
                            prefix="Last updated"></adapt-entity-timestamp>
</adapt-toolbar-title>
<adapt-toolbar-content alignment="center">
    <h2>Our values</h2>
</adapt-toolbar-content>
<div adaptToolbarContent>
    <button *ngIf="isEditing"
            adaptButtonIcon="add"
            [adaptBlockingClick]="addValue"
            data-test="add-value-button">Add value</button>
    <button *ngIf="isEditing"
            adaptButton="view"
            data-test="view-mode-button"
            (click)="isEditing = false"></button>
    <button *ngIf="(canEditValuesConstitution$ | async) && !isEditing"
            adaptButton="edit"
            data-test="edit-mode-button"
            (click)="isEditing = true"
            data-tour="values-edit"></button>
</div>

<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.SetValuesOutcomes"
                                          [fullScreen]="true"
                                          title="Values"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-values-constitution *ngIf="(valuesConstitution$ | async) as valuesConstitution; else noValuesTemplate"
                           [valuesConstitution]="valuesConstitution"
                           [isEditing]="isEditing"
                           [noValuesTemplate]="noValuesTemplate"
                           data-tour="values-card">
</adapt-values-constitution>

<ng-template #noValuesTemplate>
    <adapt-call-to-action [learnMoreUrl]="learnMoreUrl">
        <adapt-call-to-action-title>Values</adapt-call-to-action-title>

        <p>Adapt's values tool provides a place to capture and maintain your organisation's values.</p>

        <button *ngIf="canEditValuesConstitution$ | async"
                adaptCallToActionButton
                adaptButtonIcon="add"
                [adaptBlockingClick]="addValue">
            Add value
        </button>
    </adapt-call-to-action>
</ng-template>
