import { formatCurrency } from "@angular/common";
import { Component, Inject, Injector, LOCALE_ID, OnDestroy, OnInit } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { CoachSessionBreezeModel } from "@common/ADAPT.Common.Model/organisation/coach-session";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { CoachRequestType, ICoachRequest, PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { PreventNavigationGuard } from "@common/route/prevent-navigation.guard";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { catchError, defer, EMPTY, finalize, of, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { CoachAccessFeedbackDialogComponent } from "../coach-access-feedback-dialog/coach-access-feedback-dialog.component";
import { CoachAccessScheduleDialogComponent, ICoachAccessScheduleDialogData } from "../coach-access-schedule-dialog/coach-access-schedule-dialog.component";
import { CoachAccessSessionDialogComponent } from "../coach-access-session-dialog/coach-access-session-dialog.component";
import { ICoachOption } from "../coach-option.interface";

const FeedbackSearchParam = "feedback";
const CoachSessionIdSearchParam = "coachSessionId";

@Component({
    selector: "adapt-coach-access-page",
    templateUrl: "./coach-access-page.component.html",
    styleUrl: "./coach-access-page.component.scss",
})
export class CoachAccessPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public readonly ImplementationKitArticle = ImplementationKitArticle;
    public readonly scopingSession: ICoachOption = {
        name: "Scoping session",
        cost: 50.00,
        minutes: 20,
        adjustableTime: false,
        type: CoachRequestType.ScopingSession,
    };
    public readonly customSession: ICoachOption = {
        name: "In-depth coach session",
        cost: 150.00,
        hours: 1,
        adjustableTime: true,
        type: CoachRequestType.CustomCoachingSession,
    };
    public readonly coachOptions: ICoachOption[] = [this.scopingSession, this.customSession];

    public coachOption: ICoachOption = this.scopingSession;
    public hoursRequested = 1;
    public description?: string;

    public cardIsSet = false;

    public account?: Account;
    public errorMessage?: string;
    public submitting = false;

    private providingFeedback = false;

    private removePreventNavigationCallback?: () => void;

    public constructor(
        @Inject(LOCALE_ID) private localeId: string,
        injector: Injector,
        private accountService: AccountService,
        private paymentProcessingService: PaymentProcessingService,
        private dialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
        private preventNavigationGuard: PreventNavigationGuard,
    ) {
        super(injector);

        if (!AdaptClientConfiguration.CalendlyBaseUri) {
            throw new Error("Calendly URI is not set. Scheduling the session will not work!");
        }

        this.removePreventNavigationCallback = this.preventNavigationGuard.registerCanNavigateCallback(() => defer(() => {
            // don't allow any navigation while submitting
            if (this.submitting) {
                return of(false);
            }

            // prompt for confirming discard if they've entered a description
            if (this.description || this.providingFeedback) {
                return this.dialogService.openConfirmDiscardDialog().pipe(
                    map((result) => !!result),
                );
            }

            // otherwise allow navigation
            return of(true);
        }));
    }

    public get customSessionCost() {
        return this.hoursRequested * this.customSession.cost;
    }

    public get totalDisplay() {
        if (this.account && this.account.currency) {
            const cost = this.coachOption.adjustableTime
                ? this.customSessionCost
                : this.coachOption.cost;
            return formatCurrency(this.account.extensions.getTotalCost(cost), this.localeId, this.account.currency.shortName, this.account.currency.code);
        }

        return undefined;
    }

    public ngOnInit() {
        this.accountService.getAccount().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((account) => this.account = account);

        this.notifyActivated();

        const doFeedback = this.getSearchParameterValue(FeedbackSearchParam);
        const coachSessionId = this.getSearchParameterIntValue(CoachSessionIdSearchParam);
        if (doFeedback && coachSessionId) {
            this.providingFeedback = true;
            this.provideFeedback(coachSessionId).pipe(
                this.takeUntilDestroyed(),
            ).subscribe();
        } else {
            this.routeService.deleteSearchParameters([FeedbackSearchParam, CoachSessionIdSearchParam]);
        }
    }

    private provideFeedback(coachSessionId: number) {
        return this.commonDataService.getById(CoachSessionBreezeModel, coachSessionId).pipe(
            switchMap((coachSession) => {
                if (!coachSession) {
                    throw new Error("Failed to get your coach session details.");
                }

                return this.dialogService.open(CoachAccessFeedbackDialogComponent, coachSession);
            }),
            catchError((err) => {
                this.errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(err);
                return EMPTY;
            }),
            finalize(() => {
                this.providingFeedback = false;
                this.routeService.deleteSearchParameters([FeedbackSearchParam, CoachSessionIdSearchParam]);
            }),
            this.takeUntilDestroyed(),
        );
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.removePreventNavigationCallback?.();
    }

    @Autobind
    public requestCoach() {
        this.errorMessage = undefined;
        this.submitting = true;

        if (this.account && this.description) {
            const coachRequest: ICoachRequest = {
                Description: this.description,
                RequestType: this.coachOption.type,
                RequestedTimeHrs: this.coachOption.type === CoachRequestType.CustomCoachingSession
                    ? this.hoursRequested
                    : undefined,
            };

            return this.paymentProcessingService.requestCoach(this.account.organisationId, coachRequest).pipe(
                // TODO: calendly/intercom when this succeeds
                switchMap((coachSessionId) => this.dialogService.open(CoachAccessScheduleDialogComponent, {
                    ...coachRequest,
                    coachSessionId,
                } as ICoachAccessScheduleDialogData)),
                catchError((e) => this.errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e)),
                finalize(() => this.reset()),
                this.takeUntilDestroyed(),
            );
        }

        return EMPTY;
    }

    public viewCoachingSessionHistory() {
        this.dialogService.open(CoachAccessSessionDialogComponent).subscribe();
    }

    private reset() {
        this.submitting = false;
        this.description = undefined;
        this.hoursRequested = 1;
        this.coachOption = this.scopingSession;
    }
}

export const coachAccessPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-coach-access-page", CoachAccessPageComponent)
    .atOrganisationUrl("/coach-access")
    .withTitle("Talk to a coach")
    .build();
