<div *ngIf="agendaItem.supplementaryData?.itemDescription"
     [froalaView]="agendaItem.supplementaryData?.itemDescription">
</div>

<div *ngIf="agendaItem.componentSelector"
     class="alert alert-secondary">
    <ng-container #container></ng-container>
</div>

<adapt-display-meeting-note *ngFor="let note of agendaNotes"
                            [note]="note"
                            [edit]="canEditMeetingItem$ | async"></adapt-display-meeting-note>
<adapt-display-meeting-action *ngFor="let action of agendaActions"
                              [meetingItem]="action"></adapt-display-meeting-action>
<div class="d-flex pt-1 align-items-center justify-content-end"
     data-tour="agenda-action-items"
     *ngIf="canEditMeetingItem$ | async">
    <button class="btn btn-primary btn-pill btn-minute me-auto"
            *ngIf="canEditAgendaItem"
            adaptTooltip="Edit agenda item"
            data-tour="edit-agenda-item"
            adaptButtonIcon="fal fa-fw fa-edit"
            [adaptBlockingClick]="editAgendaItem"></button>
    <button class="btn btn-primary btn-pill btn-minute"
            data-tour="add-agenda-decision"
            adaptTooltip="Add decision"
            (click)="addDecision()"><i [ngClass]="MeetingNoteIconClass[MeetingNoteType.Decision]"></i></button>
    <button class="btn btn-primary btn-pill btn-minute mx-1"
            data-tour="add-agenda-minute"
            adaptTooltip="Add minute"
            (click)="addMinute()"><i [ngClass]="MeetingNoteIconClass[MeetingNoteType.Minute]"></i></button>
    <button class="btn btn-primary btn-pill btn-minute"
            data-tour="add-agenda-work-item"
            adaptTooltip="Add action"
            *ngIf="hasEditableBoard"
            (click)="addMeetingItem()"><i [ngClass]="MeetingItemIconClass"></i></button>
</div>
