<dx-popup [wrapperAttr]="{class: 'adapt-dialog'}"
          [container]="container"
          [shading]="true"
          shadingColor="rgba(0, 0, 0, 0.5)"
          [position]="position"
          [animation]="animationOptions"
          (visibleChange)="onVisibleChanged($event)"
          [minWidth]="minWidth"
          [maxWidth]="maxWidth"
          [width]="width ?? (isMobile ? '95vw' : '80vw')"
          [minHeight]="minHeight"
          [maxHeight]="maxHeightValue"
          (onInitialized)="onInitialized($event)"
          (onShown)="onShown()"
          [showTitle]="!minimal"
          [fullScreen]="fullscreen"
          [dragEnabled]="!fullscreen"
          height="auto">
    <div *dxTemplate="let data of 'title'">
        <div class="mt-1 mb-0 d-flex flex-row justify-content-between align-items-center dialog-title"
             [ngClass]="{'mt-2 mb-1': !expandable}">
            <ng-content select="[adapt-dialog-title]"></ng-content>

            <div class="d-flex align-items-center ms-2">
                <ng-content select="[adapt-dialog-menu]"></ng-content>

                <button *ngIf="showHelpButton && contextSidebarHasContent"
                        adaptButton="help"
                        class="ms-2"
                        [ngClass]="{active: contextSidebarVisible}"
                        data-test="dialog-help-button"
                        (click)="toggleHelp()"></button>

                <button *ngIf="expandable"
                        [adaptButtonIcon]="fullscreen ? 'fal fa-compress-alt' : 'fal fa-expand-alt'"
                        [adaptTooltip]="fullscreen ? 'Minimise' : 'Maximise'"
                        [title]="fullscreen ? 'Minimise' : 'Maximise'"
                        class="btn btn-blank btn-fullscreen"
                        data-test="dialog-fullscreen-toggle"
                        (click)="onFullscreenToggle()"></button>
            </div>
        </div>
    </div>
    <div *dxTemplate="let data of 'content'"
         class="d-flex flex-column justify-content-between">
        <div *ngIf="errorMessage"
             class="m-1 alert alert-danger"
             role="alert"
             data-test="dialog-error">
            <span [textContent]="errorMessage"></span>
        </div>
        <div class="dialog-content d-flex flex-column h-100 p-0"
             [ngClass]="{'fit-height': fitHeight}">
            <dx-scroll-view *ngIf="allowMouseWheelContentScroll; else noScroll"
                            [useNative]="true"
                            class="h-100">
                <ng-container [ngTemplateOutlet]="noScroll"></ng-container>
            </dx-scroll-view>
            <ng-template #noScroll>
                <div [ngClass]="minimal || !padding ? 'p-0' : 'p-3'">
                    <ng-content select="[adapt-dialog-content]"></ng-content>
                </div>
            </ng-template>
        </div>
        <div class="dialog-footer flex-shrink-0"
             [ngClass]="minimal ? 'd-none': 'd-flex'">
            <ng-content select="[adapt-dialog-footer]"></ng-content>
        </div>
    </div>
</dx-popup>

<adapt-context-sidebar-implementation-kit *ngIf="articleId !== undefined"
                                          [articleId]="articleId"
                                          context="dialog"></adapt-context-sidebar-implementation-kit>
