import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { ObjectiveStatus, ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { ObjectiveType, ObjectiveTypeMetadata } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { NumberUtilities } from "@common/lib/utilities/number-utilities";
import { UrlFilterService } from "@common/shell/filter/url-filter.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { SelectPersonComponent } from "@org-common/lib/directory-shared/select-person/select-person.component";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import isEqual from "lodash.isequal";
import { lastValueFrom, Subscription } from "rxjs";
import { PersonService } from "../../person/person.service";
import { ClosedFilters, ObjectiveFilter } from "./objective-filter";
import { ObjectiveFilterService } from "./objective-filter.service";


export enum ObjectiveFilterParamKeys {
    LabelIds = "labelIds",
    Assignee = "assigneePersonId",
    DuringYear = "duringYear",
    ShowSupportingTeamObjectives = "showSupportingTeamObjectives",
    ShowSupportedOrgObjectives = "showSupportedOrgObjectives",
    ShowAllTeamObjectives = "showAllTeamObjectives",
    ClosedOption = "closedOption",
    ObjectiveStatuses = "objectiveStatuses",
    FocusOn = "focusOn",
}

@Component({
    selector: "adapt-objective-filter",
    templateUrl: "./objective-filter.component.html",
    styleUrls: ["./objective-filter.component.scss"],
})
export class ObjectiveFilterComponent extends BaseComponent implements OnInit, OnDestroy {
    private readonly DefaultFilter = new ObjectiveFilter();

    @Input() public team?: Team;

    @Output() public changed = new EventEmitter<ObjectiveFilter>();

    public readonly TeamObjectivesOptions = [
        { displayText: "Not showing team objectives", value: 0 },
        { displayText: "Show supporting team objectives", value: 1 },
        { displayText: "Show all team objectives", value: 3 }, // note value is bitmask - 1st bit showing supporting, 2nd bit non supporting
    ];
    public teamObjectivesOptionValue = 0;

    public focussedType?: ObjectiveType;
    public focusOptions = [
        this.generateSelectOption(ObjectiveType.Annual),
        this.generateSelectOption(ObjectiveType.Quarterly),
    ];

    public statuses: ObjectiveStatusMetadata[];
    public statusSelection: ObjectiveStatusMetadata[];

    public closedFilters = [ClosedFilters.ShowSupporting, ClosedFilters.ShowAll];

    public filter: ObjectiveFilter;
    public filterForm: UntypedFormGroup;
    private formSubscriptions = new Subscription();
    @ViewChild(SelectPersonComponent) private selectPersonComponent!: SelectPersonComponent;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        public objectiveFilterService: ObjectiveFilterService,
        private urlFilterService: UrlFilterService,
        private labellingService: LabellingService,
        private personService: PersonService,
    ) {
        super();
        this.filter = objectiveFilterService.filter.currentValue.clone();
        this.filterForm = this.generateForm(this.filter);
        this.statuses = ObjectiveStatusMetadata.All;
        this.statusSelection = [...this.filter.objectiveStatuses].map((status) => ObjectiveStatusMetadata.ByStatus[status]);
        this.updateTeamObjectivesOptionValue();
    }

    public async ngOnInit() {
        /* There are 2 cases here:
            1: setFilterFromUrl - is for getting url params and setting it on the filter.
                This is used when th page refreshes or browser navigates to objectives and there filter params in the url that need to be applied.

            2: setFilterToUrl - is for setting params on the url. since filter object presist even if the user switches to a different page.
                We need to set those filter values back on the url when they navigate back to the objectives page
        */
        if (this.urlFilterService.urlHasSearchParams()) {
            await this.setFilterFromUrl();
        }

        if (!this.objectiveFilterService.isDefault) {
            await this.setUrlParamsFromFilter();
        }

        this.objectiveFilterService.filter.option$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((filter) => {
            this.filter = filter.clone();
            this.filterForm = this.generateForm(this.filter);
            this.updateTeamObjectivesOptionValue();
            this.changed.emit(this.filter);
        });
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.formSubscriptions.unsubscribe();
    }

    public reset() {
        this.objectiveFilterService.reset();
        this.statusSelection = [...this.filter.objectiveStatuses].map((status) => ObjectiveStatusMetadata.ByStatus[status]);
        this.selectPersonComponent.reset();
    }

    public async onFocussedTypeSelection(type?: ObjectiveType) {
        await this.setUrlFilter(ObjectiveFilterParamKeys.FocusOn, type);
        this.objectiveFilterService.focusType.next(type ?? undefined);
    }

    @Autobind
    public async onStatusChanged(selectedStatus: ObjectiveStatusMetadata[]) {
        this.statusSelection = selectedStatus;

        const selected = selectedStatus.map((status) => status.status);
        await this.urlFilterService.setFilter({
            [ObjectiveFilterParamKeys.ObjectiveStatuses]: selectedStatus?.length
                ? selectedStatus.map((l) => l.status).join(",")
                : undefined,
        });
        this.objectiveFilterService.filter.next(this.filter.setFilterStatuses(selected));
    }

    public async onAssigneeChanged(person: Person) {
        this.filter.assignee = person ?? undefined;
        await this.urlFilterService.setFilter({
            [ObjectiveFilterParamKeys.Assignee]: person
                ? person.personId
                : undefined,
        });
        this.objectiveFilterService.filter.next(this.filter);
    }

    public async onLabelsChanged(labels: Label[]) {
        this.filter.labels = (!labels || labels.length < 1)
            ? []
            : labels;
        await this.urlFilterService.setFilter({
            [ObjectiveFilterParamKeys.LabelIds]: labels?.length
                ? this.filter.labels.map((l) => l.labelId).join(",")
                : undefined,
        });
        this.objectiveFilterService.filter.next(this.filter);
    }

    public async onClosedOptionsChanged(option: ClosedFilters) {
        this.filter.closedOption = option;
        await this.setUrlFilter(ObjectiveFilterParamKeys.ClosedOption, option);
        this.objectiveFilterService.filter.next(this.filter);
    }

    public async onTeamObjectivesOptionChanged(value: number) {
        this.filter.showSupportingTeamObjectives = (value & 1) == 1;
        this.filter.showAllTeamObjectives = (value & 2) == 2;
        await this.setUrlFilter(ObjectiveFilterParamKeys.ShowSupportingTeamObjectives, this.filter.showSupportingTeamObjectives);
        await this.setUrlFilter(ObjectiveFilterParamKeys.ShowAllTeamObjectives, this.filter.showAllTeamObjectives);
        this.objectiveFilterService.filter.next(this.filter);
    }

    public get closedSelected() {
        return this.statusSelection && this.statusSelection.some((selectedStatus) => selectedStatus.status === ObjectiveStatus.Closed);
    }

    private generateForm(filter: ObjectiveFilter) {
        this.formSubscriptions.unsubscribe();
        this.formSubscriptions = new Subscription();

        const form = this.formBuilder.group({});

        const duringYearControl = this.formBuilder.control(filter.duringYear);
        this.updateFilterOnFormValueChange<Date>(duringYearControl, async (year) => {
            filter.duringYear = year;
            await this.urlFilterService.setFilter({ [ObjectiveFilterParamKeys.DuringYear]: year ? year.getFullYear() : undefined });

        });
        form.addControl("duringYear", duringYearControl);

        const showSupportedOrgObjectivesControl = this.formBuilder.control(filter.showSupportedOrgObjectives);
        this.updateFilterOnFormValueChange<boolean>(showSupportedOrgObjectivesControl, async (showSupportedOrgObjectives) => {
            filter.showSupportedOrgObjectives = showSupportedOrgObjectives;
            await this.setUrlFilter(ObjectiveFilterParamKeys.ShowSupportedOrgObjectives, showSupportedOrgObjectives);
        });
        form.addControl("showSupportedOrgObjectives", showSupportedOrgObjectivesControl);

        return form;
    }

    private updateTeamObjectivesOptionValue() {
        let value = 0;
        value = value | (this.filter.showSupportingTeamObjectives ? 1 : 0);
        value = value | (this.filter.showAllTeamObjectives ? 2 : 0);
        this.teamObjectivesOptionValue = value;
    }

    private updateFilterOnFormValueChange<T>(formControl: AbstractControl, updateFilterValue: (value: T) => Promise<unknown>) {
        const subscription = formControl.valueChanges.subscribe(async (value: T) => {
            await updateFilterValue(value);
            this.objectiveFilterService.filter.next(this.filter);
        });

        this.formSubscriptions.add(subscription);
    }

    private generateSelectOption(type: ObjectiveType) {
        const metadata = ObjectiveTypeMetadata.ByType[type];
        return {
            type,
            label: metadata.pluralName,
            html: `<i class="${metadata.iconClass} me-1" aria-hidden="true"></i>${metadata.pluralName}`,
        };
    }

    private async setFilterFromUrl() {
        const paramValues = this.urlFilterService.getFilters(Object.values(ObjectiveFilterParamKeys));
        for (const param of paramValues) {
            if (param.value) {
                switch (param.name) {
                    case ObjectiveFilterParamKeys.LabelIds:
                        await this.onLabelsChanged(await lastValueFrom(this.labellingService.getLabelsWithIds(param.value.split(",").map((s) => Number(s)))));
                        break;

                    case ObjectiveFilterParamKeys.Assignee:
                        const personInt = NumberUtilities.parseNumber(param.value);
                        if (personInt) {
                            const person = await lastValueFrom(this.personService.getPerson(personInt));
                            if (person) {
                                await this.onAssigneeChanged(person);
                            }
                        }
                        break;

                    case ObjectiveFilterParamKeys.ShowSupportingTeamObjectives:
                        this.filter.showSupportingTeamObjectives = param.value === "true";
                        break;

                    case ObjectiveFilterParamKeys.ShowAllTeamObjectives:
                        this.filter.showAllTeamObjectives = param.value === "true";
                        break;

                    case ObjectiveFilterParamKeys.ShowSupportedOrgObjectives:
                        this.filter.showSupportedOrgObjectives = param.value === "true";
                        break;

                    case ObjectiveFilterParamKeys.DuringYear:
                        this.filter.duringYear = new Date(param.value);
                        break;

                    case ObjectiveFilterParamKeys.ObjectiveStatuses:
                        const statuses = param.value.split(",");
                        const objectiveStatuses: ObjectiveStatusMetadata[] = [];
                        statuses.forEach((s) => {
                            objectiveStatuses.push(ObjectiveStatusMetadata.ByStatus[s as ObjectiveStatus]);
                        });
                        await this.onStatusChanged(objectiveStatuses);
                        break;

                    case ObjectiveFilterParamKeys.FocusOn:
                        await this.onFocussedTypeSelection(param.value as ObjectiveType);
                        break;

                    case ObjectiveFilterParamKeys.ClosedOption:
                        await this.onClosedOptionsChanged(param.value as ClosedFilters);
                }
            }
        }

        if (this.filter.showAllTeamObjectives && !this.filter.showSupportingTeamObjectives) {
            this.filter.showSupportingTeamObjectives = true;
        }

        this.objectiveFilterService.filter.next(this.filter);
    }

    private async setUrlParamsFromFilter() {
        const defaultFilter: ObjectiveFilter = new ObjectiveFilter();

        await this.urlFilterService.setFilter({
            [ObjectiveFilterParamKeys.LabelIds]:
                this.filter.labels?.length && !isEqual(this.filter.labels, defaultFilter.labels)
                    ? this.filter.labels.map((l) => l.labelId).join(",")
                    : undefined,
        });

        await this.setUrlFilter(ObjectiveFilterParamKeys.Assignee, this.filter.assignee);
        await this.setUrlFilter(ObjectiveFilterParamKeys.ShowSupportingTeamObjectives, this.filter.showSupportingTeamObjectives);
        await this.setUrlFilter(ObjectiveFilterParamKeys.ShowAllTeamObjectives, this.filter.showAllTeamObjectives);
        await this.setUrlFilter(ObjectiveFilterParamKeys.ShowSupportedOrgObjectives, this.filter.showSupportedOrgObjectives);
        await this.setUrlFilter(ObjectiveFilterParamKeys.DuringYear, this.filter.duringYear);

        await this.urlFilterService.setFilter({
            [ObjectiveFilterParamKeys.ObjectiveStatuses]:
                this.filter.objectiveStatuses.size && !isEqual(this.filter.objectiveStatuses, defaultFilter.objectiveStatuses)
                    ? Array.from(this.filter.objectiveStatuses).join(",")
                    : undefined,
        });

        await this.urlFilterService.setFilter({
            [ObjectiveFilterParamKeys.FocusOn]:
                !isEqual(this.objectiveFilterService.focusType.currentValue, this.objectiveFilterService.focusType.defaultValue)
                    ? this.objectiveFilterService.focusType.currentValue
                    : undefined,
        });

        await this.setUrlFilter(ObjectiveFilterParamKeys.ClosedOption, this.filter.closedOption);
    }

    private setUrlFilter(key: string, value: any) {
        return this.urlFilterService.setFilter({
            [key]: !!value && !isEqual((this.DefaultFilter as any)[key], value)
                ? value
                : undefined,
        });
    }
}
