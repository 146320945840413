<ng-container *ngIf="anchors.length > 0; else noAnchor">
    <dx-list data-test="anchors-list"
             class="anchors-list"
             [activeStateEnabled]="false"
             [hoverStateEnabled]="false"
             [focusStateEnabled]="false"
             [dataSource]="anchors"
             (onItemReordered)="reorderAnchors($event)">
        <dxo-item-dragging [data]="anchors"
                           [allowReordering]="isEditing"></dxo-item-dragging>
        <div *dxTemplate="let anchor of 'item'">
            <adapt-strategic-anchor [anchor]="anchor"
                                    [isEditing]="isEditing"
                                    [isCollapsible]="!isEditing"
                                    [collapsed]="collapsed">
            </adapt-strategic-anchor>
        </div>
    </dx-list>
</ng-container>


<ng-template #noAnchor>
    No strategic anchors have been defined.
</ng-template>
