// missing types from dx that we've defined to increase type safety
// commonly found in their event handlers
// definitions taken from the dx.all.d.ts where possible

import { PositionConfig } from "devextreme/animation/position";
import { dxElement } from "devextreme/core/element";
import { DxEvent, EventType } from "devextreme/events";
import dxDraggable from "devextreme/ui/draggable";
import dxList from "devextreme/ui/list";
import dxMenu from "devextreme/ui/menu";
import dxPopover from "devextreme/ui/popover";
import dxScrollView from "devextreme/ui/scroll_view";
import dxSelectBox from "devextreme/ui/select_box";
import dxSortable from "devextreme/ui/sortable";
import dxTreeView from "devextreme/ui/tree_view";
import { AsyncRule, CompareRule, CustomRule, EmailRule, NumericRule, PatternRule, RangeRule, RequiredRule, StringLengthRule } from "devextreme/ui/validation_rules";
import dxValidator from "devextreme/ui/validator";
import { chartSeriesObject } from "devextreme/viz/chart";

// CM-5081 no typing on their function at all yet
export interface IDxGridColumnCellInfo<TValue = string | number | Date> {
    value?: TValue;
    valueText?: string;
    target?: string;
    groupInterval?: string | number;
}

// CM-3081 no type as far as I can tell
export type IDxGridColumnTemplateCellInfo = any;

// CM-5081 no type for the function defined at all
export interface IDxChartCustomizePoint {
    argument: any;
    data: any;
    index: number;
    series: chartSeriesObject;
    seriesName: string;
    tag: any;
    value: any;
}

// this is for click event when clicking on a point in dxChart
export interface IDxChartPointEvent {
    target: IDxChartCustomizePoint;
}

export interface IDxChartLabelCustomiseTextArg {
    min: any;
    max: any;
    value: any;
    valueText: string;
}

// CM-5081 no type for the function defined at all
export interface IDxChartCustomizeTooltip {
    originalValue: any;
    value: any;
    valueText: string;
    originalArgument: any;
    argument: any;
    argumentText: string;
    seriesName: string;
    point: any;
}

// CM-5081 no type for the function defined at all
export interface IDxChartTooltipPointInfo {
    argument: any;
    argumentText: string;
    originalArgument: any;
    originalValue: any;
    point: any;
    seriesName: string;
    value: any;
    valueText: string;
    totalText: string;
    percentText: string;
    data: any;
}

// CM-5081 no type for the function defined at all
export interface IDxPieChartCustomizeLegendText {
    pointName?: any;
    pointIndex?: number;
    pointColor?: string;
}

// CM-5081 no type for the function defined at all
export interface IDxPieChartCustomizeText {
    argument: any;
    argumentText: string;
    percent: any;
    percentText: string;
    seriesName: string;
    value: any;
    valueText: string;
}

// CM-5081 their type has any specified instead of template
export interface IDxListSelectionChangedEvent<T> {
    component?: dxList;
    element?: dxElement;
    addedItems: T[];
    removedItems: T[];
}

export type IDxListItemIndex = number | { group: number; item: number; };

// CM-5081 their type has any specified instead of template
export interface IDxListItemReorderedEvent<T> {
    component: dxList;
    element: JQuery;
    fromIndex: number;
    itemData?: T;
    itemElement: JQuery;
    itemIndex: IDxListItemIndex;
    toIndex: number;
}

// CM-5081 their type has any specified instead of template
export interface IDxListItemDeletingEvent<T> {
    cancel?: boolean | PromiseLike<void> | PromiseLike<boolean>;
    component?: dxList;
    element?: dxElement;
    itemData?: T;
}

export interface IDxScrollViewEvent {
    component?: dxScrollView;
    element?: dxElement;
    reachedBottom?: boolean;
    reachedLeft?: boolean;
    reachedRight?: boolean;
    reachedTop?: boolean;
    scrollOffset?: { top?: number, left?: number };
}

export interface IDxPopoverEvent {
    cancel?: boolean;
    component?: dxPopover;
    element?: dxElement;
}

// CM-5081 their type has any specified instead of template
export interface IDxListItemDeletedEvent<T> {
    component?: dxList;
    element?: dxElement;
    itemData?: T;
}

// CM-5081 they don't event have a type here
export enum DxGaugePointType {
    ValueIndicator = "value-indicator",
    SubvalueIndicator = "subvalue-indicator",
}

// CM-5081 they don't event have a type here
export interface IDxGaugeTooltipPointInfo {
    value: number;
    valueText: string;
    type: DxGaugePointType;
    index: number;
}

// CM-5081 their type has any specified instead of template
export interface IDxSelectBoxValueChangedEvent<T> {
    component?: dxSelectBox;
    element?: dxElement;
    event?: EventType;
    previousValue?: T;
    value?: T;
}

// CM-5081 their type has any specified instead of template
export interface IDxSelectBoxSelectionChangedEvent<T> {
    component?: dxSelectBox;
    element?: dxElement;
    selectedItem: T;
}


// CM-5081 their type has any specified instead of template
export interface IDxSortableEvent<TFrom, TTo = TFrom, TData = any> {
    cancel?: boolean;
    component?: dxSortable;
    element?: dxElement;
    event?: JQuery.Event | DxEvent<MouseEvent>;

    /** Set itemData in the onDragStart event in order for this to be set */
    itemData?: TData;
    itemElement?: dxElement;
    fromIndex?: number;
    toIndex?: number;
    fromComponent?: dxSortable | dxDraggable;
    toComponent?: dxSortable | dxDraggable;

    /** Set data on the source component definition for this to be set */
    fromData?: TFrom;

    /** Set data on the target component definition for this to be set */
    toData?: TTo;

    dropInsideItem?: boolean;
}

export type IDxCustomRuleValidationCallback = (validationCallbackEvent: IDxCustomRuleValidationCallbackEvent) => boolean;

// CM-5081 need to so some work in our code because rule and validator are required in their type
// possibly we need to get the validator instance and call that?
export interface IDxCustomRuleValidationCallbackEvent {
    value?: string | number;
    rule?: RequiredRule | NumericRule | RangeRule | StringLengthRule | CustomRule | CompareRule | PatternRule | EmailRule | AsyncRule;
    validator?: dxValidator;
}

// CM-5081 their type has any specified instead of template
export interface IDxTreeViewItemRenderedEvent<TData = any> {
    component: dxTreeView;
    element: dxElement;
    itemData?: TData;
    itemElement?: dxElement;
    itemIndex?: number;
}

// CM-5081 their type has any specified instead of template
export interface IDxMenuItemClicked<TData = any> {
    component: dxMenu;
    element: dxElement;
    itemData?: TData;
    itemElement: dxElement;
    itemIndex: number;
}

// CM-5081 their type has any specified instead of boolean
export interface IDxCheckBoxOnValueChanged {
    value?: boolean
}

export interface IDxTagBoxCustomItemCreatingEvent<TData = any> {
    text?: string;
    customItem?: TData;
}

export interface IDxPositionUtilities {
    setup(element: Element, options: PositionConfig): void;
}
