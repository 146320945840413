import { Component, Input } from "@angular/core";
import { Anchor } from "@common/ADAPT.Common.Model/organisation/anchor";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-strategic-anchors",
    templateUrl: "./strategic-anchors.component.html",
    styleUrls: ["./strategic-anchors.component.scss"],
})
export class StrategicAnchorsComponent {

    @Input() public anchors: Anchor[] = [];
    @Input() public isEditing = false;
    @Input() public collapsed = false;

    public constructor(private commonDataService: CommonDataService) {
    }

    public async reorderAnchors(e: IDxListItemReorderedEvent<Anchor[]>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.anchors, "ordinal", e.fromIndex, e.toIndex);
        await lastValueFrom(this.commonDataService.saveEntities(this.anchors));
    }
}
