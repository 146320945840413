<adapt-dialog [maxWidth]="1200">
    <h3 adapt-dialog-title>Edit Team Guidance</h3>

    <div adapt-dialog-content
         class="row g-3">
        <div class="col-12 col-lg-6 d-flex"
             [adaptCollapsible]="ImplementationKitArticle.TeamPurposeEdit">
            <adapt-styled-implementation-kit [articleId]="ImplementationKitArticle.TeamPurposeEdit"
                                             contentStyle="embedded"></adapt-styled-implementation-kit>
        </div>

        <div class="col-12 col-lg-6">
            <adapt-html-editor #focus
                               [(contents)]="purpose.content"
                               forceSize="compact"></adapt-html-editor>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="purposeIsUnmodified"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>
