import { AfterViewChecked, Component, ElementRef, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { SurveyType, SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { IdentityService } from "@common/identity/identity.service";
import { IdentityStorageService } from "@common/identity/identity-storage.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { SUBSCRIBE_ORGANISATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ShellContentElementHeightTracker } from "@common/ux/base-ui.service/shell-content-element-height-tracker";
import { ValueChangedEvent } from "devextreme/ui/radio_group";
import { ScrollEvent } from "devextreme/ui/scroll_view";
import { DxScrollViewComponent } from "devextreme-angular";
import { Subject } from "rxjs";
import { debounceTime, finalize, tap } from "rxjs/operators";
import { ISurveyQuestionDisplay, SurveyResponseBindingModel, SurveyService } from "../survey.service";
import { SurveyDetails } from "../survey-details";
import { ISurveyResponseChoice } from "../survey-response-choice.interface";
import { SurveyUiService } from "../survey-ui.service";
import { SurveyUtils } from "../survey-utils";
import { SignupHealthCheckPageRoute } from "./survey-page.route";

interface ICategoryQuestions {
    categoryName: string;
    categoryIntro: string;
    questions: ISurveyQuestionDisplay[];
}

@Component({
    selector: "adapt-survey-page",
    templateUrl: "./survey-page.component.html",
    styleUrls: ["./survey-page.component.scss"],
})
export class SurveyPageComponent extends BaseRoutedComponent implements OnInit, AfterViewChecked {
    private readonly ScrollableElement = "dx-scroll-view";
    private readonly MinScrollViewHeight = 280;
    private readonly QuestionElementPrefix = "question_";
    private readonly ScrollBehaviour = "scroll-behavior";
    private readonly Smooth = "smooth";
    private readonly BorderOffset = 10;

    public isTokenlessSurvey = false;
    public introArticle?: ImplementationKitArticle;
    public token?: string;
    public surveyNameFromParam?: string;
    public surveyDetails?: SurveyDetails;
    public errorMessage?: string;
    public submitErrorMessage?: string;
    public surveyQuestions: ISurveyQuestionDisplay[] = [];
    public surveyQuestionsGroupedByCategory: ICategoryQuestions[] = [];
    public isSubmitting = false;
    public submitted = false;
    public questionResponses: ISurveyResponseChoice[] = [];
    public scrollViewHeight = this.MinScrollViewHeight;
    private scrollViewHeightUpdater = this.createThrottledUpdater((height: number) => this.scrollViewHeight = height);

    public title = "";
    @ViewChild(DxScrollViewComponent) private dxScrollViewComponent?: DxScrollViewComponent;
    public surveyDescription?: string;

    private scrollViewHeightTracker: ShellContentElementHeightTracker;

    private previouslyAnsweredQuestionOrdinal?: number;
    private currentQuestionOrdinal = 1;
    private scrollEvents = new Subject<void>();
    private smoothScrolling = false;
    private previousScrollOffset = 0;
    private scrollDown = true;
    private returnPath?: string;

    public constructor(
        private el: ElementRef,
        injector: Injector, // need injector here as this page is anonymous which will be loaded before AngularGlobals.injector is set
        private surveyService: SurveyService,
        private identityStorage: IdentityStorageService,
        private identityService: IdentityService,
        @Inject(SUBSCRIBE_ORGANISATION_PAGE) private signupPageRoute: IAdaptRoute<{}>,
    ) {
        super(injector);
        const uiService = injector.get(SurveyUiService);
        this.scrollViewHeightTracker = uiService.trackShellContentElementHeight(
            el,
            this.ScrollableElement,
            this.MinScrollViewHeight,
            80,
        );

        this.scrollEvents.pipe(
            debounceTime(100), // only check for focus question if not moving for 100msec
            tap(() => this.focusQuestionOnScrolled()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get isLoggedIn() {
        return this.identityStorage.isLoggedIn;
    }

    public get incomplete() {
        return this.surveyQuestions.some((q) => !q.response);
    }

    public get totalQuestionCount() {
        return this.surveyQuestions.length;
    }

    public get answeredQuestionCount() {
        let answeredCount = 0;
        this.surveyQuestions.forEach((q) => {
            if (q.response) {
                answeredCount++;
            }
        });
        return answeredCount;
    }

    public get scrollViewInstance() {
        return this.dxScrollViewComponent?.instance;
    }

    public ngAfterViewChecked() {
        this.scrollViewHeightUpdater.next(this.scrollViewHeightTracker.contentElementHeight);
    }

    public onItemClick(e: MouseEvent) {
        if (this.previouslyAnsweredQuestionOrdinal) {
            this.previouslyAnsweredQuestionOrdinal = undefined;
            return;
        }

        const clickedElement = jQuery(e.currentTarget!);
        if (clickedElement.length === 1) {
            const elementId = clickedElement.attr("id");
            if (elementId && elementId.startsWith(this.QuestionElementPrefix)) {
                const questionOrdinal = Number(elementId.substring(this.QuestionElementPrefix.length));
                this.currentQuestionOrdinal = questionOrdinal;
                this.scrollToQuestionOrdinal(questionOrdinal);
            }
        }
    }

    public onAnswered(question: ISurveyQuestionDisplay, e?: ValueChangedEvent) {
        if (!e?.event) {
            // 2 events from RadioGroup - only need to handle the event where event is not undefined
            return;
        }

        const self = this;
        this.storeResponse();
        this.previouslyAnsweredQuestionOrdinal = question.ordinal;
        if (!this.incomplete) {
            this.currentQuestionOrdinal = this.surveyQuestions[this.surveyQuestions.length - 1].ordinal;
            this.setSmoothScrolling(true);
            this.scrollViewInstance!.scrollTo(Math.ceil(this.scrollViewInstance!.scrollHeight()));
        } else {
            // scroll to next un-answered or from the top if all questions after the current are answered
            const nextUnanswered = nextUnansweredOrdinal(question.ordinal);
            if (nextUnanswered) {
                this.currentQuestionOrdinal = nextUnanswered;
                this.scrollToQuestionOrdinal(this.currentQuestionOrdinal, true);
            }
        }

        function nextUnansweredOrdinal(currentOrdinal: number) {
            for (let i = currentOrdinal; i < self.surveyQuestions.length; i++) {
                if (!self.surveyQuestions[i].response) {
                    return self.surveyQuestions[i].ordinal;
                }
            }

            // check from begin as all questions after the current ordinal has been answered
            for (let i = 0; i < currentOrdinal - 1; i++) {
                if (!self.surveyQuestions[i].response) {
                    return self.surveyQuestions[i].ordinal;
                }
            }

            return undefined;
        }
    }

    public onScrolled(e: ScrollEvent) {
        // data is only attached if calling scrollTo* from scrollview instance - undefined otherwise
        // don't want to double handle the scroll event from scrollTo
        if (!e.event?.data) {
            // not using scrollTo - turn off smooth scrolling or the scroll will be non-responsive
            this.setSmoothScrolling(false);
            if (this.scrollViewInstance) {
                const currentOffset = this.scrollViewInstance.scrollOffset().top;
                if (currentOffset > this.previousScrollOffset) {
                    this.scrollDown = true;
                } else if (currentOffset < this.previousScrollOffset) {
                    this.scrollDown = false;
                }
                // don't change direction if current === previous

                this.previousScrollOffset = currentOffset;
            }
            this.scrollEvents.next();
        }
    }

    public getAdditionalQuestionStyle(ordinal: number) {
        let style = "";
        if (this.surveyQuestions[this.surveyQuestions.length - 1].response
            && !this.surveyQuestions[ordinal - 1].response) {
            style += " alert alert-danger";
        }

        if (ordinal !== this.currentQuestionOrdinal) {
            style += " faded-question";
        }

        return style;
    }

    public async ngOnInit() {
        this.shellUiService.removeDefaultShellPadding(); // intercom won't interfere with survey content (centralised and empty on the right)
        this.token = this.getSearchParameterValue("token")!;
        if (this.token) {
            this.returnPath = this.getSearchParameterValue("returnPath");
            this.surveyService.getSurveyDetails(this.token)
                .pipe(finalize(() => this.notifyActivated())) // complete in the subscribe won't be called if error
                .subscribe({
                    next: (result) => {
                        this.surveyDetails = result;
                        this.title = `${SurveyTypeLabel[this.surveyDetails.surveyType]} survey `;
                        if (this.surveyDetails.teamName) {
                            this.title += " for " + this.surveyDetails.teamName;
                        } else {
                            this.title += " at " + this.surveyDetails.organisationName;
                        }

                        this.surveyDescription = SurveyUtils.forSurveyType(this.surveyDetails.surveyType).surveyDescription;

                        this.initialise();
                    },
                    error: (response) => this.errorMessage = response.error.Message,
                });
        } else if (this.routeService.currentControllerId === SignupHealthCheckPageRoute.id) {
            if (this.isLoggedIn) {
                await this.identityService.logout(true);
            }

            this.isTokenlessSurvey = true;
            this.introArticle = ImplementationKitArticle.UnderstandJourneyAlternateSignup;
            const surveyType = SurveyType.OrganisationDiagnostic;
            this.surveyDescription = SurveyUtils.forSurveyType(surveyType).surveyDescription;
            this.surveyDetails = {
                surveyName: "Take your business health check",
                organisationName: "your organisation",
                surveyType,
            } as SurveyDetails;
            this.title = this.surveyDetails.surveyName;

            this.initialise();
            this.notifyActivated();
        } else {
            this.errorMessage = "This is not a valid survey.";
            this.notifyActivated();
        }

        // this is only used if the token is invalid or survey ended where we cannot use token to retrieve survey anymore
        this.surveyNameFromParam = this.getSearchParameterValue("name");
    }

    public submitResponse() {
        if (this.token && !this.incomplete) {
            this.submitErrorMessage = undefined;
            this.isSubmitting = true;
            const surveyResponse = {
                token: this.token,
                questionResponses: [],
                surveyType: this.surveyDetails!.surveyType,
            } as SurveyResponseBindingModel;

            for (const q of this.surveyQuestions) {
                surveyResponse.questionResponses.push({
                    surveyQuestionId: q.questionId,
                    response: q.response,
                });
            }

            this.surveyService.postSurveyResponse(surveyResponse).pipe(
                finalize(() => this.isSubmitting = false),
            ).subscribe({
                next: () => {
                    this.submitted = true;
                    this.clearResponseStore();
                    if (this.returnPath) {
                        this.routeService.navigateByUrl(this.returnPath);
                    }
                },
                error: (response) => this.submitErrorMessage = response.error.Message,
            });
        }
    }

    public analyseResults() {
        if (!this.incomplete) {
            this.submitErrorMessage = undefined;
            this.isSubmitting = true;

            this.signupPageRoute.gotoRoute().subscribe();
        }
    }

    // Cannot do this once off as having it on all the time will cause scrolling scrollbar to be non-responsive
    // - Only enable this when scrolling to question ordinal
    private setSmoothScrolling(enable: boolean) {
        // Cannot scroll with animation
        // https://supportcenter.devexpress.com/Ticket/Details/T488984/dxscrollview-scrollto-with-animation
        //
        // have to use jQuery here to override the scroll-behaviour of dx-scrollable-container
        // (can't use scss unless we remove encapsulation which I don't really want to as it will affect all)
        // - just do it once off when scrollable container is available (not available on view init)
        const element = jQuery(this.el.nativeElement);
        const scrollableContainer = element.find(".dx-scrollable-container");
        if (!this.smoothScrolling && enable) {
            if (scrollableContainer.length === 1 && scrollableContainer.css(this.ScrollBehaviour) !== this.Smooth) {
                scrollableContainer.css(this.ScrollBehaviour, this.Smooth);
                this.smoothScrolling = true;
            }
        } else if (this.smoothScrolling && !enable) {
            if (scrollableContainer.length === 1 && scrollableContainer.css(this.ScrollBehaviour) === this.Smooth) {
                scrollableContainer.css(this.ScrollBehaviour, "auto");
                this.smoothScrolling = false;
            }
        }
    }

    private scrollToQuestionOrdinal(ordinal: number, forceScroll = false) {
        const element = jQuery(this.el.nativeElement);
        const scrollableElement = element.find(this.ScrollableElement);
        const questionElement = element.find("#" + this.QuestionElementPrefix + ordinal);
        if (scrollableElement.length === 1 && questionElement.length === 1 && this.scrollViewInstance) {
            // only scroll to element if not completely visible!
            if (forceScroll || !this.questionElementVisible(questionElement, scrollableElement)) {
                const currentScrollPosition = this.scrollViewInstance.scrollOffset().top;
                const questionHeight = questionElement.height()!;
                const scrollViewHeight = scrollableElement.height()!;
                const centerOffset = scrollViewHeight > questionHeight ? (scrollViewHeight - questionHeight) / 2 : 0;
                const destinationScrollPosition = questionElement.offset()!.top - centerOffset + currentScrollPosition - scrollableElement.offset()!.top;
                this.setSmoothScrolling(true);
                // tried to use scrollToElement here but that won't show the whole element, only top of it
                this.scrollViewInstance.scrollTo(Math.ceil(destinationScrollPosition) - this.BorderOffset);
            }
        }
    }

    private questionElementVisible(questionElement: JQuery<HTMLElement>, scrollableElement: JQuery<HTMLElement>) {
        const scrollViewOffset = scrollableElement.offset()!.top;
        const questionElementOffset = questionElement.offset()!.top;
        if (questionElementOffset < scrollViewOffset ||
            (this.BorderOffset + questionElementOffset + questionElement.height()!) > (scrollViewOffset + scrollableElement.height()!)) {
            return false;
        } else {
            return true;
        }
    }

    private focusQuestionOnScrolled() {
        const self = this;
        const element = jQuery(this.el.nativeElement);
        const scrollableElement = element.find(this.ScrollableElement);
        const questionElement = element.find("#" + this.QuestionElementPrefix + this.currentQuestionOrdinal);
        if (scrollableElement.length === 1 && questionElement.length === 1 && this.scrollViewInstance) {
            if (!this.questionElementVisible(questionElement, scrollableElement)) {
                if (this.scrollDown) {
                    for (let i = this.currentQuestionOrdinal; i < this.surveyQuestions.length; i++) {
                        if (questionVisible(this.surveyQuestions[i], scrollableElement)) {
                            this.currentQuestionOrdinal = this.surveyQuestions[i].ordinal;
                            break;
                        }
                    }
                } else {
                    for (let i = this.currentQuestionOrdinal - 2; i >= 0; i--) {
                        if (questionVisible(this.surveyQuestions[i], scrollableElement)) {
                            this.currentQuestionOrdinal = this.surveyQuestions[i].ordinal;
                            break;
                        }
                    }
                }
            }
        }

        function questionVisible(question: ISurveyQuestionDisplay, scrollViewElement: JQuery<HTMLElement>) {
            const qElem = element.find("#" + self.QuestionElementPrefix + question.ordinal);
            if (qElem.length === 1) {
                return self.questionElementVisible(qElem, scrollViewElement);
            }

            return false;
        }
    }

    private initialise() {
        if (this.surveyDetails) {
            this.surveyQuestions = [];
            this.loadResponseStore();

            const surveyQuestionSet = SurveyUtils.getSurveyQuestions(this.surveyDetails);
            this.questionResponses = surveyQuestionSet.surveyResponseChoices;

            if (this.surveyQuestions.length === 0) {
                // only re-initializing survey questions if not previously saved (want to keep order of restoring)
                const questionIds = surveyQuestionSet.questionIds;
                let ordinal = 1;
                for (const questionId of questionIds) {
                    const question = surveyQuestionSet.getQuestion(questionId);
                    if (question) {
                        this.surveyQuestions.push({
                            ordinal,
                            questionId,
                            questionText: question,
                        });
                        ordinal++;
                    }
                }
            }

            const categoryIds = surveyQuestionSet.categoryIds;
            if (categoryIds && categoryIds.length > 0) {
                for (const categoryId of categoryIds) {
                    const category = surveyQuestionSet.getCategory(categoryId);
                    const catIntro = category?.categoryPageIntro;
                    if (catIntro && category?.questionIds) {
                        this.surveyQuestionsGroupedByCategory.push({
                            categoryName: category?.categoryName,
                            categoryIntro: catIntro,
                            questions: this.surveyQuestions.filter((q) => category?.questionIds.indexOf(q.questionId) >= 0),
                        });
                    }
                }
            }

            // need to call this after questions are loaded to update the scrollbar or it will appear as just a single dot
            setTimeout(() => this.scrollViewInstance?.update(), 500);
        }
    }

    private storeResponse() {
        if (this.surveyDetails) {
            SurveyService.storeSurveyQuestions(this.surveyQuestions, this.surveyDetails.surveyType, this.token);
        }
    }

    private clearResponseStore() {
        if (this.surveyDetails) {
            SurveyService.clearSurveyQuestionsFromStorage(this.surveyDetails.surveyType, this.token);
        }
    }

    private loadResponseStore() {
        if (this.surveyDetails) {
            const previousStore = SurveyService.getSurveyQuestionsFromStorage(this.surveyDetails.surveyType, this.token);
            if (previousStore) {
                this.surveyQuestions = previousStore;
            } else {
                // not an array - clear the store
                this.clearResponseStore();
            }
        }
    }
}
