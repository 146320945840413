import { DOCUMENT } from "@angular/common";
import { Component, ElementRef, HostBinding, Inject, OnDestroy, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { baseZIndex } from "@common/lib/ux-initialiser";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { PositionConfig } from "devextreme/animation/position";
import { ContentReadyEvent } from "devextreme/ui/popup";
import { DxScrollViewComponent } from "devextreme-angular";
import { BehaviorSubject, fromEvent } from "rxjs";
import { throttleTime } from "rxjs/operators";
import { ContextSidebarSource, ContextSidebarState, contextSidebarWidth, IContextSidebar, ISidebarContent, SidebarOpenStates } from "../context-sidebar.interface";
import { ContextSidebarService } from "../context-sidebar.service";

@Component({
    selector: "adapt-context-sidebar",
    templateUrl: "./context-sidebar.component.html",
    styleUrls: ["./context-sidebar.component.scss"],
})
export class ContextSidebarComponent extends BaseComponent implements IContextSidebar, OnDestroy {
    @HostBinding("style.zIndex") public zIndex?: number;

    private contentWrapper?: ElementRef<HTMLElement>;
    @ViewChild("contentWrapper") public set setContentWrapper(wrapper: ElementRef<HTMLElement>) {
        if (this.contentWrapper !== wrapper) {
            this.contentWrapper = wrapper;
            this.updateState();
        }
    }

    @ViewChild("sidebarTitle") private sidebarTitle?: ElementRef<HTMLElement>;
    @ViewChild("sidebarContent") private sidebarContent?: ElementRef<HTMLElement>;

    @ViewChild("popupTitle") private popupTitle?: ElementRef<HTMLElement>;
    @ViewChild("popupContent") private popupContent?: ElementRef<HTMLElement>;

    @ViewChildren(DxScrollViewComponent) private scrollViews?: QueryList<DxScrollViewComponent>;

    public readonly contextSidebarWidth = contextSidebarWidth;
    public readonly ContextSidebarState = ContextSidebarState;

    public isOpen = false;
    public popupPosition: PositionConfig;

    private maxWidth?: number;
    private forcedState?: SidebarOpenStates;

    private contentStack: ISidebarContent[] = [];
    private titleStack: ISidebarContent[] = [];

    private _contextSidebarState = new BehaviorSubject<ContextSidebarState>(ContextSidebarState.Closed);
    public contextSidebarState$ = this._contextSidebarState.asObservable();

    private hasDialogContent$ = new BehaviorSubject<boolean>(false);

    public get showPopup() {
        return this._contextSidebarState.value === ContextSidebarState.Popup
            || this._contextSidebarState.value === ContextSidebarState.Fullscreen;
    }

    public get hasDialogContent() {
        return this.contentStack.length > 0
            && this.getContent()?.source === ContextSidebarSource.Dialog;
    }

    public get hasDialogContentChange$() {
        return this.hasDialogContent$.asObservable();
    }

    private get showingSidebar() {
        return this._contextSidebarState.value === ContextSidebarState.Open;
    }

    public constructor(
        @Inject(DOCUMENT) private document: Document,
        public responsiveService: ResponsiveService,
        private contextSidebarService: ContextSidebarService,
    ) {
        super();

        this.popupPosition = {
            my: "top",
            at: "top",
            of: window,
            offset: { x: 0, y: 64 },
        };

        fromEvent(window, "resize").pipe(
            throttleTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateState());

        this.contextSidebarService.registerContextSidebar(this);
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        this.contextSidebarService.clearContextSidebar();
    }

    public setIsOpen(isOpen: boolean, state?: SidebarOpenStates) {
        this.isOpen = isOpen;

        this.forcedState = state;
        this.updateState();
        this.forcedState = undefined;
    }

    public setContent(content: ISidebarContent["content"], source: ContextSidebarSource = ContextSidebarSource.Page) {
        this.contentStack.push({ content, source });
        this.updateState();
        this.hasDialogContent$.next(this.hasDialogContent);
    }

    public clearContent() {
        // hide the sidebar when content has been cleared e.g. navigating away from a page
        if (this.contentStack.length === 1) {
            this.setIsOpen(false);
        } else {
            this.updateState();
        }

        // pop after changing state so content is still visible as it hides
        this.contentStack.pop();

        // if the title was generated we need to clear it
        if (this.titleStack.length !== 0
            && this.titleStack[this.titleStack.length - 1].default === true) {
            this.titleStack.pop();
        }

        this.hasDialogContent$.next(this.hasDialogContent);
    }

    public setTitle(content: ISidebarContent["content"], source: ContextSidebarSource = ContextSidebarSource.Page) {
        this.titleStack.push({ content, source });
        this.updateState();
    }

    public clearTitle() {
        this.titleStack.pop();
        this.updateState();
    }

    public setMaxWidth(width?: number) {
        this.maxWidth = width;
        this.updateState();
    }

    public onPopupContentReady(event: ContentReadyEvent) {
        // remove padding of popup so scrollbar can be flush
        const content = jQuery(event.component.content());
        content.css("padding", 0);
    }

    public onHidePopup() {
        // this callback is triggered whether a user manually closes the popup,
        // or if it is closed due to resizing.
        // if not closing due to resize, close the sidebar.
        if (!this.showingSidebar) {
            this.setIsOpen(false);
        }
    }

    private updateState() {
        if (this.isOpen) {
            // don't need to update anything if in fullscreen mode
            // but do update if user forcing out of fullscreen
            if (this._contextSidebarState.value !== ContextSidebarState.Fullscreen
                || (this.forcedState !== undefined && this.forcedState !== ContextSidebarState.Fullscreen)) {
                const sidebarFits = this.maxWidth !== undefined
                    ? ((this.maxWidth + contextSidebarWidth) < window.innerWidth)
                    : this.responsiveService.currentBreakpoint.is(Breakpoint.LG);

                const newState = this.forcedState ?? (sidebarFits
                    ? ContextSidebarState.Open
                    : ContextSidebarState.Popup);
                if (this._contextSidebarState.value !== newState) {
                    this._contextSidebarState.next(newState);

                    // scroll dx-scroll-views to the top on state change
                    this.scrollViews?.forEach((view) => view.instance.scrollTo(0));
                }
                this.updateSidebarMargin(newState === ContextSidebarState.Open);
                this.updateContents(newState);
                this.updateZIndex();
            }
        } else {
            if (this._contextSidebarState.value !== ContextSidebarState.Closed) {
                this._contextSidebarState.next(ContextSidebarState.Closed);
            }
            this.updateSidebarMargin(false);
        }
    }

    private updateSidebarMargin(isVisible: boolean) {
        if (this.contentWrapper) {
            const wrapperElement = this.contentWrapper.nativeElement;
            const currentSidebarWidth = this.document.defaultView?.getComputedStyle(wrapperElement).width ?? "0px";
            wrapperElement.style.marginRight = isVisible
                ? "0"
                : `-${currentSidebarWidth}`;
        }
    }

    private async updateContents(state: SidebarOpenStates) {
        // wait a frame to allow changes to digest
        await new Promise(requestAnimationFrame);

        const active: { [k in SidebarOpenStates]: (ElementRef<HTMLElement> | undefined)[] } = {
            [ContextSidebarState.Open]: [this.sidebarTitle, this.sidebarContent],
            [ContextSidebarState.Popup]: [this.popupTitle, this.popupContent],
            [ContextSidebarState.Fullscreen]: [this.popupTitle, this.popupContent],
        };

        const [activeTitle, activeContent] = active[state];
        this.setElementContent(activeTitle, this.getTitle()?.content);
        this.setElementContent(activeContent, this.getContent()?.content);
    }

    private getTitle() {
        const hasTitles = this.titleStack.length !== 0;
        // no title found to use, create one
        if (!hasTitles) {
            const content = document.createElement("h2");
            content.textContent = "Guidance";
            this.titleStack.push({
                content,
                // set the title source to the same as the current content
                source: this.getContent()?.source ?? ContextSidebarSource.Page,
                default: true,
            });
        }

        return this.titleStack[this.titleStack.length - 1];
    }

    private getContent() {
        return this.contentStack.length !== 0
            ? this.contentStack[this.contentStack.length - 1]
            : undefined;
    }

    private setElementContent(element?: ElementRef<HTMLElement>, content?: ISidebarContent["content"]) {
        if (element) {
            // only clear the element if content not already inside.
            // if we reset regardless, this causes fullscreen youtube videos in the sidebar to close.
            // (going fullscreen fires window.resize, which calls updateState)
            if (!element.nativeElement.contains(content ?? null)) {
                // clear out the current contents
                element.nativeElement.textContent = "";

                if (content) {
                    element.nativeElement.append(content);
                }
            }
        }
    }

    private updateZIndex() {
        // get the highest z-index from any dx overlays
        const devExtremeOverlays = Array.from(this.document.querySelectorAll(".dx-overlay-wrapper"));
        const highestZIndex = devExtremeOverlays.reduce((acc, elem) => Math.max(acc, this.getZIndex(elem) ?? 0), baseZIndex);

        // setTimeout to get around ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => this.zIndex = highestZIndex + 1);
    }

    private getZIndex(element: Element) {
        const computedStyle = getComputedStyle(element, null);
        const zIndex = computedStyle.getPropertyValue("z-index");
        return zIndex !== "auto" ? parseInt(zIndex, 10) : null;
    }
}
