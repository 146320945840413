<adapt-dialog [maxWidth]="600"
              [articleId]="ImplementationKitArticle.ValueGuidance">
    <h3 adapt-dialog-title>{{title}}</h3>
    <div adapt-dialog-content>
        <dx-text-box #focus
                     [(value)]="value.name"
                     placeholder="Enter value name"
                     data-test="value-name-input">
            <dx-validator>
                <dxi-validation-rule [adaptEntity]="value"
                                     adaptEntityProperty="name"></dxi-validation-rule>
                <dxi-validation-rule [adaptEntity]="value"
                                     [adaptBreezeValidator]="uniqueValueNameValidator"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
        <hr />
        <h3>Our meaning</h3>
        <adapt-html-editor id="inputMeaning"
                           name="inputMeaning"
                           [(contents)]="value.meaning"
                           data-test="value-meaning-input"></adapt-html-editor>
        <hr />
        <h3>Ask yourself questions</h3>
        <div class="p-2 mb-2 pattern">
            <dx-list [dataSource]="questions"
                     noDataText="No questions defined."
                     [activeStateEnabled]="false"
                     [focusStateEnabled]="false"
                     [hoverStateEnabled]="false"
                     (onItemReordered)="updateValueQuestionOrdinals($event)">
                <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>

                <div *dxTemplate="let valueQuestion of 'item'"
                     class="value-question-list-item"
                     data-test="value-question-item">
                    <div class="question-content"
                         [froalaView]="valueQuestion.question"></div>
                    <div>
                        <button class="btn btn-link"
                                adaptButtonIcon="edit"
                                [adaptBlockingClick]="editQuestion"
                                [adaptBlockingClickParam]="valueQuestion"
                                data-test="edit-value-question-button"></button>
                        <button class="btn btn-link"
                                adaptButtonIcon="delete"
                                [adaptBlockingClick]="deleteQuestion"
                                [adaptBlockingClickParam]="valueQuestion"
                                data-test="delete-value-question-button"></button>
                    </div>
                </div>
            </dx-list>


        </div>
        <button adaptButtonIcon="add"
                class="btn btn-primary btn-small"
                [adaptBlockingClick]="addQuestion"
                data-test="add-question-button">Add question
        </button>
    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="isInvalidOrNoChanges"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
