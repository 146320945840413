<adapt-toolbar-content alignment="right">
    <button adaptButton="view"
            (click)="viewCoachingSessionHistory()">coaching session history
    </button>
</adapt-toolbar-content>

<div class="row">
    <div class="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7 m-auto d-flex gap-3 flex-column">
        <div class="content">
            <adapt-implementation-kit-content [articleId]="ImplementationKitArticle.CoachAccess"></adapt-implementation-kit-content>
        </div>

        <ng-container *adaptLoading="!account">
            <div>
                <h3>Please describe the issue that you're facing</h3>
                <dx-text-area placeholder="Describe your issue..."
                              [disabled]="submitting"
                              [(value)]="description"
                              [inputAttr]="{ rows: 3 }">
                    <dx-validator>
                        <dxi-validation-rule type="required"
                                             message="This is required"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-area>
            </div>

            <div>
                <h3>Select coach time</h3>
                <dx-radio-group [disabled]="submitting"
                                [items]="coachOptions"
                                [(value)]="coachOption">
                    <div *dxTemplate="let option of 'item'"
                         class="d-flex align-items-center gap-3 w-100">
                        <ng-container *ngIf="option.adjustableTime; else fixedCost">
                            <div>{{option.name}} at {{option.cost | currency: account!.currency?.shortName}} / 1 hour
                            </div>
                            <div class="ms-auto d-flex align-items-center gap-3">
                                <dx-number-box format="#0 hours"
                                               [(value)]="hoursRequested"
                                               [disabled]="coachOption !== customSession || submitting"
                                               [showSpinButtons]="true"
                                               [width]="110"
                                               [min]="1"
                                               [max]="3"></dx-number-box>
                                <div>Subtotal: {{customSessionCost | currency: account!.currency?.shortName}}</div>
                            </div>
                        </ng-container>
                        <ng-template #fixedCost>
                            <div>{{option.name}} - {{option.cost | currency: account!.currency?.shortName}}
                                / {{option.minutes}} minutes
                            </div>
                        </ng-template>
                    </div>
                </dx-radio-group>
            </div>

            <div *ngIf="account && account.extensions.isBilledUsingCreditCard; else notCreditCard">
                <h3>Payment method
                    <a class="ms-2 btn btn-link small"
                       [class.disabled]="submitting"
                       (click)="!submitting && configureCard.showUpdateCreditCardDialog().subscribe()">Update payment
                        method</a>
                </h3>

                <div [ngClass]="{'alert alert-light pt-2 pb-0': cardIsSet}">
                    <adapt-configure-credit-card #configureCard
                                                 [organisationId]="account.organisationId"
                                                 [allowEditing]="false"
                                                 (cardIsSet)="cardIsSet = $event"></adapt-configure-credit-card>
                </div>
            </div>

            <ng-template #notCreditCard>
                <h3>Payment method</h3>
                <p>Your organisation is not paying via credit card. You cannot request a coach at this time.</p>
            </ng-template>

            <p>
                You will be charged a total of <strong>{{totalDisplay}}</strong>
                <ng-container *ngIf="!account?.taxExempt"> (inc. GST)</ng-container>
                upon requesting a coach.
                You will then be prompted to schedule a date to work with one of our coaches.
            </p>

            <div class="alert alert-danger mt-3"
                 *ngIf="errorMessage">{{errorMessage}}
            </div>

            <button class="w-100 btn btn-primary"
                    [adaptBlockingClick]="requestCoach"
                    [disabled]="!cardIsSet || !description || submitting">Request a coach<i class="fal fa-angle-right ms-2"></i></button>
        </ng-container>
    </div>
</div>
