<label>Goal title</label>
<dx-text-box [(value)]="goal!.name"
             (valueChange)="updateCompletion()"
             [maxLength]="60"
             [adaptValidateEntity]="goal"
             adaptValidateEntityProperty="name"></dx-text-box>

<div class="d-flex w-50 flex-column">
    <label class="mt-3">Zone</label>
    <adapt-select-zone [(zone)]="goal!.zone"
                       [required]="true"
                       [filter]="filterZone"></adapt-select-zone>
</div>

<label class="mt-3">Description</label>
<adapt-html-editor [(contents)]="goal!.description"
                   forceSize="compact"
                   [minHeight]="240"></adapt-html-editor>
