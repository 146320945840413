<adapt-dialog [maxWidth]="1000"
              [padding]="false">
    <h3 adapt-dialog-title>Objective review</h3>
    <div adapt-dialog-content
         class="h-100 m-2 row">
        <div [adaptCollapsible]="objectiveReviewArticle"
             class="col-12 col-lg-6">
            <adapt-styled-implementation-kit [articleId]="objectiveReviewArticle"
                                             contentStyle="embedded"></adapt-styled-implementation-kit>
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column ps-lg-3 gap-3 pt-3 pt-lg-0">
            <div class="flex-grow-1 d-flex flex-column">
                <h3>What went well?</h3>
                <adapt-html-editor [(contents)]="objectiveReview.whatWentWell"
                                   forceSize="compact"
                                   class="flex-grow-1"
                                   data-test="objective-review-went-well"></adapt-html-editor>
            </div>
            <div class="flex-grow-1 d-flex flex-column">
                <h3>What didn't go well?</h3>
                <adapt-html-editor [(contents)]="objectiveReview.whatWentWrong"
                                   forceSize="compact"
                                   class="flex-grow-1"
                                   data-test="objective-review-went-wrong"></adapt-html-editor>
            </div>
            <div class="flex-grow-1 d-flex flex-column">
                <h3>What did we learn?</h3>
                <adapt-html-editor [(contents)]="objectiveReview.whatWeLearned"
                                   forceSize="compact"
                                   class="flex-grow-1"
                                   data-test="objective-review-learned"></adapt-html-editor>
            </div>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
