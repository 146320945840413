import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { BreezeService } from "@common/lib/data/breeze.service";
import { IBreezeParameter } from "@common/lib/data/breeze-parameter.interface";
import { AdaptPaymentProcessingModule } from "@common/payment-processing/payment-processing.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptCollapsibleModule } from "@common/ux/collapsible/collapsible.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { XstateAngular } from "@common/xstate-angular/useMachine";
import { DxButtonGroupModule, DxTemplateModule } from "devextreme-angular";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { AdaptCommonConfigurationModule } from "../configuration/configuration.module";
import { SetInitialSubscriptionDialogComponent } from "./account/set-initial-subscription-dialog/set-initial-subscription-dialog.component";
import { OrganisationService } from "./organisation.service";
import { OrganisationAuthService } from "./organisation-auth.service";
import { provideOrganisationEntityUpdatedEventHandler } from "./organisation-event-handler.interface";
import { OrganisationIdentifierTracker } from "./organisation-identifier-tracker.service";

@NgModule({
    imports: [
        CommonModule,
        AdaptCommonConfigurationModule,
        AdaptCommonDialogModule,
        AdaptLoadingSpinnerModule,
        AdaptPaymentProcessingModule,
        AdaptTooltipModule,
        AdaptDateModule,
        DxButtonGroupModule,
        DxTemplateModule,
        AdaptImplementationKitModule,
        AdaptCollapsibleModule,
    ],
    declarations: [
        SetInitialSubscriptionDialogComponent,
    ],
    providers: [
        XstateAngular,
        provideOrganisationEntityUpdatedEventHandler(OrganisationIdentifierTracker),
    ],
})
export class AdaptOrganisationModule {
    constructor(authorisationService: AuthorisationService) {
        const orgIdInvoker = {
            paramName: "organisationId",
            invoker: (injector: Injector) => {
                const orgService = injector.get(OrganisationService);
                return orgService.getOrganisationId();
            },
            valueIsValid: (orgId) => orgId > 0,
            waitObservable: (injector: Injector) => {
                const orgService = injector.get(OrganisationService);
                return orgService.waitForOrganisationEntity();
            },
        } as IBreezeParameter<number>;
        BreezeService.setOrganisationIdInvoker(orgIdInvoker);

        OrganisationAuthService.registerAccess(authorisationService);
    }
}
