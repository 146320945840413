<adapt-dialog [maxWidth]="950"
              [padding]="false">
    <h3 adapt-dialog-title>{{mode}} action</h3>

    <adapt-item-action-menu adapt-dialog-menu
                            adaptTooltip="Actions"
                            *ngIf="!addMode"
                            [item]="item"
                            (itemClosed)="itemAction.emit(EditItemAction.Close)"
                            (itemMoved)="itemAction.emit(EditItemAction.Move)"
                            (itemDeleted)="itemAction.emit(EditItemAction.Delete)"
                            (itemReopened)="refreshEdit()"
                            data-test="edit-item-menu"></adapt-item-action-menu>

    <div adapt-dialog-content
         class="edit-item-dialog d-flex flex-column h-100"
         [ngClass]="{'edit-item-directive-personal-item': item.board?.isPersonalBoard}">
        <div class="row"
             *ngIf="!addMode">
            <div class="deleted-item-banner col-md-12"
                 *ngIf="itemHasBeenDeleted">
                <p class="deleted-item-text lead">This action has been deleted.</p>
            </div>

            <ng-container *ngIf="canEdit; else viewOnlyTitleBar">
                <div class="title-bar col-md-12">
                    <div class="divider d-flex gap-2 w-100"
                         [ngClass]="{'inactive-entity': itemIsClosed}">
                        <div class="d-flex align-items-center gap-2">
                            <i class="fal fa-fw fa-2x fa-info-circle"
                               *ngIf="itemIsClosed"
                               adaptTooltip="This action has been archived and is no longer editable">
                            </i>
                            <h3 class="m-0 text-nowrap">{{item.code}}</h3>
                        </div>
                        <dx-text-box class="flex-grow-1"
                                     #focus
                                     [adaptValidateEntity]="item"
                                     adaptValidateEntityProperty="summary"
                                     [(value)]="item.summary"
                                     data-test="item-summary">
                        </dx-text-box>
                        <adapt-select-item-status *ngIf="!isMobileSize"
                                                  [(status)]="item.status"
                                                  (statusChange)="refreshEdit()"
                                                  [disabled]="!isValidBoardSelection"></adapt-select-item-status>
                    </div>
                </div>
            </ng-container>
            <ng-template #viewOnlyTitleBar>
                <div class="title-bar col-md-12">
                    <div class="divider d-flex flex-row justify-content-start"
                         [ngClass]="{'inactive-entity': itemIsClosed}">

                        <div class="d-flex flex-row me-2 align-items-center">
                            <i class="fal fa-fw fa-2x fa-info-circle"
                               *ngIf="itemIsClosed"
                               adaptTooltip="This action has been archived">
                            </i>
                            <adapt-item-link [item]="item"
                                             [showDialogLink]="false"></adapt-item-link>
                        </div>

                        <adapt-display-item-status [itemStatus]="item.status"></adapt-display-item-status>
                    </div>
                </div>
            </ng-template>
        </div>

        <div class="row item-content-container flex-grow-1">
            <div class="mainbar flex-fill col-md-7 col-lg-8">
                <ng-container *ngIf="addMode">
                    <!-- board (shown for new items only)-->
                    <div class="form-group">
                        <label for="inputBoard">Board</label>
                        <adapt-select-board id="inputBoard"
                                            [required]="true"
                                            [showEditableBoardsOnly]="true"
                                            [showColouredIcon]="true"
                                            [showTeamBoards]="teamBoardsOnly"
                                            [team]="$any(itemTeam)"
                                            [boards]="item.board ? [item.board] : []"
                                            (boardsChange)="onBoardChange($event[0])"
                                            data-test="select-item-board"></adapt-select-board>
                    </div>
                    <!-- Summary -->
                    <div class="form-group">
                        <label for="inputSummary">Summary</label>
                        <dx-text-box id="inputSummary"
                                     #focus
                                     [adaptValidateEntity]="item"
                                     adaptValidateEntityProperty="summary"
                                     [(value)]="item.summary"
                                     data-test="item-summary">
                        </dx-text-box>
                    </div>
                </ng-container>
                <ng-container *ngIf="canEdit; else viewOnlyMain">
                    <!-- description -->
                    <div class="form-group">
                        <label for="inputDescription">Description</label>
                        <adapt-html-editor id="inputDescription"
                                           [(contents)]="item.description"
                                           data-test="item-description"></adapt-html-editor>
                    </div>
                    <adapt-edit-item-comments *ngIf="!addMode && !isMobileSize"
                                              class="form-group form-group-comments"
                                              [item]="item"
                                              [allowEdit]="true"></adapt-edit-item-comments>
                </ng-container>
                <ng-template #viewOnlyMain>
                    <div class="form-group">
                        <h2 class="mb-0 text-wrap">{{item.summary}}</h2>
                        <small *ngIf="item.dueDate"
                               class="mt-1 d-block">Due: {{item.dueDate | adaptDate}}</small>
                    </div>
                    <div class="form-group">
                        <div [froalaView]="item.description"></div>
                    </div>
                    <adapt-edit-item-comments class="form-group form-group-comments"
                                              [item]="item"
                                              [allowEdit]="false"></adapt-edit-item-comments>
                </ng-template>
            </div>
            <div class="sidebar col-md-5 col-lg-4 mt-xs-0 mt-sm-0 mt-md-3 pt-xs-0 pt-sm-0">
                <div *ngIf="(addMode || isMobileSize) && canEdit"
                     class="form-group">
                    <label for="inputStatus">Status</label>
                    <div class="d-flex"> <!-- Wrap this in a d-flex to not extend to the entire width -->
                        <adapt-select-item-status id="inputStatus"
                                                  [(status)]="item.status"
                                                  [disabled]="!isValidBoardSelection"></adapt-select-item-status>
                    </div>
                </div>
                <ng-container *ngIf="canEdit; else viewOnlyMetadata">
                    <!-- Assignee -->
                    <div class="form-group"
                         *ngIf="item?.board?.isTeamBoard">
                        <label for="inputAssignee">Assignee</label>
                        <adapt-select-person id="inputAssignee"
                                             [(person)]="item.assignee"
                                             [teamGroup]="$any(item.board?.team)"
                                             [filter]="hasAccessToView"
                                             [disabled]="!this.isValidBoardSelection"
                                             data-test="item-assignee"></adapt-select-person>
                    </div>

                    <div class="form-group">
                        <label for="labelSelector">Labels</label>
                        <adapt-select-label-for-related-entity [item]="item"
                                                               (entitiesChange)="changedLabelLocations = $event"
                                                               id="labelSelector"></adapt-select-label-for-related-entity>
                    </div>

                    <!-- Due Date -->
                    <div class="form-group divider">
                        <label for="inputDueDate">Due date</label>
                        <dx-date-box id="inputDueDate"
                                     type="date"
                                     [value]="item.dueDate"
                                     [showClearButton]="true"
                                     (valueChange)="item.dueDate = $any($event)"
                                     [disabled]="!isValidBoardSelection"
                                     data-test="item-due-date"></dx-date-box>
                    </div>
                </ng-container>
                <ng-template #viewOnlyMetadata>
                    <!-- Assignee -->
                    <div class="form-group"
                         *ngIf="item?.board?.isTeamBoard">
                        <label for="assignee">Assignee</label>
                        <div id="assignee"
                             class="mt-2">
                            <adapt-link-person [person]="item.assignee"
                                               [showImage]="true"></adapt-link-person>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="labelSelector">Labels</label>
                        <div>
                            <adapt-display-labels [labels]="itemLabels$ | async"></adapt-display-labels>
                        </div>
                    </div>
                    <!-- don't have to worry about category here as personal board can only be edit/read by owner -->
                </ng-template>
                <!-- links -->
                <div class="form-group"
                     *ngIf="item.links?.length || objectiveItemLinks.length">
                    <label for="itemLinks">
                        Links
                    </label>
                    <div id="itemLinks"
                         class="itemLinkGroup d-flex flex-column gap-1">
                        <ng-container *adaptLoading="loadingRelatedInfo">
                            <ng-container *ngIf="objectiveItemLinks?.length">
                                <small>Objectives</small>
                                <adapt-display-linked-objectives [objectives]="linkedObjectives"
                                                                 [showGroupObjectiveTextSuffix]="false"></adapt-display-linked-objectives>
                            </ng-container>

                            <small *ngIf="item.links?.length">Actions</small>
                            <div *ngFor="let link of item.links"
                                 class="d-flex gap-1 align-items-baseline">
                                <adapt-item-link [item]="link.secondaryItem"
                                                 [showStatus]="true"
                                                 [showSummary]="true"
                                                 (itemDialogOpened)="close()"
                                                 [showPersonImageLink]="true"></adapt-item-link>
                                <button class="btn btn-link"
                                        (click)="deleteLink(link)"
                                        adaptTooltip="Delete link"
                                        *ngIf="canEdit"
                                        adaptButtonIcon="fal fa-fw fa-trash-alt"></button>
                            </div>

                        </ng-container>
                    </div>
                </div>

                <!-- related items -->
                <div class="form-group"
                     *ngIf="item?.meetingItem?.meeting">
                    <label for="relatedMeeting">
                        Associated Meeting
                    </label>
                    <div id="relatedMeeting"
                         class="itemLinkGroup">
                        <adapt-link-team-meeting [meeting]="item.meetingItem!.meeting"></adapt-link-team-meeting>
                    </div>
                </div>

                <div class="form-group small text-muted">
                    <span>Created </span>
                    <adapt-entity-timestamp [timestamp]="item.createdDateTime"></adapt-entity-timestamp>
                    <ng-container *ngIf="item?.board?.isTeamBoard">
                        by
                        <adapt-link-person [person]="item.createdBy"></adapt-link-person>
                    </ng-container>

                    <div *ngIf="item.lastUpdatedDateTime > item.createdDateTime">
                        <span>Last updated </span>
                        <adapt-entity-timestamp [timestamp]="item.lastUpdatedDateTime"></adapt-entity-timestamp>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="isInvalidOrNoChanges && canEdit"
                                   [saveBlockingClick]="saveAndClose"
                                   [saveButtonPreset]="canEdit ? undefined: 'close'"
                                   [hideCancelButton]="!canEdit"
                                   (cancelClick)="cancel()"
                                   cancelButtonPreset="cancel"
                                   data-test="edit-item-dialog-buttons">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
