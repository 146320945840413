import { Injectable } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { RouteService } from "@common/route/route.service";
import { RouteEventsService } from "@common/route/route-events.service";
import { CommonSidebarConstants } from "@common/shell/common-sidebar/common-sidebar.constants";
import { ISidebarTab, SidebarTabPosition } from "@common/shell/common-sidebar/sidebar-tab";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { SidebarTabIconComponent } from "@common/shell/sidebar-tab-icon/sidebar-tab-icon.component";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { MeetingsUiService } from "@org-common/lib/meetings/meetings-ui.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { SearchTabId } from "@org-common/lib/search/search-tab-content/search-tab";
import { BehaviorSubject, merge, Observable, of, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { teamActiveMeetingPageRoute } from "../../meetings/team-active-meeting-page/team-active-meeting-page.route";
import { MenuTabId } from "../menu-tab-content/menu-tab-id";
import { TeamTabId } from "../team-tab-content/team-tab-id";
import { MeetingTabContentComponent } from "./meeting-tab-content.component";
import { MeetingTabId } from "./meeting-tab-id";

@Injectable()
export class MeetingTab implements ISidebarTab {
    public id = MeetingTabId;
    public label = "Live Meeting";
    public ordinal = 5;
    public position = SidebarTabPosition.Top;
    public isLoading$: Observable<boolean>;
    public isDisplayed$: Observable<boolean>;
    public isActive$: Observable<boolean>;
    public maxWidth = CommonSidebarConstants.MeetingTabMaxWidth;

    private triggerFocus$ = new Subject<void>(); // init before being used by focusTab$
    public focusTab$ = this.triggerFocus$.asObservable();

    private currentDisplay = false;

    public icon: IComponentRender<SidebarTabIconComponent> = {
        component: SidebarTabIconComponent,
        params: {
            iconClass: "fal fa-clipboard-list",
        },
    };

    public content: IComponentRender<MeetingTabContentComponent> = {
        component: MeetingTabContentComponent,
    };

    public activeMeetingForCurrentPerson$: Observable<Meeting | undefined>;

    private triggerUpdate$ = new BehaviorSubject<void>(undefined);

    public constructor(
        rxjsBreezeService: RxjsBreezeService,
        orgService: OrganisationService,
        shellUiService: ShellUiService,
        routeEventsService: RouteEventsService,
        routeService: RouteService,
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
    ) {
        // start the loading subject with false so that common-sidebar won't wait for it before setting the activeTab
        // which will only then subscribe to the isDisplayed$. Each triggerUpdate$ later will result in a spinner visible
        // in the sidebar after common-sidebar component template subscription.
        // Initialization of the other 3 navigation tabs are always going to be slower anyway as there are so many queries
        // involved whereas meeting tab only has this 1 single query here to get active meeting for current person.
        const loadingSubject = new BehaviorSubject<boolean>(false);
        this.isLoading$ = loadingSubject.asObservable();

        this.activeMeetingForCurrentPerson$ = this.triggerUpdate$.pipe(
            debounceTime(10),
            tap(() => loadingSubject.next(true)),
            switchMap(() => meetingsService.getFirstActiveMeetingForCurrentPerson()),
            tap(() => loadingSubject.next(false)),
        );

        // won't display if no current meeting
        this.isDisplayed$ = this.activeMeetingForCurrentPerson$.pipe(
            withLatestFrom(shellUiService.sidebarTab$, this.meetingsUiService.isUsingActiveMeetingPage$),
            tap(([meeting, tab, isUsingActiveMeetingPage]) => {
                const isDisplayed = !!meeting;
                if (isDisplayed
                    // only focus if previously not displayed
                    && !this.currentDisplay
                    // make sure we don't overwrite the search tab showing by default if we load on search page
                    && tab?.id !== SearchTabId
                    // we shouldn't focus the meeting tab if active meeting page is enabled
                    && !isUsingActiveMeetingPage
                ) {
                    this.triggerFocus$.next();
                } else if (!isDisplayed && this.currentDisplay) {
                    // currently displayed but meeting gone -> trigger tab switching
                    const targetTab = AdaptClientConfiguration.AdaptProject === AdaptProject.Alto ? MenuTabId : TeamTabId;
                    shellUiService.focusTab(targetTab);
                }

                this.currentDisplay = isDisplayed;
            }),
            map(([meeting]) => !!meeting),
        );

        this.isActive$ = routeEventsService.navigationEnd$.pipe(
            startWith(undefined),
            switchMap(() => this.meetingsUiService.isUsingActiveMeetingPage$),
            map((isUsingActiveMeetingPage) => isUsingActiveMeetingPage && routeService.currentControllerId === teamActiveMeetingPageRoute.id),
        );

        // this will be passed into MeetingTabContentComponent @Input() meeting$
        this.content.params = {
            meeting$: this.activeMeetingForCurrentPerson$,
        };

        // trigger update if any meeting is being saved
        // - don't have to unsubscribe as the tab instance will always exist, loaded by common sidebar component
        merge(
            this.meetingsService.meetingForMeetingAttendeeChangedForCurrentPerson$,
            rxjsBreezeService.entityTypeChanged(Meeting),
        ).subscribe(() => this.triggerUpdate$.next());

        this.meetingsUiService.isUsingActiveMeetingPage$.pipe(
            distinctUntilChanged(),
        ).subscribe(() => this.triggerUpdate$.next());

        // organisation change should trigger update too
        orgService.currentOrganisation$.subscribe((newOrg) => {
            if (newOrg) {
                this.triggerUpdate$.next();
            }
        });
    }

    public onClick(event: CustomEvent) {
        return this.activeMeetingForCurrentPerson$.pipe(
            take(1),
            withLatestFrom(this.meetingsUiService.isUsingActiveMeetingPage$),
            switchMap(([meeting, isUsingActiveMeetingPage]) => {
                if (meeting && isUsingActiveMeetingPage) {
                    // this will stop the default action of opening up the sidebar when clicking the tab
                    event.preventDefault();
                    return this.meetingsService.gotoActiveMeetingPage(meeting);
                } else {
                    return of(undefined);
                }
            }),
        );
    }
}
