<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.ObjectivesOverview"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-title>
    <h2 *ngIf="team$ | async as team; else orgTitle">
        {{team.name}} team objectives
    </h2>
    <ng-template #orgTitle>
        <h2>Organisation objectives</h2>
    </ng-template>
</adapt-toolbar-title>

<ng-container *ngIf="(teamObjectivesGroups$ | async) as teamObjectiveGroups">
    <div *ngIf="teamObjectiveGroups.length > 0">
        <adapt-button-toggle-group adaptToolbarContent
                                   alignment="center"
                                   [(value)]="currentView"
                                   (valueChange)="onViewChanged($event)">
            <button [adaptToggleGroupButton]="ViewType.ObjectiveOverview"
                    adaptButtonIcon="fal fa-list-alt"
                    data-test="overview-view-button">Overview</button>
            <button [adaptToggleGroupButton]="ViewType.ObjectiveListView"
                    adaptButtonIcon="fal fa-indent"
                    data-test="list-view-button">List</button>
            <button [adaptToggleGroupButton]="ViewType.ObjectiveTreeView"
                    adaptButtonIcon="fal fa-sitemap"
                    data-test="tree-view-button">Tree</button>
            <button [adaptToggleGroupButton]="ViewType.ObjectiveGridView"
                    adaptButtonIcon="fal fa-table"
                    data-test="grid-view-button">Grid</button>
        </adapt-button-toggle-group>
    </div>
</ng-container>

<div *ngIf="hasEditPermissions$ | async">
    <button adaptToolbarContent
            adaptButtonIcon="add"
            (click)="addObjective()"
            data-test="add-objective-button"
            data-tour="add-objective-button">Add objective</button>
    <adapt-menu *ngIf="containsSortableObjectives"
                adaptToolbarContent
                [ordinal]="2001"
                [items]="pageMenu"></adapt-menu>
</div>

<adapt-filter-content [isDefault]="isDefaultFilter"
                      (reset)="filterComponent.reset()"
                      [urlParamNames]="ObjectiveFilterParamKeys">
    <adapt-objective-filter #filterComponent
                            [team]="team$ | async"
                            (changed)="filterChanged($event)"></adapt-objective-filter>
</adapt-filter-content>

<ng-container *ngIf="(teamObjectivesGroups$ | async) as teamObjectiveGroups">
    <div *ngIf="teamObjectiveGroups.length > 0; else objectivesGuidance"
         [ngSwitch]="currentView">
        <ng-container *adaptLoading="!isInitialised">
            <ng-container *ngSwitchCase="ViewType.ObjectiveOverview">
                <adapt-objectives-overview [objectives]="lastQueriedObjectives"
                                           [teamId]="teamId"
                                           [hasEditPermissions]="hasEditPermissions$ | async"></adapt-objectives-overview>
            </ng-container>
            <ng-container *ngSwitchCase="ViewType.ObjectiveListView">
                <adapt-objective-list *ngFor="let teamObjectiveGroup of teamObjectiveGroups"
                                      [currentTeamId]="teamId ? teamId : null"
                                      [objectiveGroup]="teamObjectiveGroup"
                                      (objectiveChange)="onObjectiveChanged()"></adapt-objective-list>
            </ng-container>
            <adapt-objective-tree-view *ngSwitchCase="ViewType.ObjectiveTreeView"
                                       [currentTeamId]="teamId ? teamId : null"
                                       [objectiveGroups]="teamObjectiveGroups"
                                       [applyFilter]="true"></adapt-objective-tree-view>
            <adapt-objectives-grid *ngSwitchCase="ViewType.ObjectiveGridView"
                                   [objectives]="lastQueriedObjectives"
                                   [teamId]="teamId"
                                   [hasEditPermissions]="hasEditPermissions$ | async"></adapt-objectives-grid>
            <div *ngSwitchDefault
                 class="alert alert-danger">
                <span>Unexpected view option!</span>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-template #objectivesGuidance>
    <adapt-call-to-action *ngIf="isDefaultFilter && isInitialised; else noResultsTemplate"
                          [learnMoreUrl]="learnMoreUrl">
        <adapt-call-to-action-title>Objectives</adapt-call-to-action-title>

        <p>
            Figuring out exactly what you have to accomplish is hard for any business.
            You may have a great idea, an inspiring vision, and an impeccable gut
            feeling, but that's not enough.
        </p>
        <p>
            Objectives and Key Results are about that: getting the right tasks done.
            It's about setting and communicating the right goals and understanding
            how to achieve them.
        </p>
        <p>
            Objectives and Key Results (OKRs), on a team and organisation level make up a system that shows
            how everything one person does connects to the work of others.
        </p>

        <button *ngIf="hasEditPermissions$ | async"
                adaptCallToActionButton
                adaptButtonIcon="add"
                (click)="addObjective()">
            Add an objective
        </button>
    </adapt-call-to-action>
</ng-template>
<ng-template #noResultsTemplate>
    <div *ngIf="isInitialised"
         class="row">
        <div class="alert alert-secondary col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <p>
                There are no objectives matching the current filter.
            </p>
            <p>
                Click the 'Reset' button on the filter panel to restore the default objectives filter or try a different filter.
            </p>
        </div>
    </div>
</ng-template>
