import { Component, Input, OnChanges } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatus, ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";

@Component({
    selector: "adapt-select-objective-status",
    templateUrl: "./select-objective-status.component.html",
})
export class SelectObjectiveStatusComponent implements OnChanges {
    public readonly ObjectiveStatus = ObjectiveStatus;

    @Input() public objective!: Objective;
    @Input() public includeClosed = false;
    @Input() public saveAfterChange = true;
    public dataSource: ObjectiveStatus[] = [];
    public dropDownOpened = false;

    public constructor(private commonDataService: CommonDataService) { }

    public onStatusChanged(e: IDxSelectBoxValueChangedEvent<ObjectiveStatus>) {
        // no event will be present if change comes from other source
        // i.e. edit-objective-dialog updates status, which causes the value to change for this select box
        if (e.event) {
            this.objective.status = e.value!;
            if (this.saveAfterChange) {
                this.commonDataService.saveEntities(this.objective)
                    .subscribe();
            }
            // this component will modify the objective status directly - so no need to emit if not saveAfterChang
        }
    }

    public ngOnChanges() {
        this.dataSource = ObjectiveStatusMetadata.All
            .filter(({ status }) => status !== ObjectiveStatus.Closed || this.includeClosed)
            .map(({ status }) => status);
    }
}
