<adapt-dialog [maxWidth]="900">
    <h3 adapt-dialog-title>Start adapt HQ subscription</h3>
    <ng-container adapt-dialog-content>
        <div class="row">
            <div class="col-6">
                <adapt-styled-implementation-kit [articleId]="StartSubscriptionArticle"
                                                 contentStyle="embedded"></adapt-styled-implementation-kit>
            </div>

            <div class="col-6">
                <adapt-select-payment-frequency *ngIf="account"
                                                title="Payment Frequency"
                                                [account]="account"
                                                (selectionChanged)="onSelect($event)"></adapt-select-payment-frequency>
                <h4>Credit card details</h4>
                <ng-container *adaptLoading="cardDetailsLoading">
                    <adapt-update-credit-card-details *ngIf="!cardDetails"
                                                      [updateCreditCardInput]="creditCardInput"
                                                      (cardSet)="cardSetCorrectly = $event"></adapt-update-credit-card-details>

                    <adapt-configure-credit-card *ngIf="cardDetails"
                                                 [(cardDetails)]="cardDetails"
                                                 [organisationId]="creditCardInput.organisationIdentifier.organisationId"
                                                 [eulaToken]="creditCardInput.organisationIdentifier.eulaToken"
                                                 [allowEditing]="true"
                                                 (cardIsSet)="cardSetCorrectly = $event"></adapt-configure-credit-card>
                </ng-container>
            </div>
        </div>

        <div *ngIf="account.extensions.canResumeSubscriptionWithoutCharge()"
             class="alert alert-info mt-3 mb-0">
            Your subscription has already been paid for the period ending <strong>{{account.nextSubscriptionInvoiceDate | adaptDate}}</strong>.
            You will not be charged again until after this date.
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   (cancelClick)="cancel()"
                                   saveButtonPreset="primary"
                                   [saveBlockingClick]="saveAndClose"
                                   [saveIsDisabled]="!cardSetCorrectly || !account.contactName || !account.contactEmail"
                                   [saveText]="account.extensions.isPendingCancellation ? 'Resume subscription!' : 'Start subscription!'"></adapt-standard-dialog-buttons>
</adapt-dialog>
