import { Component, Inject, Injector } from "@angular/core";
import { ObjectiveReview } from "@common/ADAPT.Common.Model/organisation/objective-review";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@PersistableDialog("ReviewObjectiveDialog")
@Component({
    selector: "adapt-review-objective-dialog",
    templateUrl: "./review-objective-dialog.component.html",
})
export class ReviewObjectiveDialogComponent extends BaseDialogWithDiscardConfirmationComponent<ObjectiveReview> {
    public readonly dialogName = "ReviewObjectiveDialog";

    public entitiesToConfirm: ObjectiveReview[];
    public objectiveReviewArticle = this.ImplementationKitArticle.ObjectiveReview;

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public objectiveReview: ObjectiveReview,
    ) {
        super(injector);

        this.entitiesToConfirm = [this.objectiveReview];
        this.autoResolveData = this.objectiveReview;
    }
}
