<div class="d-flex flex-column">
    <ng-container *ngIf="workflowStep?.customData === ObjectiveDescriptionFormationStep.WhatToAchieveAndWhyImportant">
        <h3>What are you trying to achieve?</h3>
        <adapt-html-editor [(contents)]="whatToAchieve"
                           (contentsChange)="updateObjectiveDescription()"
                           forceSize="compact"
                           data-test="what-to-achieve-editor"></adapt-html-editor>
        <h3 class="mt-4">Why is it important and why now?</h3>
        <adapt-html-editor [(contents)]="whyImportantAndWhyNow"
                           (contentsChange)="updateObjectiveDescription()"
                           forceSize="compact"
                           data-test="why-important-editor"></adapt-html-editor>
    </ng-container>
    <ng-container *ngIf="workflowStep?.customData === ObjectiveDescriptionFormationStep.HowWeKnowSuccess">
        <h3>How will we know we have been successful?</h3>
        <adapt-html-editor [(contents)]="howWeKnowSuccess"
                           (contentsChange)="updateObjectiveDescription()"
                           forceSize="compact"
                           data-test="how-we-know-success-editor"></adapt-html-editor>
    </ng-container>
    <ng-container *ngIf="workflowStep?.customData === ObjectiveDescriptionFormationStep.Review">
        <h3>What are you trying to achieve?
            <button adaptButton="editBorderless"
                    class="ms-1"
                    (click)="workflowGoToStep.next(0)"></button>
        </h3>
        <div class="question-review"
             [froalaView]="whatToAchieve ? whatToAchieve : 'Not specified'"></div>
        <h3 class="mt-4">Why is it important and why now?
            <button adaptButton="editBorderless"
                    class="ms-1"
                    (click)="workflowGoToStep.next(0)"></button>
        </h3>
        <div class="question-review"
             [froalaView]="whyImportantAndWhyNow ? whyImportantAndWhyNow : 'Not specified'"></div>
        <h3 class="mt-4">How will we know we have been successful?
            <button adaptButton="editBorderless"
                    class="ms-1"
                    (click)="workflowGoToStep.next(1)"></button>
        </h3>
        <div class="question-review"
             [froalaView]="howWeKnowSuccess ? howWeKnowSuccess : 'Not specified'"></div>
    </ng-container>
</div>
