<adapt-dialog [maxWidth]="600"
              [minWidth]="480">
    <h3 adapt-dialog-title>{{isNew ? "Add new" : "Edit"}} {{surveyTypeLabel.toLowerCase()}} survey</h3>

    <div adapt-dialog-content>
        <div class="form-group">
            <label>Name</label>
            <dx-text-box #focus
                         [adaptValidateEntity]="survey"
                         adaptValidateEntityProperty="name"
                         placeholder="Enter survey name..."
                         [(value)]="survey.name"
                         data-test="survey-name-input"></dx-text-box>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-md-6 mb-3 mb-md-0">
                    <label>Start date and time</label>
                    <dx-date-box type="datetime"
                                 [openOnFieldClick]="true"
                                 [displayFormat]="dateTimeDisplayFormat"
                                 [showAnalogClock]="false"
                                 [min]="minStartTime"
                                 [value]="survey.startTime"
                                 [disabled]="isStarted"
                                 (valueChange)="onStartTimeChanged($any($event))"
                                 data-test="survey-start-date-input"></dx-date-box>
                </div>
                <div class="col-md-6">
                    <label>End date and time</label>
                    <dx-date-box type="datetime"
                                 [openOnFieldClick]="true"
                                 [displayFormat]="dateTimeDisplayFormat"
                                 [showAnalogClock]="false"
                                 [min]="minEndTime"
                                 [value]="survey.endTime"
                                 (valueChange)="onEndTimeChanged($any($event))"
                                 data-test="survey-end-date-input"></dx-date-box>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Survey conducted on behalf of</label>
            <adapt-select-person [(person)]="survey.surveyor"
                                 [userTypes]="allowedBehalfUserTypes"
                                 [required]="true"></adapt-select-person>
        </div>

        <div *ngIf="showNotification"
             class="form-group">
            <label>Custom message</label>
            <div>
                <dx-check-box [(value)]="useCustomMessage"
                              text="Include a custom message in the survey invitation"></dx-check-box>
            </div>
            <div *ngIf="useCustomMessage && survey.supplementaryData"
                 class="mt-1">
                <dx-text-area id="customMessage"
                              name="customMessage"
                              placeholder="Type your custom message here..."
                              [(value)]="survey.supplementaryData.message"
                              [inputAttr]="{ rows: 5 }"
                              [disabled]="!useCustomMessage">
                    <dx-validator>
                        <dxi-validation-rule [adaptEntity]="survey.supplementaryData"
                                             adaptEntityProperty="message"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-area>
            </div>
        </div>

        <div *ngIf="showNotification"
             class="alert alert-primary">
            <p *ngIf="survey.surveyType===SurveyType.ResilientBusinessAssessment">
                <span>Members of the </span>
                <adapt-link-team [team]="leaderTeam$ | async"></adapt-link-team>
                <span> team will be allocated to the leadership group.</span>
            </p>
            <p *ngIf="survey.surveyType===SurveyType.TeamAssessment">
                <span>This survey will assess the </span>
                <adapt-link-team [team]="survey.team"></adapt-link-team>
                <span> team.</span>
            </p>
            <span>An email notification will be sent to each <b>active {{survey.teamId ? "team member" : "employee"}}</b> within an hour
                from now or your specified start date and time</span>
        </div>

        <div class="alert alert-warning"
             *ngIf="!!overlappingSurvey">
            <div>Please change the start and/or end date and time. This is currently overlapping with the following survey:</div>
            <div class="mt-1">
                <span>{{overlappingSurvey!.name}}</span>
                <span class="ms-3">{{overlappingSurvey!.startTime | adaptDate}} &mdash; {{overlappingSurvey!.endTime | adaptDate}}</span>
            </div>
        </div>

        <div class="alert alert-warning"
             *ngIf="isEnded">
            <div>This survey has been ended. Extending the end time and save will re-open it.</div>
        </div>
    </div>

    <div adapt-dialog-footer
         adaptBlockingButtonGroup
         class="d-flex w-100">
        <button *ngIf="showNotification"
                adaptButtonIcon="view"
                [adaptBlockingClick]="previewEmail">
            Preview invitation email
        </button>

        <!-- somehow deleting createdBy using delete key (select-person is setting required to true) will not trigger validation error -->
        <adapt-standard-dialog-buttons [saveText]="isNew ? 'Schedule survey' : 'Save changes'"
                                       [saveIsDisabled]="hasValidationError || !survey.createdById || !!overlappingSurvey || !hasUnsavedEntity"
                                       [saveBlockingClick]="save"
                                       (cancelClick)="cancel()">
        </adapt-standard-dialog-buttons>
    </div>
</adapt-dialog>
