<div class="d-flex flex-column align-items-center my-4">
    <div class="pb-3">
        <adapt-strategic-guidance class="mb-4"
                                  area="purpose"></adapt-strategic-guidance>
    </div>

    <ng-container *ngIf="purpose?.content">
        <div class="mb-4 bt-1">
            <h4>Your purpose statement</h4>
            <div class="mt-2 p-3 purpose-statement"
                 [froalaView]="purpose!.content"></div>
        </div>
    </ng-container>

    <button *adaptIfAuthorised="CompassGuidingPhilosophyEdit"
            class="btn btn-primary px-4"
            [adaptBlockingClick]="recordPurposeStatement"
            role="button">{{!!purpose?.content ? "Update" : "Record"}} purpose statement
    </button>
</div>
