import { Component, ElementRef, Injector } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { UserService } from "@common/user/user.service";
import { ICompletedHealthCheckPersonSurvey, SurveyService } from "@org-common/lib/survey/survey.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { catchError, finalize, forkJoin, switchMap } from "rxjs";
import { IOrganisationDiagnosticStatusCustomData } from "../organisation-diagnostic-status-custom-data";

export const LaunchOrganisationDiagnosticSurveySelector = "adapt-launch-organisation-diagnostic-survey";

@WorkflowStepComponent(LaunchOrganisationDiagnosticSurveySelector)
@Component({
    selector: "adapt-launch-organisation-diagnostic-survey",
    templateUrl: "./launch-organisation-diagnostic-survey.component.html",
})
export class LaunchOrganisationDiagnosticSurveyComponent extends WorkflowStepComponentAdapter {
    public customMessageText = "";
    public startingSurvey = false;
    public otherTeamMembers: Person[] = [];
    private completedHealthCheck?: ICompletedHealthCheckPersonSurvey;

    public constructor(
        elementRef: ElementRef,
        private commonTeamsService: CommonTeamsService,
        private userService: UserService,
        private surveyService: SurveyService,
        private workflowService: WorkflowService,
        private injector: Injector,
    ) {
        super(elementRef);
    }

    // this is called after workflow step is set - in ngOnInit, step is not yet set
    public async workflowStepOnInit() {
        const currentPersonId = this.userService.getCurrentPersonId();
        const team = await this.commonTeamsService.promiseToGetLeadershipTeam();
        this.otherTeamMembers = team.roleConnections
            .filter((i) => i.isActive() && i.connection.personId !== currentPersonId)
            .map((rc) => rc.connection.person);

        this.completedHealthCheck = SurveyService.getCompletedHealthCheckPersonSurvey(this.injector);
    }

    public workflowStepNext() {
        if (!this.workflowConnection) {
            throw new Error("Launch survey workflow step cannot be execute in a stateless workflow - missing workflowConnection!");
        }

        this.workflowStepErrorMessage.next(undefined); // clear error message before attempting
        this.startingSurvey = true;
        const launchSurveyAction = this.completedHealthCheck
            ? this.surveyService.reopenHealthCheckSurvey(this.completedHealthCheck.surveyId, this.customMessageText)
            : this.surveyService.launchOrganisationDiagnostic(this.customMessageText);
        return launchSurveyAction.pipe(
            switchMap((s) => forkJoin([
                this.surveyService.startSurvey(s, this.completedHealthCheck?.personId),
                this.workflowService.getWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(this.workflowConnection!),
            ])),
            switchMap(([s, customData]) => {
                customData.surveyId = s?.surveyId;
                return this.workflowService.updateWorkflowCustomData(this.workflowConnection!, customData);
            }),
            catchError((e) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
                this.workflowStepErrorMessage.next(errorMessage);
                throw e;
            }),
            finalize(() => this.startingSurvey = false),
        );
    }
}
