<div class="d-flex flex-column align-items-center my-4 overflow-hidden">
    <adapt-see-more-popup [minWidth]="500"
                          popupTitle="Strategic themes"
                          buttonText="View strategic themes">
        <ng-template>
            <div class="w-100 d-flex mt-3 align-items-end pb-3 mb-3 border-bottom">
                <adapt-strategic-guidance area="strategic themes"></adapt-strategic-guidance>
                <button *adaptIfAuthorised="EditStrategyBoard"
                        adaptButton="primary"
                        class="ms-4"
                        [adaptBlockingClick]="addTheme">Add theme</button>
            </div>
            <adapt-strategy-board [isEditing]="true"
                                  [views]="mapViews"
                                  [showToolbarMenu]="false"></adapt-strategy-board>
        </ng-template>
    </adapt-see-more-popup>
</div>
