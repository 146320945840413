<div class="mt-4">
    <dx-validation-group #validationGroup>
        <ng-container *ngFor="let person of people; let idx = index"
                      [ngTemplateOutlet]="addPersonRow"
                      [ngTemplateOutletContext]="{$implicit: person, firstRow: idx === 0}"></ng-container>
        <div>
            <span><i class="fal fa-fw fa-user-plus"
                   role="button"
                   (click)="addNewRow()"
                   adaptTooltip="Add another person"></i></span>
        </div>
    </dx-validation-group>
</div>

<ng-template #addPersonRow
             let-person
             let-firstRow="firstRow">
    <div class="row mb-3">
        <div class="col">
            <label *ngIf="firstRow">First name</label>
            <dx-text-box (valueChange)="person.firstName = $event; validateGroup()"
                         data-test="add-people-first-name"
                         placeholder="John">
                <dx-validator>
                    <dxi-validation-rule type="required"
                                         message="Name is required"></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength"
                                         [min]="2"
                                         message="Name must have at least 2 letters"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>
        <div class="col">
            <label *ngIf="firstRow">Last name</label>
            <dx-text-box (valueChange)="person.lastName = $event; validateGroup()"
                         data-test="add-people-last-name"
                         placeholder="Smith">
                <dx-validator>
                    <dxi-validation-rule type="stringLength"
                                         [min]="2"
                                         message="Name must have at least 2 letters"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>

        <div class="col">
            <label *ngIf="firstRow">Email</label>
            <dx-text-box (valueChange)="person.email = $event; validateGroup()"
                         data-test="add-people-email"
                         placeholder="j.smith@example.com"
                         mode="email">
                <dx-validator>
                    <dxi-validation-rule type="required"
                                         message="Email is required"></dxi-validation-rule>
                    <dxi-validation-rule type="email"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>

        <div class="col">
            <adapt-select-role-in-organisation [(value)]="person.roleType"
                                               (valueChange)="validateGroup()"
                                               data-test="add-people-role-in-org"
                                               [withLabel]="firstRow"></adapt-select-role-in-organisation>
        </div>

        <div class="col-1 align-self-center">
            <ng-container *ngIf="!firstRow">
                <button class="pl-3"
                        adaptButton="deleteBorderless"
                        role="button"
                        (click)="removeLastRow()"
                        adaptTooltip="Remove person"></button>
            </ng-container>
        </div>
    </div>
</ng-template>
