<div class="sidebar-container">
    <button class="sidebar-toggle"
            data-tour="sidebar-toggle"
            *ngIf="!isMobileSize && isAllowedToCollapse"
            (click)="toggleSidebarOpenClosed()">
        <i [ngClass]="toggleIconClass"></i>
        <span class="visually-hidden">Slide Sidebar In/Out</span>
    </button>

    <div *ngIf="!isMobileSize && (tabs.length > 1 || hasPopover)"
         class="vertical-tabs">
        <div class="vertical-tabs vertical-tabs-top">
            <ng-container *ngFor="let tab of tabsTop"
                          [ngTemplateOutlet]="tabTemplate"
                          [ngTemplateOutletContext]="{$implicit: tab}"></ng-container>
        </div>

        <div class="vertical-tabs vertical-tabs-bottom mt-auto">
            <ng-container *ngFor="let tab of tabsBottom"
                          [ngTemplateOutlet]="tabTemplate"
                          [ngTemplateOutletContext]="{$implicit: tab}"></ng-container>

            <adapt-shell-popover-link-item *ngFor="let popover of popovers$ | async"
                                           class="tab"
                                           [item]="popover"
                                           [isInternal]="popover.isInternal"
                                           popoverLocation="right"></adapt-shell-popover-link-item>
        </div>
    </div>

    <div class="horizontal-tabs"
         *ngIf="isMobileSize">
        <ng-container *ngFor="let tab of tabs"
                      [ngTemplateOutlet]="tabTemplate"
                      [ngTemplateOutletContext]="{$implicit: tab}"></ng-container>
        <ng-container *ngFor="let popover of popovers$ | async; let first = first;">
            <adapt-shell-popover-link-item [ngClass]="{'first-popover': first}"
                                           [item]="popover"
                                           [isInternal]="popover.isInternal"
                                           popoverLocation="bottom"></adapt-shell-popover-link-item>

        </ng-container>
        <button class="mobile-sidebar-toggle"
                (click)="toggleSidebarOpenClosed()">
            <i class="fal fa-times"></i>
        </button>
    </div>

    <div class="tab-content"
         #tabContent>
        <adapt-loading-spinner *ngIf="tabIsLoading"
                               class="h-100"></adapt-loading-spinner>

        <dx-scroll-view [hidden]="tabIsLoading"
                        direction="vertical"
                        showScrollbar="onHover"
                        [useNative]="false">
            <adapt-render-component class="scroll-view-content"
                                    [hidden]="tabIsLoading"
                                    [component]="activeTab?.content?.component"
                                    [componentInputs]="activeTab?.content?.params"
                                    [eagerRenderComponents]="tabContentComponents">
            </adapt-render-component>
        </dx-scroll-view>
    </div>

    <button class="d-md-none logout-button"
            (click)="promiseToLogout()">
        <i class="fal fa-sign-out"></i>
        Logout
    </button>
</div>

<ng-template #tabTemplate
             let-tab>
    <adapt-render-component class="tab"
                            [id]="tab.id"
                            [adaptTooltip]="tab.label"
                            tooltipPlacement="right"
                            [hidden]="!activeTab || !(tab.isDisplayed$ | async)"
                            [ngClass]="{'active': tab === activeTab || (tab.isActive$ && tab.isActive$ | async), 'has-text': !!tab?.icon?.params?.iconText }"
                            [component]="tab.icon.component"
                            [componentInputs]="tab.icon.params"
                            (click)="tabClick(tab)">
    </adapt-render-component>
</ng-template>
