import { Component, Inject, Input } from "@angular/core";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ORGANISATION_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import moment from "moment";
import { lastValueFrom, Observable, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-configure-team-details-new",
    templateUrl: "./configure-team-details.component.html",
})
export class ConfigureTeamDetailsComponent extends ConfigureFeatureBase {
    @Input() public team!: Team;
    @Input() public configItem!: IConfigItem;

    public maxStartDate = moment().startOf("day").toDate();
    public originallyActive = true;
    public isLeaderTeam$?: Observable<boolean>;

    public changedLabelLocationEntities: LabelLocation[] = [];

    public constructor(
        configurationService: ConfigurationService,
        protected commonDataService: CommonDataService,
        private teamsService: CommonTeamsService,
        private commonDialogService: AdaptCommonDialogService,
        private surveyService: SurveyService,
        @Inject(ORGANISATION_DASHBOARD_PAGE) private organisationDashboardPageRoute: IAdaptRoute<{}>,
    ) {
        super(configurationService);
    }

    public get isActive() {
        return this.team && this.team.isActive();
    }

    public changesAreValid() {
        return this.team && !(this.team as IBreezeEntity).entityAspect.hasValidationErrors;
    }

    @Autobind
    public async initialiseData() {
        this.originallyActive = this.isActive;
        this.isLeaderTeam$ = this.surveyService.getResilientBusinessSurveyConfigurations().pipe(
            map((configs) => configs.some((c) => c.leaderTeamId === this.team.teamId)),
        );
    }

    public async onSave() {
        await this.teamsService.promiseToUpdateTeamName(this.team);
        this.changedLabelLocationEntities = []; // this is called after entities save -> can clear the collection
        return super.onSave();
    }

    public deactivateTeam() {
        const dialogData: IConfirmationDialogData = {
            title: "Deactivate team",
            message: "This preserves all data for this team in the organisation, but the team will not be accessible. All boards will be archived.",
            checkboxMessage: "Confirm that you wish to deactivate this team",
            confirmButtonText: "Deactivate & save",
        };

        this.commonDialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
            filter((dialogResult) => !!dialogResult),
            // reject all unsaved label location changes before deactivating
            switchMap(() => this.changedLabelLocationEntities.length
                ? this.commonDataService.rejectChanges(this.changedLabelLocationEntities)
                : of(undefined)),
            switchMap(async () => { // 'tap' will still emit if save fail - need switchMap to capture failed save
                this.team.endDate = moment.utc().toDate();
                await this.teamsService.promiseToEndTeam(this.team);
                return lastValueFrom(this.commonDataService.save());
            }),
            switchMap(() => this.commonDialogService.showMessageDialog("Deactivate team", "The team has been successfully deactivated.")),
            switchMap(() => this.organisationDashboardPageRoute.gotoRoute()),
            catchError((err) => this.commonDialogService.showMessageDialog("Failed to End Team", err)),
        ).subscribe();
    }

    public deleteTeam() {
        const dialogData: IConfirmationDialogData = {
            title: "Delete team",
            message: "This is a destructive operation and all data associated with the team will be deleted. All guidance, actions, objectives, surveys, Peer Catch-Ups will be deleted.",
            checkboxMessage: "Confirm that you wish to delete this team",
            confirmButtonText: "Delete & save",
        };

        this.commonDialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
            filter((dialogResult) => !!dialogResult),
            // reject all unsaved label location changes before deactivating
            switchMap(() => this.changedLabelLocationEntities.length
                ? this.commonDataService.rejectChanges(this.changedLabelLocationEntities)
                : of(undefined)),
            switchMap(() => this.teamsService.promiseToRemoveTeam(this.team)),
            switchMap(() => this.commonDataService.save()),
            switchMap(() => this.commonDialogService.showMessageDialog("Delete team", "The team has been successfully deleted.")),
            switchMap(() => this.organisationDashboardPageRoute.gotoRoute()),
            catchError((err) => this.commonDialogService.showMessageDialog("Failed to delete team", err)),
        ).subscribe();
    }

    public onPrivateChanged(isPrivate: boolean) {
        this.team.isPrivate = isPrivate;
        if (!isPrivate) {
            const confirmationDialogData: IConfirmationDialogData = {
                title: "Changing private team to public",
                message: "<p>You are about to change the private team to public, which will result in the team data such as " +
                    "meetings, actions, objectives, etc. no longer strictly restricted to team members. Leaders with appropriate permissions will be " +
                    "able to access the team regardless of other privacy settings if this is no longer a private team.</p>" +
                    "<p>Are you sure you want to continue?</p>",
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                checkboxMessage: "Confirm that you under this action will result in exposure of private team data",
            };
            this.commonDialogService.openConfirmationDialogWithBoolean(confirmationDialogData).subscribe((confirm) => {
                if (!confirm) {
                    this.team.isPrivate = true;
                }
            });
        }
    }
}
