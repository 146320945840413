// base environment shared by all apps
// each app specifies its own environment.base.ts that is based off this file

// AltoAzureTablesUrl, EmbedAzureTablesUrl:
//  values are also stored in Web.config and StorageProvider.cs so update there also if changing here.

import { RawClientConfiguration } from "./adapt-client-configuration";

export const clientConfiguration: Partial<RawClientConfiguration> = {
    AdaptEnvironmentName: "Local",
    AltoApiBaseUri: "http://localhost:4300",
    AltoAzureTablesUrl: "https://adaptdev2.table.core.windows.net/",
    AltoBaseUri: "http://localhost:4305",
    AnalyticsProductId: "",
    ApiBaseUri: "/api",
    BoxClientId: "l4ljnh88sav6vg083t1nx6seu1vdpwnr",
    DeepDiveDomain: "https://adaptdeepdivestaging.z26.web.core.windows.net",
    DropboxAppKey: "kqq9nh24c7i0xp1",
    EmbedApiBaseUri: "/api",
    EmbedAzureTablesUrl: "https://adaptdev2.table.core.windows.net",
    EmbedBaseUri: "http://localhost:4301",
    FroalaKey: "Lc2C1qE1C1A3D3D3B14B7iAd1Te1YZNYAc1CUKUEQOHFVANUqB2C1E7C6E1E5E4A1B3C10==",
    GoogleApiKey: "AIzaSyDkD9k7-4i56CZfwZRUSY0chEYPURr5Kvw",
    GoogleClientId: "799098505879-p5uciuk6kges86pnnhil2rg84d31ee8e.apps.googleusercontent.com",
    IntercomAppId: "q32mupku",
    MicrosoftClientId: "4bdd55c9-a959-42c8-b866-174d1b46dcf3",
    OneDriveClientId: "8f51d602-b047-427a-bcdc-f802ff020035",
    SentryTraceLevelName: "Error",
    ServiceApiBaseUri: "http://localhost:4303",
    StripePublicKey: "pk_test_1FhAZMakNbB63zYwUjAgFfiv",
    ToasterTraceLevelName: "Warning",
    TraceLevelName: "Debug",
    TrustedVimeoDomain: "http://player.vimeo.com/video/*",
};
