import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Injector, Type } from "@angular/core";
import { IBreezeService } from "@common/lib/data/breeze-service.interface";
import { Logger } from "@common/lib/logger/logger";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { TimerWorker } from "@common/lib/timer-worker/timer-worker";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { RouteService } from "@common/route/route.service";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { ICommonDialogService } from "@common/ux/adapt-common-dialog/common-dialog-service.interface";
import { IDialogEventHandler } from "@common/ux/adapt-common-dialog/dialog-event-handler.interface";
import * as Sentry from "@sentry/browser";
import { Entity, EntityAction, EntityChangedEventArgs, EntityManager, EntityState } from "breeze-client";
import { interval, Subject, Subscription, timer } from "rxjs";
import { debounceTime, filter, tap } from "rxjs/operators";
import { SentrySeverity } from "../logger/sentry-log-provider";
import { ArrayUtilities } from "../utilities/array-utilities";
import { IBreezeEntity } from "./breeze-entity.interface";
import { IBreezeModel } from "./breeze-model.interface";
import { EntityPersistentBreezeHelper, IBreezeReplacementObject, IImportManager } from "./entity-persistent-breeze-helper";
import { EntityPersistentDialogUtility } from "./entity-persistent-dialog-utility";
import { isDisabledEntityPersistentDialog } from "./persistable-dialog.decorator";

const constants = {
    SESSION_PREFIX: "embedADAPTsession",
    UNSAVED_ENTITIES_PREFIX: "adapt.unsaved.entities.",
    LOAD_MUTEX_KEY: "embedADAPTloadEntitiesMutex",
    WATCHDOG_TIMER_FEEDING_INTERVAL: 3000,
    WATCHDOG_TIMEOUT: 5000,
    WATCHDOG_CLEANUP_INTERVAL: 60000,
    LOAD_MUTEX_MAX_TIME: 1000,
    CONTINUE: "Continue",
    DISCARD: "Discard",
    CANCEL: "Cancel",
};

interface IPersistDataType {
    unsavedEntities: object;
    timestamp: string;
    types: string[];
    url: string;
    sessionId: string;
    ngDialogs: any[];
    adoptedBy?: string;
}

interface IPersistingBreezeEntity extends IBreezeEntity {
    hasPropertyChanged?: boolean; // flag to indicate the entity has property changed - not caused by adding other entities
    isRestoringPreviousValue?: boolean; // flag to indicate the entity is being restored
}

@Injectable({
    providedIn: "root",
})
export class EntityPersistentService implements IDialogEventHandler {
    private unsavedEntities: IBreezeEntity[] = [];
    private registeredTypes: string[] = [];
    private pendingLoadTypes: string[] = [];
    private personId = -1;
    private organisationId = -1;
    private lastLoadedOrganisationId = -1;

    private breezeHelper?: EntityPersistentBreezeHelper;
    private importManager?: IImportManager;
    private dialogUtility?: EntityPersistentDialogUtility;
    private entityManagerSubscriptionHandle?: number;
    private factoryId: string;
    private stopWatchdogFeederWorker?: () => void;

    private _getOrganisationIdFunction?: () => number;
    private _getCommonDialogServiceFunction?: () => ICommonDialogService;

    private watchdogCleanupTimer = interval(constants.WATCHDOG_TIMER_FEEDING_INTERVAL * 10);
    private watchdogCleanupTimerSubscription?: Subscription;
    private logger = Logger.getLogger("AdaptEntityPersistentService");
    private restoringDialogs = false;
    private previouslyAddedEntityImported = new Subject<EntityChangedEventArgs>();

    private entityPersistentDisabled = false;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private routeService: RouteService,
        private injector: Injector,
    ) {
        this.factoryId = constants.SESSION_PREFIX + Date.now();
    }

    public set getOrganisationIdFunction(fn: (() => number) | undefined) {
        this._getOrganisationIdFunction = fn;
    }

    public get getOrganisationIdFunction() {
        return this._getOrganisationIdFunction;
    }

    public set getCommonDialogServiceFunction(fn: (() => ICommonDialogService) | undefined) {
        this._getCommonDialogServiceFunction = fn;
    }

    public get getCommonDialogServiceFunction() {
        return this._getCommonDialogServiceFunction;
    }

    public set currentPersonId(personId: number) {
        this.personId = personId;
    }

    public get hasUnsavedEntities() {
        return this.unsavedEntities.length > 0;
    }

    public isUnsavedEntity(checkEntity: IBreezeEntity) {
        return this.unsavedEntities.includes(checkEntity);
    }

    public get entityManager() {
        if (this.breezeHelper) {
            return this.breezeHelper.entityManager;
        } else {
            return undefined;
        }
    }

    public set entityManager(manager: EntityManager | undefined) {
        if (!LocalStorage.isAvailable || !this.breezeHelper) {
            // not subscribing if local storage not supported - no entity change intercepted
            // or if the service is not initialized by BreezeService
            return;
        }

        // setting a new entity manager
        // - if there were previous subscription and previous entityManager - unsubscribe
        if (this.entityManagerSubscriptionHandle && this.entityManager) {
            this.entityManager.entityChanged.unsubscribe(this.entityManagerSubscriptionHandle);
            this.entityManagerSubscriptionHandle = undefined;
        }

        this.breezeHelper.entityManager = manager;

        if (manager && manager.entityChanged && manager.entityChanged.subscribe) {
            this.entityManagerSubscriptionHandle = manager.entityChanged.subscribe((e: EntityChangedEventArgs) => {
                // Add additional wrapping as Breeze suppresses the error which doesn't allow it to propagate.
                try {
                    this.entityChanged(e);
                } catch (err: any) {
                    this.logger.error(err);
                }
            });
        }
    }

    public entityTypeImported(entityShortName: string) {
        return this.previouslyAddedEntityImported.asObservable().pipe(
            filter((args) => {
                return this.breezeHelper!.getEntityShortName(args.entity!) === entityShortName;
            }),
            // time here does not really applied, after entity imported, subject will be completed. So debounce here
            // will flow through after import finishes
            debounceTime(1000),
        );
    }

    public load() {
        if (this.getOrganisationIdFunction) {
            this.organisationId = this.getOrganisationIdFunction();
        } else {
            this.organisationId = -1;
            this.logger.warn("getOrganisationId function is not set (from BreezeService) before initialisation. Organisation id of -1 will be used");
        }

        if (this.lastLoadedOrganisationId === this.organisationId) {
            // only need to check once on sidebar loaded after switching organisation or logged in
            return;
        }

        if (!LocalStorage.isAvailable) {
            // not loading if no local storage supported
            return;
        }

        this.lastLoadedOrganisationId = this.organisationId;

        // don't have to feed anymore if we switch organisation
        // - if there are changed entity in this organisation, it will be started again
        this.stopWatchdogFeederAndCleaner();

        // don't block the caller - allow watchdog to be fed from another session
        // timer emit after 1 second then complete - no need to unsubscribe here
        timer(constants.WATCHDOG_TIMER_FEEDING_INTERVAL).subscribe(() => this.loadEntities());
    }

    private stopWatchdogFeederAndCleaner() {
        if (this.watchdogCleanupTimerSubscription) {
            this.watchdogCleanupTimerSubscription.unsubscribe();
            this.watchdogCleanupTimerSubscription = undefined;
        }

        if (this.stopWatchdogFeederWorker) {
            this.stopWatchdogFeederWorker();
            this.stopWatchdogFeederWorker = undefined;
        }

        LocalStorage.delete(this.factoryId);
    }

    private startWatchdogFeederAndCleaner() {
        if (!this.watchdogCleanupTimerSubscription) {
            this.watchdogCleanupTimerSubscription = this.watchdogCleanupTimer.subscribe(() => this.cleanupSessionWatchdog());
        }

        if (!this.stopWatchdogFeederWorker) {
            this.stopWatchdogFeederWorker = new TimerWorker().setInterval(() => this.feedSessionWatchdog(), constants.WATCHDOG_TIMER_FEEDING_INTERVAL);
        }
    }

    private cleanupSessionWatchdog() {
        const now = Date.now();

        // cleanup watchdog that's not fed for over a minute
        for (const key of LocalStorage.adaptKeys) {
            if (key.indexOf(constants.SESSION_PREFIX) === 0 && key !== this.factoryId) {
                const keyValue = LocalStorage.get<number>(key)!;
                if (now - keyValue > constants.WATCHDOG_CLEANUP_INTERVAL) {
                    // last fed more than a minute ago -> can remove
                    LocalStorage.delete(key);
                }
            }
        }
    }

    private feedSessionWatchdog() {
        LocalStorage.set(this.factoryId, Date.now());
    }

    private logToSentry(message: string) {
        Sentry.captureMessage(message, SentrySeverity.Info);
    }

    public dialogOpened(dialog: BaseDialogComponent<unknown>, data?: unknown) {
        if (isDisabledEntityPersistentDialog(dialog.constructor as Type<unknown>)) {
            this.logger.log("Entity persistent will be disabled with this dialog opened: " + dialog.dialogName);
            this.entityPersistentDisabled = true;
            return;
        }

        if (this.dialogUtility) {
            this.dialogUtility.dialogOpened(dialog.constructor as Type<unknown>, data);
            if (this.restoringDialogs) {
                this.persistDialogs();
            }
        }
    }

    public dialogResolved(dialog: BaseDialogComponent<unknown>) {
        this.dialogCancelled(dialog);
    }

    public dialogCancelled(dialog: BaseDialogComponent<unknown>) {
        if (isDisabledEntityPersistentDialog(dialog.constructor as Type<unknown>)) {
            this.logger.log("Entity persistent will be re-enabled with this dialog closed: " + dialog.dialogName);
            this.entityPersistentDisabled = false;
            return;
        }

        if (this.dialogUtility) {
            this.dialogUtility.dialogClosed(dialog.constructor as Type<unknown>);
            this.persistDialogs();
        }
    }

    private persistDialogs() {
        // only persist dialogs if there is any unsaved entities
        // - not going to save entities again as that is already persisted in persistEntities() when then entities are changed
        if (this.unsavedEntities.length > 0) {
            const storageKey = this.getStorageKey();

            if (!storageKey || !this.entityManager || !this.dialogUtility) {
                return;
            }

            const persistData = LocalStorage.get<any>(storageKey) as IPersistDataType;
            if (persistData) {
                persistData.ngDialogs = this.dialogUtility.serialiseOpenedDialogs();
                LocalStorage.set(storageKey, persistData);
            }
        }
    }

    public modelRegistered(toType: string, model: IBreezeModel) {
        if (!LocalStorage.isAvailable || !this.breezeHelper) { // not doing anything without local storage or breeze helper
            return;
        }

        this.registeredTypes.push(toType);
        this.breezeHelper.modelRegistered(toType, model);

        if (this.pendingLoadTypes.includes(toType)) {
            this.loadEntities();
        }
    }

    public isModelRegistered(toType: string) {
        return this.registeredTypes.includes(toType);
    }

    public entityRemoved(entity: IBreezeEntity) {
        this.removeEntity(entity, true);
        this.logger.log(`Entity (${this.breezeHelper!.getEntityShortName(entity)}) removed - remaining unsavedEntities count: ${this.unsavedEntities.length}`);
    }

    public entitiesSaved(entities: IBreezeEntity[]) {
        if (Array.isArray(entities)) {
            entities.forEach((entity) => this.removeEntity(entity, false));
            this.persistEntities();

            this.logger.log("Removed saved entities - remaining unsavedEntities count: " + this.unsavedEntities.length);
        }
    }

    public initialise() {
        this.lastLoadedOrganisationId = -1;
    }

    public set breezeService(service: IBreezeService | undefined) {
        if (service) {
            this.breezeHelper = new EntityPersistentBreezeHelper(service);
            this.dialogUtility = new EntityPersistentDialogUtility(this.breezeHelper, this.injector);
        }
    }

    public clear() {
        this.unsavedEntities = [];
        this.persistEntities();

        if (this.importManager) {
            this.importManager.entities = [];
        }

        this.logger.log("All unsaved entities cleared. Remaining count: " + this.unsavedEntities.length);
    }

    private loadEntities() {
        const self = this;
        // not going to load if tab is hidden
        if (this.document.hidden) {
            timer(1000).subscribe(() => this.loadEntities()); // try again later
            return;
        }

        if (this.hasUnsavedEntities) {
            // not doing anything if there are already changed breeze entities - log this so we can get some stats
            this.logger.log("Someone managed to change persistable breeze entities before unsaved entities are checked. Skip loading unsaved entities.");
            this.previouslyAddedEntityImported.complete();
            return;
        }

        const loadMutexTime = LocalStorage.get<number>(constants.LOAD_MUTEX_KEY)!;

        if (loadMutexTime && (Date.now() - loadMutexTime) < constants.LOAD_MUTEX_MAX_TIME) {
            timer(constants.LOAD_MUTEX_MAX_TIME).subscribe(() => this.loadEntities());
            return;
        }

        LocalStorage.set(constants.LOAD_MUTEX_KEY, Date.now()); // record timestamp when start loading
        const persistData = this.getLatestStoredDataWithUnfedWatchdog();
        LocalStorage.delete(constants.LOAD_MUTEX_KEY);

        if (!persistData) {
            return;
        }

        const entityTypes = persistData.types;
        const redirectUrl = persistData.url;

        if (Array.isArray(entityTypes)) {
            // have to make sure that all model registered before loading or registerModel with ctor will fail)
            if (entityTypes.every((type) => this.isModelRegistered(type))) {
                this.logger.log("loading previous exported data of types: ", entityTypes);
                this.pendingLoadTypes = [];
                loadExportData(persistData);
            } else {
                this.pendingLoadTypes = entityTypes;
            }
        } else {
            this.pendingLoadTypes = [];
        }

        function loadExportData(loadedData: IPersistDataType) {
            try {
                // only need ajs dialog service if restoring - can't restore without dialog
                if (!self.getCommonDialogServiceFunction) {
                    throw new Error("Cannot load previously exported data without the dialog services");
                }

                self.dialogUtility!.commonDialogService = self.getCommonDialogServiceFunction();
                const emptyEntityManager = self.entityManager!.createEmptyCopy();

                // importManager is just an object with entities as documented in
                // http://breeze.github.io/doc-js/api-docs/files/a50_entityManager.js.html#l416
                self.logger.log("debug unsaved entities: ", loadedData.unsavedEntities);
                self.importManager = emptyEntityManager.importEntities(loadedData.unsavedEntities) as IImportManager;
                self.importManager.manager = emptyEntityManager;

                if (Array.isArray(self.importManager.entities)) {
                    self.dialogUtility?.commonDialogService.closeAll();
                    self.dialogUtility!.promptToDiscardUnsavedEntities(self.importManager.entities, entityTypes)
                        .subscribe({
                            next: (discard) => discard ? discardingCheckNext() : restoreData(),
                            complete: cleanupLoadedData,
                        });
                }
            } catch (error) {
                cleanupLoadedData(); // remove unsaved entities from localStorage
                discardingCheckNext(); // remove timers and move onto next unclaimed changeset
                throw error;
            }

            function discardingCheckNext() {
                self.stopWatchdogFeederAndCleaner(); // discarding - no unsaved changes - no feeder required
                setTimeout(() => self.loadEntities()); // see if there are more unsaved entities to be loaded - does nothing if all changesets are claimed
            }

            // cleanup regardless of which choice - if restore, a new copy will be saved under this current factoryId
            function cleanupLoadedData() {
                LocalStorage.delete(self.getStorageKeyPrefix() + loadedData.sessionId);
            }

            async function restoreData() {
                if (redirectUrl !== self.routeService.currentUrl) {
                    self.logger.log("redirecting to the last saved location: " + redirectUrl);
                    await self.routeService.navigateByUrl(redirectUrl, { restoreData: true });
                    timer(1000).subscribe(restoreExportDataOnLocationChange);
                } else {
                    restoreExportDataOnLocationChange();
                }

                function restoreExportDataOnLocationChange() {
                    primeEntities()
                        .then(promiseToCheckForLoadedEntitiesChanged)
                        .then(updateEntities)
                        .then(validateEntities)
                        .then(() => self.persistEntities()) // store entities from this factoryId and start feeding the watchdog
                        .then(restoreDialogs)
                        .catch(() => self.logger.log("Unsaved entities discarded"))
                        .finally(() => self.previouslyAddedEntityImported.complete());

                    function primeEntities() {
                        if (self.importManager && Array.isArray(self.importManager.entities)) {
                            const restoreEntities: (IBreezeEntity | IBreezeReplacementObject)[] = self.importManager.entities.map((entity) => entity as IBreezeEntity);
                            const promises = restoreEntities
                                .map((entity) => self.breezeHelper!.promiseToPrimeEntity(entity) as PromiseLike<any>);

                            return Promise.all(promises);
                        } else {
                            return Promise.resolve([]);
                        }
                    }

                    // This will check if the entities have been changed since it was last persisted
                    // - if so, prompt user to determine if the changes should be discarded
                    function promiseToCheckForLoadedEntitiesChanged() {
                        if (self.importManager && self.importManager.entities.some(isChangedFromServer)) {
                            // prompt to overwrite or discard
                            return new Promise((resolve, reject) => {
                                self.dialogUtility!.promptToDiscardWithServerChanges().pipe(
                                    tap((discard) => self.logToSentry("Conflicts detected during restoration. " +
                                        (discard ? "Discard" : "Continue") + "unsaved entities restore.")),
                                ).subscribe(
                                    (discard) => discard ? reject() : resolve(undefined));
                            });
                        } else {
                            return Promise.resolve();
                        }

                        function isChangedFromServer(importEntity: Entity) {
                            let isChanged = false;
                            const cacheEntity = getMatchingEntityFromEntityManager(importEntity as IBreezeEntity);

                            if (cacheEntity) {
                                const keys = Object.keys(importEntity.entityAspect.originalValues);
                                for (const key of keys) {
                                    const value = (importEntity.entityAspect.originalValues as any)[key];
                                    verifyCacheEntity(value, key);
                                }
                            }

                            return isChanged;

                            function verifyCacheEntity(originalValue: any, key: string) {
                                if (extractCacheValue(key) !== originalValue) {
                                    self.logger.log("Conflicts: unsaved "
                                        + self.breezeHelper!.getEntityShortName(importEntity)
                                        + " entity original[" + key + "]: "
                                        + originalValue
                                        + " != "
                                        + cacheEntity![key],
                                    );
                                    isChanged = true;
                                }
                            }

                            function extractCacheValue(key: string) {
                                return self.breezeHelper!.extractEntityValue(cacheEntity!, key);
                            }
                        }
                    }

                    function getMatchingEntityFromEntityManager(entity: IBreezeEntity) {
                        const id = self.breezeHelper!.getEntityId(entity);
                        const entityShortName = self.breezeHelper!.getEntityShortName(entity);

                        if (id && self.entityManager && entityShortName) {
                            return self.entityManager.getEntityByKey(entityShortName, id) as IBreezeEntity;
                        } else {
                            return undefined;
                        }
                    }

                    function validateEntities() {
                        self.unsavedEntities.forEach(validate);

                        function validate(unsavedEntity: IBreezeEntity) {
                            unsavedEntity.entityAspect.validateEntity();
                        }
                    }

                    function updateEntities() {
                        // take a shallow copy of the array first as importManager.entities will be spliced after the changes are applied to the
                        // cached entities
                        const importedEntities = self.importManager!.entities.slice();

                        importedEntities.forEach(applyChangesToExistingCache);
                        self.logger.log("Loaded entities and applied to already cached entities. Import manager entities left: " + self.importManager!.entities.length);

                        // recreate all entities with id < 0 from importManager.entities
                        if (self.importManager!.entities.length > 0) {
                            const newEntities = self.importManager!.entities.filter(isNewEntity);

                            if (newEntities.length > 0) {
                                // This is supposed to be right way of importing entities according to
                                // https://stackoverflow.com/questions/23914060/what-is-the-right-way-to-append-entity-from-one-manager-to-another-in-breeze
                                // The original poster had the same issue as our Location where auto ID overlaps with imported entities.
                                // This recommended way still does not resolve the issue (still getting clashing locationId when creating
                                // detached location after restoring) - but this is recommended.
                                const importResult = self.entityManager!.importEntities(self.importManager!.manager!.exportEntities(newEntities, false));

                                if (Array.isArray(importResult.entities) && importResult.entities.length > 0) {
                                    // new entities need to go to unsavedEntities immediately they are imported as importEntities won't trigger propertyChange
                                    // event; otherwise, you would lose them if you refresh and haven't made any changes and trigger a save.
                                    // They are imported and won't have any duplicates (otherwise MergeStrategy error will be thrown) - so no need to check.
                                    self.unsavedEntities = self.unsavedEntities.concat(importResult.entities as IBreezeEntity[]);
                                }

                                // remove from importManager
                                for (const newEntity of newEntities) {
                                    const deleteIndex = self.importManager!.entities.indexOf(newEntity);

                                    if (deleteIndex >= 0) {
                                        self.importManager!.entities.splice(deleteIndex, 1);
                                    }
                                }
                            }

                            if (self.importManager!.entities.length > 0) {
                                const failedEntitiesString = self.importManager!.entities.map(getEntityIdentifier).join(",");

                                self.logger.error("The followings entities cannot be restored, resulting in all previously unsaved entities to be discarded: ["
                                    + failedEntitiesString + "]");
                                self.clear();

                                // after showing the dialog, this will be a rejected promise and skip the subsequence steps in the promise chain
                                return new Promise((_accept, reject) =>
                                    self.dialogUtility!.showFailedImportedEntities(failedEntitiesString).subscribe(() => reject()));
                            } else {
                                self.logger.log("After recreating unsaved entities, import manager entities left: " + self.importManager!.entities.length);
                            }
                        }

                        return Promise.resolve();

                        function getEntityIdentifier(entity: IBreezeEntity) {
                            const modelName = self.breezeHelper!.getEntityShortName(entity);
                            if (modelName) {
                                return self.breezeHelper!.models[modelName].singularName + ":" + self.breezeHelper!.getEntityId(entity);
                            } else {
                                return "undefined";
                            }
                        }

                        // all entities should already be in the entity manager cache by the time this function is called
                        function applyChangesToExistingCache(changedEntity: IBreezeEntity) {
                            const loadedEntity = getMatchingEntityFromEntityManager(changedEntity);

                            if (loadedEntity) {
                                restoreUnsavedEntity(loadedEntity, changedEntity);
                            }

                            function restoreUnsavedEntity(destEntity: IPersistingBreezeEntity, unsavedEntity: IBreezeEntity) {
                                destEntity.isRestoringPreviousValue = true; // this is to prevent saving entities that we are restoring
                                copyNonKeyDataProperty(destEntity, unsavedEntity);
                                delete destEntity.isRestoringPreviousValue;

                                const deleteIndex = self.importManager!.entities.indexOf(unsavedEntity);

                                if (deleteIndex >= 0) {
                                    self.importManager!.entities.splice(deleteIndex, 1);
                                    self.logger.log("Found previously unsaved entity copied to attached entity: ", destEntity);
                                    self.logger.log("importManager size changed to: " + self.importManager!.entities.length);
                                }
                            }

                            function copyNonKeyDataProperty(destEntity: IBreezeEntity, sourceEntity: IBreezeEntity) {
                                sourceEntity.entityType.dataProperties.forEach((dataProperty) => {
                                    if (!dataProperty.isPartOfKey) {
                                        destEntity[dataProperty.name] = sourceEntity[dataProperty.name];
                                    }
                                });
                            }
                        }

                        function isNewEntity(entity: IBreezeEntity) {
                            // SupplementaryData type entities typically use the related entities ID as their primary key
                            // so they won't be < 0 if the related entity has already been saved...
                            return self.breezeHelper!.getEntityId(entity) < 0
                                || entity.entityAspect.entityState.isAdded();
                        }
                    }

                    function restoreDialogs() {
                        self.restoringDialogs = true;
                        self.dialogUtility!.restoreOpenedDialogs(loadedData.ngDialogs, self.unsavedEntities, self.importManager!);
                        self.restoringDialogs = false;
                    }
                }
            }
        }
    }

    private getLatestStoredDataWithUnfedWatchdog() {
        const self = this;
        const storageKeyPrefix = this.getStorageKeyPrefix();
        let latestStoredData: IPersistDataType | undefined;
        let latestKey: string | undefined;
        let sessionCount = 0; // this is for logging to sentry to give us some ideas of editing entities from multiple tabs

        if (storageKeyPrefix && this.entityManager) {
            for (const key of LocalStorage.adaptKeys) {
                if (key.indexOf(storageKeyPrefix) === 0) {
                    getLatestStorageKey(key);
                    sessionCount++;
                }
            }
        }

        if (latestStoredData && latestKey) {
            this.feedSessionWatchdog();
            latestStoredData.adoptedBy = this.factoryId;
            // claimed by this factory instance so another instance won't claim it again
            LocalStorage.set(latestKey, latestStoredData);
            // have to start feeding the watchdog to hold on to the adopter claim
            this.startWatchdogFeederAndCleaner();
            self.logger.log(this.factoryId + " adopted unsaved entities from " + latestKey);
        } else {
            self.previouslyAddedEntityImported.complete();
            self.logger.log("No unsaved entity available for " + this.factoryId);
        }

        // log if there are multiple sessions for the same user+org
        if (sessionCount > 1) {
            // these logs (with the 2 above) will be saved to sentry with the logToSentry that follows
            self.logger.log("Number of sessions with unsaved entities: " + sessionCount);
            this.logToSentry("Detected entities persisted from multiple sessions"); // purposely don't put the sessionCount here to be grouped under a single entry in sentry
        }

        return latestStoredData;

        function getLatestStorageKey(key: string) {
            const sessionIdentifier = key.substring(storageKeyPrefix!.length);
            const watchdogFeedTime = LocalStorage.get<number>(sessionIdentifier)!;

            if (!watchdogFeedTime || (Date.now() - watchdogFeedTime) > constants.WATCHDOG_TIMEOUT) { // eslint-disable-line no-extra-parens
                // not been fed, this can be restored
                LocalStorage.delete(sessionIdentifier); // clean up original session watchdog that no longer been fed
                const persistData = LocalStorage.get<IPersistDataType>(key);

                // On IE, key can hang around longer than value - after an item has been restored, it will be gone
                // and there is no persistData if already restored by another session - won't be of interest
                if (!persistData) {
                    return;
                }

                if (persistData.adoptedBy && persistData.adoptedBy !== self.factoryId) {
                    const feedTime = LocalStorage.get<number>(persistData.adoptedBy)!;

                    if (feedTime && (Date.now() - feedTime) < constants.WATCHDOG_TIMEOUT) { // eslint-disable-line no-extra-parens
                        // recently fed - so the adopter is still alive
                        return;
                    } else {
                        LocalStorage.delete(persistData.adoptedBy); // clean up expired adopter session watchdog
                    }
                }

                if (latestStoredData) {
                    if (Date.parse(persistData.timestamp) > Date.parse(latestStoredData.timestamp)) {
                        latestStoredData = persistData;
                        latestKey = key;
                    }
                } else {
                    latestStoredData = persistData;
                    latestKey = key;
                }
            }
        }
    }

    private entityChanged(changeArgs: EntityChangedEventArgs) {
        const changedEntity = changeArgs.entity as IPersistingBreezeEntity;

        // can be detach on clear which won't have any entity
        // or won't do anything if entityPersistent is disabled from a dialog that disable it
        if (!changedEntity || this.entityPersistentDisabled) {
            return;
        }

        if (this.breezeHelper!.shouldPersistChangedEntity(changedEntity)) {
            const action = changeArgs.entityAction;
            const entityTypeName = this.breezeHelper!.getEntityShortName(changedEntity);

            // Only currently handling PropertyChange
            // - ignore EntityStateChange for entity that is added for the time being as we only want to persist
            //   entity that has been changed (i.e. if an entity is added without having the property changed,
            //   there is nothing worth saving).
            if (action === EntityAction.PropertyChange
                && !this.breezeHelper!.isPropertyChangeEventExcluded(changedEntity, (changeArgs.args as any).propertyName)) {
                if (changedEntity.entityAspect.entityState.isAdded()
                    || changedEntity.entityAspect.entityState.isModified()) {
                    changedEntity.hasPropertyChanged = true;
                    this.storeEntity(changedEntity);
                    this.logger.log("Changed entity ("
                        + entityTypeName
                        + ") persisted - unsavedEntities count: " + this.unsavedEntities.length);
                } else {
                    this.removeEntity(changedEntity, true);
                    this.logger.log("Entity removed - unsavedEntities count: " + this.unsavedEntities.length);
                }
            } else if (action === EntityAction.EntityStateChange) {
                if (changedEntity.entityAspect.entityState.isDeleted()
                    || changedEntity.entityAspect.entityState.isUnchanged()
                    || changedEntity.entityAspect.entityState.isDetached()) {
                    // entity removed - won't be saved anymore
                    this.removeEntity(changedEntity, true);
                    this.logger.log("Entity removed or unchanged - unsavedEntities count: " + this.unsavedEntities.length);
                }
            } else if (action === EntityAction.AttachOnImport && changedEntity.entityAspect.entityState.isAdded()) {
                this.previouslyAddedEntityImported.next(changeArgs);
            }
        } else if (this.removeEntity(changedEntity, true)) {
            // Remove previously persisted if there is any, e.g. when entity changes from valid to invalid.
            this.logger.log("Invalid entity removed from list - unsavedEntities count: " + this.unsavedEntities.length);
        }
    }

    private storeEntity(entity: IPersistingBreezeEntity) {
        const self = this;
        if (isUnchanged(entity)) {
            const deleteIndex = this.unsavedEntities.indexOf(entity);

            if (deleteIndex >= 0) {
                this.unsavedEntities.splice(deleteIndex, 1);
            }
        } else if (entity && this.unsavedEntities.indexOf(entity) < 0) {
            this.unsavedEntities.push(entity);
        }

        if (entity.entityAspect.entityState.isAdded()) {
            // added entity - persist them too if model marked as persisting
            const otherAddedEntities = this.entityManager!.getEntities(undefined, EntityState.Added);

            if (Array.isArray(otherAddedEntities)) {
                otherAddedEntities.forEach(addToUnsavedEntities);
            }
        }

        if (!entity.isRestoringPreviousValue) {
            // only persist entities if we are not restoring (which are already saved)
            this.persistEntities();
        } else {
            entity.hasPropertyChanged = true; // entity restored from other session - need to tag this to avoid being cleaned up
        }

        // dodgy way to bypass entities changed by froala
        function isUnchanged(ent: IBreezeEntity) {
            let unchanged = true;

            const keys = Object.keys(ent.entityAspect.originalValues);
            for (const key of keys) {
                const value = ent.entityAspect.originalValues[key];
                verifyProperty(value, key);
            }

            return unchanged;

            function verifyProperty(originalValue: any, key: string) {
                if (ent[key] !== originalValue) {
                    if ((typeof originalValue !== "string" && typeof ent[key] !== "string")
                        || StringUtilities.trimHtml(ent[key]) !== StringUtilities.trimHtml(originalValue)) {
                        unchanged = false;
                    }
                }
            }
        }

        function addToUnsavedEntities(otherEntity: IBreezeEntity) {
            if (self.breezeHelper!.shouldPersistChangedEntity(otherEntity)) {
                if (!self.unsavedEntities.includes(otherEntity)) {
                    self.unsavedEntities.push(otherEntity);
                }
            }
        }
    }

    /**
     * Remove an entity from the unsavedEntities list
     * @param {entity} entity Entity to be removed from the unsaved list
     * @param {boolean} saveAfter Perform a save if any entity is removed
     * @returns {boolean} true if the entity is found from the unsavedEntities list and removed; false otherwise
     */
    private removeEntity(entity: IPersistingBreezeEntity, saveAfter: boolean) {
        if (!entity) {
            return false;
        }

        const removalIndex = this.unsavedEntities.indexOf(entity);
        if (removalIndex < 0) {
            return false;
        }

        this.unsavedEntities.splice(removalIndex, 1);

        // check if remaining unsaved entities are all from other added entities, not because of property changed
        if (this.unsavedEntities.every((remainEntity) => !remainEntity.hasPropertyChanged)) {
            this.logger.log("Remaining unsaved entities are all as a result of other added entities - no prop change - discarding " +
                this.unsavedEntities.length);
            this.unsavedEntities = [];
        }

        if (saveAfter) {
            this.persistEntities();
        }

        return true;
    }

    private persistEntities() {
        if (!LocalStorage.isAvailable) { // shouldn't get here without local storage but just in case
            return;
        }

        const storageKey = this.getStorageKey();

        if (!storageKey || !this.entityManager) {
            return;
        }

        if (this.unsavedEntities.length > 0) {
            // remove detached or deleted entities
            this.unsavedEntities = this.unsavedEntities
                .filter((entity) => !entity.entityAspect.entityState.isDetached() && !entity.entityAspect.entityState.isDeleted());

            const saveEntityTypes = ArrayUtilities.distinct(this.unsavedEntities
                .map((unsavedEntity) => this.breezeHelper!.getEntityShortName(unsavedEntity)));

            const persistData: IPersistDataType = {
                unsavedEntities: this.entityManager.exportEntities(this.unsavedEntities, {
                    asString: false,
                    includeMetadata: false,
                }) as object,
                timestamp: new Date().toISOString(),
                types: saveEntityTypes,
                url: this.routeService.currentUrl,
                ngDialogs: this.dialogUtility!.serialiseOpenedDialogs(),
                sessionId: this.factoryId,
            };

            this.feedSessionWatchdog(); // feed timer first so that it won't be assumed as inactive when another session detects the followings
            LocalStorage.set(storageKey, persistData);
            this.startWatchdogFeederAndCleaner();
        } else if (LocalStorage.containsKey(storageKey)) {
            LocalStorage.delete(storageKey);
            this.stopWatchdogFeederAndCleaner();
        }
    }

    private getStorageKey() {
        let storageKey = this.getStorageKeyPrefix();

        if (storageKey) {
            storageKey += this.factoryId;
        }

        return storageKey;
    }

    private getStorageKeyPrefix() {
        let key = null;

        if (this.personId > 0 && this.organisationId > 0) {
            key = constants.UNSAVED_ENTITIES_PREFIX + this.personId + "." + this.organisationId + ".";
        }

        return key;
    }

}
