<span class="badge gap-2 pe-2"
      [ngClass]="StatusClass[status]">
    <span [ngClass]="'goal-status-icon-without-circle-' + StatusClass[status].toLowerCase()"
          class="invert-color"></span>
    <span class=" text-white">{{ statusText }}</span>
    <span *ngIf="showCollapsible">
        <i *ngIf="isCollapsed"
           class="fal fa-chevron-down"></i>
        <i *ngIf="!isCollapsed"
           class="fal fa-chevron-up"></i>
    </span>
</span>
