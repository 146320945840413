import { Component, Injector, Input, OnChanges } from "@angular/core";
import { OutstandingSurveyResponse } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { Survey, SurveyStatus, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { OrganisationDiagnosticAuthService } from "@org-common/lib/survey/organisation-diagnostic/organisation-diagnostic-auth.service";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { SurveyPageRoute } from "@org-common/lib/survey/survey-page/survey-page.route";
import { organisationDiagnosticSurveySummaryPage } from "@org-common/lib/survey/survey-summary-page/survey-summary-page.component";
import { SurveyUiService } from "@org-common/lib/survey/survey-ui.service";
import { SurveyUtils } from "@org-common/lib/survey/survey-utils";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { IWorkflowStepOverviewComponent, WorkflowStepOverviewComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { forkJoin, merge, of } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { IOrganisationDiagnosticStatusCustomData } from "../organisation-diagnostic-status-custom-data";

@WorkflowStepOverviewComponent("adapt-show-survey-progress")
@Component({
    selector: "adapt-show-survey-progress",
    templateUrl: "./show-survey-progress.component.html",
    styleUrls: ["./show-survey-progress.component.scss"],
})
export class ShowSurveyProgressComponent extends BaseComponent implements OnChanges, IWorkflowStepOverviewComponent {
    @Input() public workflowConnection?: WorkflowConnection;
    @Input() public workflowCard = true;

    public projectLabel = AdaptClientConfiguration.AdaptProjectLabel;
    public survey?: Survey;
    public participationRate = 0;
    public participationText = "Survey participation 0%";
    public surveyLink?: IAdaptLinkObject;
    public outstandingSurveyResponses: OutstandingSurveyResponse[] = [];
    public surveyCompleted = false;

    public surveySummaryLink?: IAdaptLinkObject;

    public constructor(
        private injector: Injector,
        private workflowService: WorkflowService,
        private surveyService: SurveyService,
        private surveyUiService: SurveyUiService,
        private userService: UserService,
        private routeService: RouteService,
        private orgDiagAuthService: OrganisationDiagnosticAuthService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        merge(
            rxjsBreezeService.entityTypeChanged(WorkflowConnection),
            rxjsBreezeService.entityTypeChanged(WorkflowStatus),
        ).pipe(
            filter((ent) => ent.workflowConnectionId === this.workflowConnection?.workflowConnectionId),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.onDataChanged());

        // update the list of survey responses when a survey finishes
        rxjsBreezeService.entityTypeChanged(OutstandingSurveyResponse).pipe(
            filter((ent) => ent.entityAspect.entityState.isDetached() &&
                // these checks are required as this can be from survey deletion, which will cause errors otherwise
                !!this.survey &&
                !this.survey.entityAspect.entityState.isDetached() &&
                !this.survey.entityAspect.entityState.isDeleted()),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.onDataChanged());
    }

    public ngOnChanges() {
        this.onDataChanged();
    }

    public onDataChanged() {
        this.survey = undefined; // reset survey first so that nothing will be shown if workflow connection is deleted
        if (this.workflowConnection &&
            !this.workflowConnection.entityAspect.entityState.isDetached() &&
            !this.workflowConnection.entityAspect.entityState.isDeleted()) {
            // Cannot call getCustomData if there is workflowConnection is deleted
            // - custom data is attached to workflow status entity
            // - if connection is deleted, there will be cascade delete to clear all statuses - so there won't be any status anymore
            // - without custom data, there won't be any survey id
            let customData: IOrganisationDiagnosticStatusCustomData = {};
            forkJoin([
                this.workflowService.getWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(this.workflowConnection),
                this.orgDiagAuthService.hasReadAccessToSurveys$(),
            ]).pipe(
                tap(([data]) => customData = data),
                switchMap(([data, canReadSurvey]) => {
                    if (data.surveyId && canReadSurvey) {
                        // need force remote here or we won't be seeing updated survey result which has the progress data
                        return this.surveyService.getSurveyById(data.surveyId!, !data.forceLocal);
                    }

                    return of(undefined);
                }),
                filter((survey) => !!survey),
                tap((survey) => {
                    this.survey = survey;
                    if (survey) {
                        const surveyResult = SurveyUtils.getSurveyResultForResponseGroup(survey, SurveyResponseGroup.All);
                        if (surveyResult) {
                            this.participationRate = surveyResult.percentageParticipation ? surveyResult.percentageParticipation : 0;
                            this.participationText = `Survey participation ${this.participationRate.toFixed(0)}%`;
                        }
                    }
                }),
                switchMap((survey) => {
                    this.surveyCompleted = survey?.status === SurveyStatus.Ended;
                    if (this.surveyCompleted) {
                        return organisationDiagnosticSurveySummaryPage.getRouteObject(
                            undefined,
                            setReturnPathSearchParam(this.injector, {}, organisationDiagnosticSurveySummaryPage.id, "Continue pathway"),
                        ).pipe(
                            map((odSummaryRoute) => {
                                this.surveySummaryLink = odSummaryRoute;
                                return survey;
                            }),
                        );
                    } else {
                        return of(survey);
                    }
                }),
                switchMap((survey) => forkJoin([
                    this.surveyService.getOutstandingSurveyResponsesForSurvey(survey!.surveyId, !customData.forceLocal),
                    survey?.surveyType === SurveyType.OrganisationDiagnostic
                        ? this.workflowService.getPersonalPageWithContinueWorkflow(this.workflowConnection!.workflowId)
                        : of(undefined),
                ])),
                switchMap(([outstandingResponses, returnPath]) => {
                    this.outstandingSurveyResponses = outstandingResponses;
                    if (outstandingResponses.length > 0) {
                        const currentPersonId = this.userService.getCurrentPersonId();
                        const myOutstandingResponse = outstandingResponses.find((response) => response.connection?.personId === currentPersonId);
                        if (myOutstandingResponse) {
                            return SurveyPageRoute.getRouteObject({}, {
                                token: myOutstandingResponse.token,
                                name: myOutstandingResponse.survey.name,
                                returnPath: returnPath?.url,
                            });
                        }
                    }

                    return of(undefined);
                }),
                tap((surveyLink) => this.surveyLink = surveyLink),
                this.takeUntilDestroyed(),
            ).subscribe(() => this.isInitialised = true);
        }
    }

    public viewOutstandingResponses() {
        return this.surveyUiService.viewOutstandingResponsesForSurvey(this.survey!, true).subscribe();
    }

    @Autobind
    public async takeSurvey(event?: CustomEvent) {
        if (this.surveyLink) {
            this.routeService.navigateByUrl(this.surveyLink.url);
        }

        // stop workflow from running on click
        if (event) {
            event.preventDefault();
        }
    }
}
