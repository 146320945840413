<div class="mb-3 pb-3 border-bottom">
    <h3>Your role in organisation</h3>
    <p>This is the role that you serve within the organisation.</p>
    <div [style.width.px]="175">
        <adapt-select-role-in-organisation [value]="currentPersonConnection.roleInOrganisation"
                                           (valueChange)="currentPersonRoleChanged($event)"></adapt-select-role-in-organisation>
    </div>

    <ng-container *ngIf="!hasOwner">
        <div class="alert alert-warning mt-3 d-flex flex-column"
             data-test="no-owner-warning">
            <p>For the best results, we recommend you invite the owners to the platform. You can always invite them later on.</p>
            <dx-check-box text="Continue your journey without involving the owners"
                          [value]="skipOwnerTask.getValue()"
                          (valueChange)="skipOwnerTask.next($any($event))"
                          data-test="no-owner-warning-checkbox"></dx-check-box>
        </div>
    </ng-container>
</div>

<h3>Your leadership team</h3>
<adapt-add-people (isValid)="validationGroupValid.next($event)"
                  (addPeopleBindingModelChanged)="addPeopleBindingModelChanged($event)"></adapt-add-people>

<div class="mt-3 pt-3 border-top">
    <dx-check-box text="Include a short message in the invitation email"
                  [value]="includeCustomMessage"
                  data-test="include-custom-message-check"
                  (valueChange)="includeCustomMessage = $any($event)"></dx-check-box>

    <div *ngIf="organisation?.supplementaryData && includeCustomMessage"
         class="mt-2">
        <dx-text-area placeholder="Type your short message here..."
                      [(value)]="organisation!.supplementaryData.customWelcomeEmail"
                      data-test="custom-welcome-email"
                      [inputAttr]="{ rows: 3 }"></dx-text-area>
    </div>
</div>
