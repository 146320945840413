import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { ContextSidebarState, contextSidebarWidth } from "@common/shell/context-sidebar/context-sidebar.interface";
import { ContextSidebarService } from "@common/shell/context-sidebar/context-sidebar.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { PositionConfig } from "devextreme/animation/position";
import dxPopup, { dxPopupAnimation, InitializedEvent } from "devextreme/ui/popup";
import { DxScrollViewComponent } from "devextreme-angular";
import { delay, distinctUntilChanged, firstValueFrom, Subject } from "rxjs";

@Component({
    selector: "adapt-dialog",
    templateUrl: "./adapt-dialog.component.html",
    styleUrls: ["./adapt-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AdaptDialogComponent extends BaseComponent implements OnDestroy {
    @Input() public articleId?: ImplementationKitArticle;
    @Input() public showGuidancePanelOnOpen = false;
    @Input() public showHelpButton = true;
    @Input() public minWidth: string | number = "auto";
    @Input() public maxWidth: string | number = "auto";
    @Input() public width?: string | number;
    @Input() public minHeight: string | number = "auto";
    @Input() public maxHeight?: string | number;
    @Input() public minimal = false;
    @Input() public padding = true;
    @Input() public expandable = true;
    @Input() public allowMouseWheelContentScroll = true;
    @Output() public shown = new EventEmitter();
    @Input() public fitHeight = false;
    @Output() public fullscreenChange = new EventEmitter<boolean>();

    @ViewChild(DxScrollViewComponent) private scrollView!: DxScrollViewComponent;

    private defaultPosition: PositionConfig = {
        my: "top",
        at: "top",
        of: window,
        offset: { x: 0, y: 32 },
    };
    public position: PositionConfig;
    public animationOptions: dxPopupAnimation;

    public contextSidebarVisible = false;
    public contextSidebarVisibleInitially?: boolean;

    public errorMessage?: string;
    // CM-5081 DX 21.1 has screwed up types - really should be the one commented out
    public container: any; // JQuery;
    public content?: JQuery;

    public fullscreen = false;
    public contextSidebarHasContent = false;

    private queuedPopupInstance = new QueuedCaller<dxPopup>();

    private visibleChange = new Subject<boolean>();
    private cancelEmitter = new Subject<void>();

    public constructor(
        private contextSidebarService: ContextSidebarService,
        private responsiveService: ResponsiveService,
    ) {
        super();

        const root = jQuery(":root");
        // Need to set container as position is now relative to a ref element to look the same as ui bootstrap dialog
        // and that will be taken first instead of the entire window
        // see sequence for shading: https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxPopup/Configuration/#shading
        this.container = root.find(".common-shell-component");
        this.position = { ...this.defaultPosition, offset: { ...this.defaultPosition.offset as object } };
        // default fading animation option from dx was nicer but here is to make it
        // looks the same as all the existing ui bootstrap dialog.
        this.animationOptions = {
            show: {
                type: "slide",
                duration: 400,
                from: {
                    position: { my: "top", at: "top", of: window },
                },
                to: {
                    position: this.position,
                },
            },
        };

        contextSidebarService.contextSidebarState$.pipe(
            distinctUntilChanged(),
            this.takeUntilDestroyed(),
        ).subscribe((state) => this.onContextSidebarStateUpdate(state));

        contextSidebarService.hasDialogContentChange$.pipe(
            delay(0), // update next cycle
            this.takeUntilDestroyed(),
        ).subscribe((contextSidebarHasContent) => this.contextSidebarHasContent = contextSidebarHasContent);
    }

    public get isMobile() {
        return this.responsiveService.currentBreakpoint.isMobileSize;
    }

    public get maxHeightValue() {
        return this.maxHeight ?? window.innerHeight - 70;
    }

    public onShown() {
        this.shown.emit();
    }

    public setVisible(visible: boolean) {
        // if we use the [visible] binding instead, we get an expression changed error.
        this.queuedPopupInstance.call((popupInstance) => popupInstance.instance().toggle(visible));
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        // only hide context sidebar if this dialog opened it
        if (this.contextSidebarVisible
            && this.showHelpButton
            && this.contextSidebarVisibleInitially === false) {
            this.hideContextSidebar();
        }

        this.visibleChange.complete();
        this.cancelEmitter.complete();
    }

    public onInitialized(e: InitializedEvent) {
        // From: https://www.devexpress.com/Support/Center/Question/Details/T264197/how-to-prevent-dxpopup-from-closing-when-the-esc-key-is-pressed
        e.component!.registerKeyHandler("escape", (arg: any) => {
            arg.stopPropagation();
            this.cancelEmitter.next();
        });

        this.queuedPopupInstance.setCallee(e.component!);

        // CM-5081 DX 21.1 has screwed up types - really should rip out the any
        this.content = (e.component! as any).content();

        // DX 22.1 added a focus handler to raise the selected dialog to the top z-index.
        // https://github.com/DevExpress/DevExtreme/pull/21194
        // This causes the context sidebar to be underneath the dialog.
        // We don't need this z-index focusing behaviour, so disable it by preventing the focusIn event.
        // https://supportcenter.devexpress.com/ticket/details/t1130515/inquiry-about-overlay-popup-z-index-focus-change
        this.content?.parent().on("focusin", (focusEvent) => {
            focusEvent.preventDefault();
        });
    }

    public get cancelEvents() {
        return this.cancelEmitter.asObservable();
    }

    public async onVisibleChanged(visible: boolean) {
        this.visibleChange.next(visible);

        if (visible) {
            // need to get sidebar visibility here as the subscription in the constructor has not fired before this.
            const sidebarState = await firstValueFrom(this.contextSidebarService.contextSidebarState$);
            this.onContextSidebarStateUpdate(sidebarState);

            if (this.showGuidancePanelOnOpen || this.contextSidebarVisible) {
                this.showContextSidebar();
            } else {
                this.hideContextSidebar();
            }
        }
    }

    public get visibleEvents() {
        return this.visibleChange.asObservable();
    }

    public resetScroll() {
        this.scrollView?.instance.scrollTo(0);
    }

    public onFullscreenToggle() {
        this.fullscreen = !this.fullscreen;
        this.fullscreenChange.emit(this.fullscreen);

        setTimeout(() => {
            this.queuedPopupInstance.call((popup) => popup.repaint());
            if (this.contextSidebarVisible && this.fullscreen) {
                // bit jarring with the popup automatically showing so hide it when entering fullscreen
                this.hideContextSidebar();
            }
        }, 10);
    }

    public toggleHelp() {
        if (this.contextSidebarVisible) {
            this.hideContextSidebar();
            return;
        }

        this.showContextSidebar();
    }

    private showContextSidebar() {
        this.contextSidebarService.setMaxWidth(typeof this.maxWidth === "number"
            ? this.maxWidth
            : undefined);

        // force popup when dialog is in fullscreen mode
        this.contextSidebarService.setIsOpen(true, this.fullscreen
            ? ContextSidebarState.Popup
            : undefined);
    }

    private hideContextSidebar() {
        this.contextSidebarService.setIsOpen(false);
    }

    private onContextSidebarStateUpdate(state: ContextSidebarState) {
        this.contextSidebarVisible = state === ContextSidebarState.Open;

        if (this.contextSidebarVisibleInitially === undefined) {
            this.contextSidebarVisibleInitially = this.contextSidebarVisible;
        }

        this.updatePosition(state);
    }

    private updatePosition(state: ContextSidebarState) {
        const offset: PositionConfig["offset"] = { ...this.defaultPosition!.offset as object };
        if (state === ContextSidebarState.Open) {
            // only need to offset half the width as offset is from centre
            offset.x = this.contextSidebarVisible ? -(contextSidebarWidth / 2) : 0;
        }
        this.position = { ...this.defaultPosition, offset };

        // animationOptions refers to position so we should update it as well
        this.animationOptions = {
            show: {
                type: "slide",
                duration: 400,
                from: {
                    position: {
                        my: "top",
                        at: "top",
                        of: window,
                        offset: { ...offset, y: 0 },
                    },
                },
                to: {
                    position: this.position,
                },
            },
        };
    }
}
