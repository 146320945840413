<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Import agenda template</h3>

    <ng-container adapt-dialog-content>
        <dx-select-box [dataSource]="dataSource"
                       [grouped]="true"
                       [searchEnabled]="true"
                       [searchExpr]="$any(['name','team.name'])"
                       [showClearButton]="false"
                       (valueChange)="templateSelected($event)"
                       displayExpr="name"
                       placeholder="Select an agenda template to import agenda items..."
                       noDataText="No other agenda template found. Create new template from 'Configure Organisation' or 'Configure Team' / 'Work' "
                       data-test="select-agenda-template">
        </dx-select-box>

        <ng-container *ngIf="selectedTemplate">
            <h3 class="mt-4">Agenda items</h3>
            <div *adaptLoading="loadingItems">
                <adapt-display-agenda-items [agendaItems]="itemsFromSelectedTemplate"
                                            [hideProgressIcon]="true"
                                            [hideNotesAndItems]="true"
                                            class="import-agenda-items"></adapt-display-agenda-items>
            </div>
        </ng-container>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   (cancelClick)="cancel()"
                                   [saveIsDisabled]="!selectedTemplate || loadingItems"
                                   saveButtonPreset="add"
                                   [saveText]="saveOnClose ? 'Import & save' : 'Import'"
                                   [saveBlockingClick]="importItemsFromTemplate"></adapt-standard-dialog-buttons>
</adapt-dialog>
