<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.EmployeeEngagementSurvey"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-manage-surveys [surveyType]="surveyType"
                      [callToActionTextTemplate]="callToActionText"
                      (initialise)="onInitialised()"></adapt-manage-surveys>

<ng-template #callToActionText>
    <p>
        Engaged workers are far more productive. They go that extra mile to support the
        business and do the best thing by the customer. Engaged employees work with passion
        and commitment. These are the people who drive innovation and help the businesses
        you coach move forward. By contrast, disengaged employees will at best have minimal
        impact but will most probably damage a business.
    </p>
    <p>
        The ADAPT Platform provides a way of taking regular pulse checks, via online surveys,
        so the leaders can measure how engagement in their business is tracking.
    </p>
    <p>
        Leaders can then use the employee engagement tool to track and analyse employee
        engagement over time.
    </p>
</ng-template>
