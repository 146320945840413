<div class="d-flex flex-column align-items-center">
    <ng-container *adaptLoading="loading">
        <div *ngIf="hasExistingCadence"
             class="alert alert-light">
            <h2 class="text-center mb-3">Cadence</h2>
            <adapt-review-recurrences [scheduledRecurrences]="recurrences"></adapt-review-recurrences>
        </div>
    </ng-container>

    <button *adaptIfAuthorised="StewardshipWorkMeetingsEdit"
            class="btn btn-primary px-4 my-4"
            [adaptBlockingClick]="setCadence"
            role="button">{{hasExistingCadence ? "Update" : "Set up"}} your cadence</button>
</div>
