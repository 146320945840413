import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { IDxCustomRuleValidationCallbackEvent } from "@common/ux/dx.types";
import { ValidationError, Validator } from "breeze-client";
import { SystemComponent } from "./system-component";
import { SystemDocumentType } from "./system-document";

export class SystemComponentExtensions {
    private readonly duplicateNameError: ValidationError;

    public constructor(private systemComponent: SystemComponent) {
        this.duplicateNameError = new ValidationError(
            undefined as unknown as Validator,
            {
                property: systemComponent.entityType.getProperty("name"),
            },
            "Document name already in use",
            "validSystemComponentName",
        );
    }

    public get componentLabel() {
        if (this.systemComponent.systemProcessMap) {
            return "process map";
        }

        if (this.systemComponent.systemDiagram) {
            return "diagram";
        }

        if (this.systemComponent.systemDocument) {
            if (this.systemComponent.systemDocument.type === SystemDocumentType.RichText) {
                return "document";
            }

            if (this.systemComponent.systemDocument.type === SystemDocumentType.Video) {
                return "video";
            }

            if (this.systemComponent.systemDocument.type === SystemDocumentType.ExternalLink) {
                return "external page";
            }
        }

        throw new AdaptError("unknown component");
    }

    public get uniqueNameValidator() {
        return ({ value }: IDxCustomRuleValidationCallbackEvent) => {
            if (typeof value === "string") {
                return this.systemComponent.extensions.isComponentNameUnique(value);
            }
            return false;
        };
    }

    public isComponentNameUnique(name?: string) {
        if (name === undefined) {
            name = this.systemComponent.name;
        }

        if (!this.systemComponent.system || !this.systemComponent.system.systemComponents) {
            // these are normally present, but when discarding the component this
            // can cause tons of errors if a check happens while being discarded.
            // since this happens when discarding the component we were creating,
            // we don't really care if the name is valid at this point.
            return false;
        }

        const nameExists = this.systemComponent.system.systemComponents
            .filter((component) => component !== this.systemComponent)
            .some((component) => component.name.trim().toLowerCase() === name!.trim().toLowerCase());
        return !nameExists;
    }

    public validateComponentName(name?: string) {
        if (!this.isComponentNameUnique(name)) {
            this.systemComponent.entityAspect.addValidationError(this.duplicateNameError);
        } else {
            this.systemComponent.entityAspect.removeValidationError(this.duplicateNameError);
        }
    }
}
