import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { BillingPeriod, BillingPeriodButtons } from "@common/ADAPT.Common.Model/account/account-extensions";

@Component({
    selector: "adapt-select-payment-frequency",
    templateUrl: "./select-payment-frequency.component.html",
})
export class SelectPaymentFrequencyComponent implements OnInit {
    protected readonly BillingPeriodButtons = BillingPeriodButtons;

    @Input() public title!: string;
    @Input() public account!: Account;
    @Output() public selectionChanged = new EventEmitter<BillingPeriod>();

    public initialBillingPeriod?: BillingPeriod[];

    public ngOnInit() {
        this.initialBillingPeriod = [this.account.billingPeriod];
    }
}
