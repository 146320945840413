<div *ngIf="inputGroup">
    <div class="d-flex flex-column mt-4 mt-lg-0 right-column">
        <label class="mt-3">{{inputGroupTypeLabel}}</label>
        <dx-text-box [(value)]="inputGroup.name"
                     (valueChange)="updateCompletion()"
                     [maxLength]="60"
                     [adaptValidateEntity]="inputGroup"
                     adaptValidateEntityProperty="name"></dx-text-box>

        <label class="mt-3">Description</label>
        <adapt-html-editor [(contents)]="inputGroup.description"
                           (contentsChange)="updateCompletion()"
                           [minHeight]="300"
                           forceSize="compact"></adapt-html-editor>
    </div>
</div>

