import { Injectable, Injector, TemplateRef } from "@angular/core";
import { IEntityWithLabelLocations } from "@common/ADAPT.Common.Model/organisation/entity-with-label-locations";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Label, LabelBreezeModel } from "@common/ADAPT.Common.Model/organisation/label";
import { LabelLocation, LabelLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { IAdaptLinkObject } from "@common/route/route.service";
import { BaseService } from "@common/service/base.service";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { forkJoin, merge, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

// chunk size when using 'in' operator in predicate to ensure number of inodes not exceeding inner limit
const QueryChunkSize = 15;

@Injectable({
    providedIn: "root",
})
export class LabellingService extends BaseService {
    private labelLinkProvider?: (label: Label) => Observable<IAdaptLinkObject>;

    public constructor(
        injector: Injector,
        private orgService: OrganisationService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);
    }

    public static getLabelMenuEntry(templateRef?: TemplateRef<any>) {
        return {
            text: "Set labels...",
            icon: "fal fa-fw fa-tags",
            visible: true,
            items: [{ templateRef, padding: false }],
        } as IAdaptMenuItem;
    }

    /**
     * Hot observable that emits when label location changed, or a label is deleted
     */
    public get labelEntityChanged$() {
        // The following emit to handle update of label when label location deleted / add cancelled
        return merge(
            this.rxjsBreezeService.entityTypeChanged(LabelLocation),
            this.rxjsBreezeService.entityTypeChanged(Label),
        );
    }

    public getAllLabelLocations = (forceRemote?: boolean) => this.commonDataService.getAll(LabelLocationBreezeModel, forceRemote);
    public getAllLabels = (forceRemote?: boolean) => this.commonDataService.getAll(LabelBreezeModel, forceRemote).pipe(
        map((labels) => labels.sort(SortUtilities.getSortByFieldFunction("name"))),
    );

    public registerLabelLinkProvider(provider: (label: Label) => Observable<IAdaptLinkObject>) {
        this.labelLinkProvider = provider;
    }

    public getLabelLink(label: Label) {
        return this.labelLinkProvider ? this.labelLinkProvider(label) : of(undefined);
    }

    public getLabelsForEntity<T extends IEntityWithLabelLocations>(entity: T) {
        let existingLabelLocationsObservable: Observable<LabelLocation[]> = of([]);

        if (!entity.entityAspect.entityState.isAdded()) {
            if (entity instanceof Item) {
                existingLabelLocationsObservable = this.getLabelLocationsForItem(entity.itemId);
            } else if (entity instanceof KeyFunction) {
                existingLabelLocationsObservable = this.getLabelLocationsForKeyFunction(entity.keyFunctionId);
            } else if (entity instanceof Team) {
                existingLabelLocationsObservable = this.getLabelLocationsForTeam(entity.teamId);
            } else if (entity instanceof Objective) {
                existingLabelLocationsObservable = this.getLabelLocationsForObjective(entity.objectiveId);
            } else if (entity instanceof SystemEntity) {
                existingLabelLocationsObservable = this.getLabelLocationsForSystem(entity.systemEntityId);
            } else if (entity instanceof ProcessStep) {
                existingLabelLocationsObservable = this.getLabelLocationsForProcessStep(entity.processStepId);
            } else if (entity instanceof Role) {
                existingLabelLocationsObservable = this.getLabelLocationsForRole(entity.roleId);
            } else {
                throw new Error("Entity not supported for labelling");
            }
        }

        // Only prime label locations if there isn't any before.
        // e.g. Issuing a separate query instead of relying on the kanban to do a single query
        // as the edit item dialog can possibly be spawn from other pages other than kanban page
        // - should only do this if label location is not primed however we can't really tell
        //   if an item has no label or not primed (both Array(0))
        const getLabelLocations = entity.labelLocations?.length
            ? of(entity.labelLocations)
            : existingLabelLocationsObservable;

        return getLabelLocations.pipe(
            // prime labels if there is any unprimed label from labelLocations
            switchMap((labelLocations) => labelLocations.some((l) => !l.label)
                ? this.getAllLabels()
                : of([])),
            map(() => entity!.labelLocations),
            map((labelLocations) => labelLocations.map((i) => i.label)),
        );
    }

    public createLabel(name: string) {
        return this.commonDataService.create(LabelBreezeModel, {
            name,
            organisationId: this.orgService.getOrganisationId(),
        });
    }

    public createLabelLocationFromLabel(label: Label, properties?: Partial<LabelLocation>) {
        return this.commonDataService.create(LabelLocationBreezeModel, {
            labelId: label.labelId,
            ...(properties ?? {}),
        });
    }

    public createLabelLocationFromLabelForEntity<T extends IEntityWithLabelLocations>(label: Label, entity?: T) {
        const properties: Partial<LabelLocation> = {
            itemId: entity?.itemId,
            keyFunctionId: entity?.keyFunctionId,
            objectiveId: entity?.objectiveId,
            systemId: entity?.systemEntityId,
            processStepId: entity?.processStepId,
        };

        if (entity instanceof Team) {
            // other related entity can have teamId too, e.g. objective
            properties.teamId = entity?.teamId;
        }

        if (entity instanceof Role) {
            // other related entity can have roleId too, e.g. process step
            properties.roleId = entity?.roleId;
        }

        return this.createLabelLocationFromLabel(label, properties);
    }

    public getLabelLocationsForItem(itemId: number) {
        const predicate = new MethodologyPredicate<LabelLocation>("itemId", "==", itemId);
        return this.getLabelLocationsByPredicate(predicate);
    }

    public getLabelsByPredicate(predicate?: MethodologyPredicate) {
        const key = `getLabelsByPredicate${predicate?.getKey()}`;
        return this.commonDataService.getWithOptions(LabelBreezeModel, key, {
            predicate,
            orderBy: "labelId", // order they are added to the entity
        });
    }

    public getLabelsWithIds(labelIds: number[]) {
        if (labelIds.length > 0) {
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(labelIds, QueryChunkSize);
            return forkJoin(chunksOfIds.map((ids) => this.getLabelsByPredicate(
                new MethodologyPredicate<Label>("labelId", "in", ids)))).pipe(
                map((queryResults) => ArrayUtilities.mergeArrays(queryResults)),
            ).pipe(
                // sort by the labelIds as I noticed when I query for label 1, 5, 4, it returns 1, 4, 5
                map((labels) => labels.sort((a, b) => labelIds.indexOf(a.labelId) - labelIds.indexOf(b.labelId))),
            );
        } else {
            return of([] as Label[]);
        }
    }

    public getLabelLocationsByPredicate(predicate?: MethodologyPredicate<LabelLocation>) {
        const key = `getLabelLocationsByPredicate${predicate?.getKey()}`;
        return this.commonDataService.getWithOptions(LabelLocationBreezeModel, key, {
            predicate,
            navProperty: "label",
            orderBy: "labelLocationId", // order they are added to the entity
        });
    }

    public getLabelLocationsForLabel(labelId: number) {
        const predicate = new MethodologyPredicate<LabelLocation>("labelId", "==", labelId);
        return this.getLabelLocationsByPredicate(predicate);
    }

    public getLabelLocationsForLabels(labelIds: number[]) {
        if (labelIds.length > 0) {
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(labelIds, QueryChunkSize);
            return forkJoin(chunksOfIds.map((ids) => this.getLabelLocationsByPredicate(
                new MethodologyPredicate<LabelLocation>("labelId", "in", ids)))).pipe(
                map((queryResults) => ArrayUtilities.mergeArrays(queryResults)),
            );
        } else {
            return of([] as LabelLocation[]);
        }
    }

    public getLabelLocationsForKeyFunction(keyFunctionId: number) {
        const predicate = new MethodologyPredicate<LabelLocation>("keyFunctionId", "==", keyFunctionId);
        return this.getLabelLocationsByPredicate(predicate);
    }

    public primeLabelLocationsForKeyFunctions(keyFunctions: KeyFunction[]) {
        if (keyFunctions.length > 0) {
            const keyFunctionIds = keyFunctions.map((kf) => kf.keyFunctionId);
            // split ids to not exceed the maximum number of inode from entity framework
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(keyFunctionIds, QueryChunkSize);

            return forkJoin(chunksOfIds.map((ids) => this.getLabelLocationsByPredicate(
                new MethodologyPredicate<LabelLocation>("keyFunctionId", "in", ids))));
        } else {
            return of([] as LabelLocation[][]);
        }
    }

    public getLabelLocationsForTeam(teamId: number) {
        const predicate = new MethodologyPredicate<LabelLocation>("teamId", "==", teamId);
        return this.getLabelLocationsByPredicate(predicate);
    }

    public getLabelLocationsForObjective(objectiveId: number) {
        const predicate = new MethodologyPredicate<LabelLocation>("objectiveId", "==", objectiveId);
        return this.getLabelLocationsByPredicate(predicate);
    }

    public primeLabelLocationsForObjectives(objectives: Objective[]) {
        if (objectives.length > 0) {
            const objectiveIds = objectives.map((o) => o.objectiveId);
            // split ids to not exceed the maximum number of inode from entity framework
            const chunksOfIds = ArrayUtilities.splitArrayIntoChunksOfSize(objectiveIds, QueryChunkSize);

            return forkJoin(chunksOfIds.map((ids) => this.getLabelLocationsByPredicate(
                new MethodologyPredicate<LabelLocation>("objectiveId", "in", ids))));
        } else {
            return of([] as LabelLocation[][]);
        }
    }

    public primeLabelLocationsForAllTeams() {
        return this.getLabelLocationsByPredicate(new MethodologyPredicate<LabelLocation>("teamId", "!=", null));
    }

    public getLabelLocationsForSystem(systemId: number) {
        const predicate = new MethodologyPredicate<LabelLocation>("systemId", "==", systemId);
        return this.getLabelLocationsByPredicate(predicate);
    }

    public getLabelLocationsForProcessStep(processStepId: number) {
        const predicate = new MethodologyPredicate<LabelLocation>("processStepId", "==", processStepId);
        return this.getLabelLocationsByPredicate(predicate);
    }

    public getLabelLocationsForRole(roleId: number) {
        const predicate = new MethodologyPredicate<LabelLocation>("roleId", "==", roleId);
        return this.getLabelLocationsByPredicate(predicate);
    }
}
