import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { InputGroup } from "./input-group";
import { InputsCanvas } from "./inputs-canvas";

export enum InputType {
    Strength = "Strength",
    Weakness = "Weakness",
    Trend = "Trend",
    Stop = "Stop",
    Start = "Start",
    KeepDoing = "KeepDoing",
    Win = "Win",
    Lose = "Lose",
    Compete = "Compete",
}

export const InputTypeTooltipMap: Record<InputType, string> = {
    [InputType.Strength]: "Where we are strong",
    [InputType.Weakness]: "Where we are weak",
    [InputType.Trend]: "An industry trend",
    // no custom tooltips for stop, start & keep doing. yet.
    [InputType.Stop]: InputType.Stop,
    [InputType.Start]: InputType.Start,
    [InputType.KeepDoing]: InputType.KeepDoing,
    [InputType.Win]: "How they win against us",
    [InputType.Lose]: "How they lose against us",
    [InputType.Compete]: "Where they compete with us",
};

export class Input extends BaseEntity<Input> {
    public inputId!: number;
    public name!: string;
    public description?: string;
    public canvasId!: number;
    public type!: InputType;
    public inputGroupId?: number;

    public canvas!: InputsCanvas;
    public inputGroup?: InputGroup;
}

// entity without an endpoint as this will come through a nav property from canvas
export const InputBreezeModel = new BreezeModelBuilder("Input", Input)
    .build();
