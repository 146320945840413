<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Associate Meeting Agenda Item</h3>

    <ng-container adapt-dialog-content>
        <div *adaptLoadingUntilFirstEmit="let dataSource of recentMeetingsDataSource$">
            <h3>Recent Meetings
                <i class="fal fa-info-circle"
                   adaptTooltip="Meetings you have permissions to associate within the last 6 months"></i>
            </h3>
            <dx-select-box [dataSource]="dataSource"
                           [showClearButton]="false"
                           [searchEnabled]="true"
                           [searchExpr]="$any(['name','team.name'])"
                           [value]="selectedMeeting"
                           (valueChange)="meetingSelected($event)"
                           displayExpr="name"
                           itemTemplate="item"
                           [grouped]="true"
                           groupTemplate="group"
                           placeholder="Select a meeting to import agenda items..."
                           noDataText="No other meeting found."
                           data-test="select-meeting-agenda">
                <div *dxTemplate="let data of 'group'">
                    <i class="fal fa-users"></i>
                    {{data.key}}
                </div>
                <div *dxTemplate="let meeting of 'item'"
                     class="d-flex">
                    <span>{{meeting.name}}</span>
                    <small class="ms-auto text-muted">{{meeting.meetingDateTime | adaptDate}}</small>
                </div>
            </dx-select-box>

            <ng-container *ngIf="selectedMeeting">
                <h3 class="mt-4">Agenda items</h3>
                <div *adaptLoading="loadingItems">
                    <dx-list [dataSource]="agendaItems"
                             displayExpr="name"
                             [(selectedItems)]="selectedAgendaItems"
                             [showSelectionControls]="true"
                             selectionMode="single"></dx-list>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   (cancelClick)="cancel()"
                                   [saveIsDisabled]="selectedAgendaItems.length < 1 || loadingItems"
                                   saveButtonPreset="add"
                                   saveText="Link & save"
                                   [saveBlockingClick]="linkAndSave"></adapt-standard-dialog-buttons>
</adapt-dialog>
