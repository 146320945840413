<div class="row g-3 gx-5">
    <div class="col-12 col-lg-6 d-flex"
         [adaptCollapsible]="ImplementationKitArticle.ApplyGoodStewardship">
        <adapt-styled-implementation-kit [articleId]="ImplementationKitArticle.ApplyGoodStewardship"
                                         contentStyle="embedded"></adapt-styled-implementation-kit>
    </div>
    <div class="col-12 col-lg-6">
        <form id="configureStewardshipForm"
              name="configureStewardshipForm"
              role="form"
              *ngIf="teamMeetingGuidance">
            <div class="form-group">
                <label>Why do we meet?</label>
                <adapt-html-editor [(contents)]="teamMeetingGuidance.meetingIntent"
                                   forceSize="compact"></adapt-html-editor>
            </div>
            <div class="form-group">
                <label>How will we behave when we meet?</label>
                <adapt-html-editor [(contents)]="teamMeetingGuidance.meetingGuidelines"
                                   forceSize="compact"></adapt-html-editor>
            </div>
            <div class="form-group">
                <label>How frequently will we meet?</label>
                <adapt-html-editor [(contents)]="teamMeetingGuidance.meetingFrequency"
                                   forceSize="compact"></adapt-html-editor>
            </div>
            <div class="form-group">
                <label>How will we make decisions?</label>
                <adapt-html-editor [(contents)]="teamMeetingGuidance.decisionProcess"
                                   forceSize="compact"></adapt-html-editor>
            </div>
        </form>
    </div>
</div>
