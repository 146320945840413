import { Component, Inject } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { Observable, timer } from "rxjs";
import { finalize, map, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";

export interface IImportMeetingAgendaData {
    currentMeeting?: Meeting;
    saveOnClose: boolean;
}

@Component({
    selector: "adapt-import-from-other-meeting-agenda-dialog",
    templateUrl: "./import-from-other-meeting-agenda-dialog.component.html",
    styleUrls: ["../import-from-agenda-template-dialog/import-from-agenda-template-dialog.component.scss"],
})
export class ImportFromOtherMeetingAgendaDialogComponent extends BaseDialogComponent<IImportMeetingAgendaData, MeetingAgendaItem[]> {
    public readonly dialogName = "ImportFromOtherMeetingAgendaDialog";

    public recentMeetingsDataSource$: Observable<DataSource>;
    public selectedMeeting?: Meeting;
    public loadingItems = false;
    public agendaItems: MeetingAgendaItem[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IImportMeetingAgendaData,
        private meetingsService: MeetingsService,
    ) {
        super();

        this.recentMeetingsDataSource$ = this.meetingsService.getAllRecentMeetings().pipe(
            map((meetings) => this.dialogData.currentMeeting
                ? meetings.filter((m) => m !== this.dialogData.currentMeeting) // excluding the current meeting
                : meetings),
            map((meetings) => {
                // sort the recent meetings by the current meeting's team, and then by alphabetical team name
                // note: DX forces you to pre-group data in this way to get custom sort
                // https://supportcenter.devexpress.com/ticket/details/t923260/selectbox-how-to-sort-a-grouped-field-based-on-another-field
                const groupedData = ArrayUtilities.groupArrayBy(meetings, (m) => m.team);
                groupedData.sort((a, b) => {
                    if (a.key?.teamId === this.dialogData.currentMeeting?.teamId) {
                        return -1;
                    } else if (b.key?.teamId === this.dialogData.currentMeeting?.teamId) {
                        return 1;
                    } else {
                        return a.key!.name.localeCompare(b.key!.name);
                    }
                });

                const sortedMeetings = groupedData.flatMap((g) => g.items);
                const mappedTeamNames: { [teamId: number]: string } = {};
                // fixed 6 digits padding in front of team name to keep the order
                groupedData.forEach((g, index) => mappedTeamNames[g.key?.teamId ?? 0] = `${index.toString().padStart(6, "0")}${g.key?.name}`);

                return new DataSource({
                    store: new ArrayStore({
                        data: sortedMeetings,
                        key: "meetingId",
                    }),
                    group: (meeting: Meeting) => mappedTeamNames[meeting.teamId],
                });
            }),
        );
    }

    public meetingSelected(meeting: Meeting) {
        this.selectedMeeting = meeting;
        this.loadingItems = true;
        this.meetingsService.getAgendaItemsForMeeting(meeting, false).pipe(
            this.takeUntilDestroyed(),
            finalize(() => this.loadingItems = false),
        ).subscribe((items) => this.agendaItems = items);
    }

    @Autobind
    public importItems() {
        // do resolve in the next digest cycle to workaround unfinished subscriber in blocking click destruction
        return timer(0).pipe(
            tap(() => this.resolve(this.agendaItems)),
        );
    }
}
