import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Account, SubscriptionStatus } from "@common/ADAPT.Common.Model/account/account";
import { BillingPeriod, BillingPeriodButtons } from "@common/ADAPT.Common.Model/account/account-extensions";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ICardPartialDetails } from "@common/payment-processing/card-partial-details.interface";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { IUpdateCreditCardInput, UpdateCreditCardDetailsComponent } from "@common/payment-processing/update-credit-card-details/update-credit-card-details.component";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { catchError, EMPTY, Observable, of, switchMap, tap } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
    selector: "adapt-set-initial-subscription-dialog",
    templateUrl: "./set-initial-subscription-dialog.component.html",
})
export class SetInitialSubscriptionDialogComponent extends BaseDialogComponent<Account, SubscriptionStatus> implements OnInit {
    public readonly dialogName = "SetInitialSubscriptionDialogComponent";
    public readonly StartSubscriptionArticle = ImplementationKitArticle.StartSubscription;
    public readonly BillingPeriodButtons = BillingPeriodButtons;
    public readonly BillingPeriod = BillingPeriod;

    public cardSetCorrectly = false;
    public selectedBillingPeriod: BillingPeriod[];

    @ViewChild(UpdateCreditCardDetailsComponent) public updateCreditCardDetailsComponent?: UpdateCreditCardDetailsComponent;

    public creditCardInput: IUpdateCreditCardInput = {
        cardDetails: undefined,
        organisationIdentifier: {
            organisationId: this.account.organisationId,
            eulaToken: undefined,
        },
    };

    public cardDetails?: ICardPartialDetails;
    public cardDetailsLoading = true;

    private originalStatus = this.account.status;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public account: Account,
        private commonDataService: CommonDataService,
        private paymentService: PaymentProcessingService,
    ) {
        super();
        this.selectedBillingPeriod = [this.account.billingPeriod];
    }

    public ngOnInit() {
        this.getCardDetails().subscribe();
    }

    public cancel() {
        this.commonDataService.rejectChanges(this.account)
            .subscribe(() => super.cancel());
    }

    @Autobind
    public saveAndClose() {
        if (!this.cardDetails && !this.updateCreditCardDetailsComponent) {
            this.setErrorMessage("Updating credit card failed. Please contact us.");
            return EMPTY;
        }

        const obs: Observable<any> = this.updateCreditCardDetailsComponent?.saveCreditCard() ?? of(undefined);
        return obs.pipe(
            switchMap(() => this.paymentService.saveEntities(this.account)),
            switchMap(() => this.paymentService.commenceSubscription(this.account.organisationId)),
            tap(() => super.resolve(this.originalStatus)),
            catchError((e) => {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
        );
    }

    private getCardDetails() {
        this.setErrorMessage(undefined);
        this.cardDetailsLoading = true;

        return this.paymentService.getCreditCardPartialDetails(this.creditCardInput.organisationIdentifier)
            .pipe(
                tap((cardDetails) => {
                    this.cardDetails = cardDetails;
                    this.cardSetCorrectly = !!cardDetails;
                }),
                catchError((errorMessage: string) => {
                    this.setErrorMessage(errorMessage);
                    return EMPTY;
                }),
                finalize(() => this.cardDetailsLoading = false),
            );
    }

    public onSelect(billingPeriod: BillingPeriod) {
        this.account.billingPeriod = billingPeriod;
    }
}
