<div class="d-flex justify-content-center">
    <a href="https://theadaptway.com"
       aria-label="Go to adapt website">
        <img src="/content/shell/images/adaptplatformlogo.svg"
             alt="adapt logo"
             width="140" />
    </a>
</div>

<div *ngIf="!isMobileSize"
     class="d-flex my-4">
    <dx-tab-panel [items]="items"
                  itemTitleTemplate="itemTitleTemplate"
                  (onSelectionChanged)="selectionChanged($event)"
                  (onInitialized)="onInitialised($event)"
                  class="w-100">

        <div *dxTemplate="let item of 'itemTitleTemplate'">
            <h2>{{item.title}}</h2>
        </div>

        <div *dxTemplate="let item of 'termsConditionsTemplate'">
            <ng-container *ngTemplateOutlet="termsConditionsTemplate"></ng-container>
        </div>

        <div *dxTemplate="let item of 'privacyPolicyTemplate'">
            <ng-container *ngTemplateOutlet="privacyPolicyTemplate"></ng-container>
        </div>
    </dx-tab-panel>
</div>

<div *ngIf="isMobileSize">
    <h2>Terms and Conditions</h2>
    <ng-container *ngTemplateOutlet="termsConditionsTemplate"></ng-container>
    <h2>Privacy</h2>
    <ng-container *ngTemplateOutlet="privacyPolicyTemplate"></ng-container>
</div>

<ng-template #termsConditionsTemplate>
    <adapt-eula></adapt-eula>
</ng-template>

<ng-template #privacyPolicyTemplate>
    <adapt-loading-spinner *ngIf="privacyLoading"
                           [largeSpinner]="true"></adapt-loading-spinner>
    <iframe src="https://app.enzuzo.com/policies/privacy/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMzEzNCwiQ3VzdG9tZXJOYW1lIjoiY3VzdC1QN3YyWmd3RSIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiVmVyc2lvbiI6MCwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE2OTY5ODg0OTB9.dzvyYBl3XvwNwTdr_adrP1jyWmITZVKB1Vs-cbAbw60"
            frameborder="0"
            class="w-100"
            [ngClass]="{'d-none' : privacyLoading}"
            style="min-height: 500px"
            (load)="privacyLoading = false"></iframe>
</ng-template>
