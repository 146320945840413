<adapt-see-more-popup [minWidth]="500"
                      popupTitle="Strategic themes"
                      buttonText="View strategic themes">
    <ng-template>
        <div class="w-100 d-flex mt-3 align-items-end pb-3 mb-3 border-bottom">
            <adapt-strategic-guidance area="strategic goals"></adapt-strategic-guidance>
            <button adaptButton="primary"
                    class="ms-4"
                    [adaptBlockingClick]="addGoal">Add goal</button>
        </div>
        <adapt-strategic-goals *adaptIfAuthorised="EditStrategyGoal"
                               [isEditing]="true"
                               [showToolbarMenu]="false"></adapt-strategic-goals>
    </ng-template>
</adapt-see-more-popup>

