import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatus, ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { buttonPreset } from "@common/ux/button/button-preset";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { Subject } from "rxjs";
import { catchError, filter, map, startWith, switchMap } from "rxjs/operators";
import { LabellingService } from "../../labelling/labelling.service";
import { MoveObjectiveDialogComponent } from "../move-objective-dialog/move-objective-dialog.component";
import { ObjectivesService } from "../objectives.service";
import { ObjectivesRouteService } from "../objectives-route.service";
import { ObjectivesUiService } from "../objectives-ui.service";

@Component({
    selector: "adapt-objective-actions",
    templateUrl: "./objective-actions.component.html",
})
export class ObjectiveActionsComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public currentPageTeamId: number | null = null;
    @Input() public showEditAction = true;
    @Input() public objective!: Objective;
    @Output() public objectiveDeleted = new EventEmitter<Objective>();

    public hasActiveTeamWithObjectiveFeature = true;

    public menuItems: IAdaptMenuItem[] = [];
    private triggerMenuUpdate$ = new Subject<void>();

    private menuItemTemplate?: TemplateRef<any>;
    @ViewChild("itemTemplate") public set menuItemTemplateSetter(template: TemplateRef<any>) {
        this.menuItemTemplate = template;
        this.triggerMenuUpdate$.next();
    }

    private labelMenuItem?: IAdaptMenuItem;
    @ViewChild("labelMenuTemplate") public set labelMenuTemplateSetter(template: TemplateRef<any>) {
        this.labelMenuItem = LabellingService.getLabelMenuEntry(template);
        this.triggerMenuUpdate$.next();
    }

    constructor(
        private objectivesUiService: ObjectivesUiService,
        private objectivesService: ObjectivesService,
        private objectivesRouteService: ObjectivesRouteService,
        private commonDialogService: AdaptCommonDialogService,
        rxjsBreezeService: RxjsBreezeService,
        teamsService: CommonTeamsService,
        featuresService: FeaturesService,
    ) {
        super();

        this.triggerMenuUpdate$.pipe(
            filter(() => !!this.objective),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.setMenu());

        rxjsBreezeService.entityTypeChanged(Team).pipe(
            startWith(undefined),
            switchMap(() => teamsService.promiseToGetAllActiveTeams()),
            map((activeTeams) => activeTeams.filter((team) => featuresService.isFeatureActive(FeatureName.StewardshipObjectives, team))),
            this.takeUntilDestroyed(),
        ).subscribe((activeObjTeams) => {
            const hasActiveTeams = activeObjTeams.length > 0;
            if (hasActiveTeams !== this.hasActiveTeamWithObjectiveFeature) {
                this.hasActiveTeamWithObjectiveFeature = hasActiveTeams;
                this.triggerMenuUpdate$.next();
            }
        });

        rxjsBreezeService.entityTypeChanged(Objective).pipe(
            filter((objective) => objective === this.objective),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerMenuUpdate$.next());
    }

    public ngOnInit() {
        // not using startWith, otherwise we can get ExpressionChangedAfterItHasBeenCheckedError
        this.triggerMenuUpdate$.next();
        this.isInitialised = true;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.objective && this.objective && this.isInitialised) {
            // objective can be changed if going from one to another - need to trigger menu update
            // this will clean up the menu, causing the submenu to re-initialise (get a new recent-label-submenu-content)
            this.triggerMenuUpdate$.next();
        }
    }

    public setMenu() {
        this.menuItems = [];
        const items: IAdaptMenuItem[] = [];

        // edit
        if (this.showEditAction) {
            items.push({
                text: "Edit objective",
                icon: "fal fa-fw fa-folder-open",
                onClick: () => {
                    this.objectivesUiService.editObjective(this.objective)
                        .subscribe((objective) => this.objectivesService.emitObjectiveUpdate(objective));
                },
            });
        }

        // set status
        if (this.objective.status !== ObjectiveStatus.Closed) {
            const subItems: IAdaptMenuItem[] = [];
            ObjectiveStatusMetadata.All
                .filter((x) => x.status !== ObjectiveStatus.Closed && x.status !== this.objective.status)
                .forEach((i) => {
                    subItems.push({
                        templateRef: this.menuItemTemplate,
                        templateData: i.status,
                        onClick: () => this.setStatus(i.status),
                    });
                });

            items.push({
                beginGroup: true,
                closeMenuOnClick: false,
                text: "Change status to...",
                icon: "fal fa-fw fa-tag",
                items: subItems,
            });
        }

        if (this.labelMenuItem) {
            items.push(this.labelMenuItem);
        }

        items.push({
            beginGroup: true,
            text: "Review objective",
            icon: "fal fa-fw fa-check-circle",
            onClick: () => {
                this.objectivesUiService.reviewObjective(this.objective).pipe(
                    this.takeUntilDestroyed(),
                ).subscribe();
            },
        });

        // close/re-open Objective
        if (this.objective.status !== ObjectiveStatus.Closed) {
            items.push({
                icon: buttonPreset.archiveAndSave.iconClass,
                text: "Close objective",
                onClick: this.closeObjective,
            });
        } else if (this.objective.status === ObjectiveStatus.Closed) {
            items.push({
                icon: "fal fa-fw fa-inbox-out",
                text: "Re-open objective",
                onClick: () => this.setStatus(ObjectiveStatus.OnTrack),
            });
        }

        if (this.hasActiveTeamWithObjectiveFeature) {
            items.push({
                beginGroup: true,
                text: "Move objective",
                icon: "fal fa-fw fa-share",
                onClick: () => {
                    this.commonDialogService.open(MoveObjectiveDialogComponent, this.objective).subscribe();
                },
            });
        }

        // Duplication
        items.push({
            beginGroup: true,
            text: "Duplicate objective",
            icon: buttonPreset.duplicateAndRedirect.iconClass,
            onClick: this.duplicateObjective,
        });


        // all annual excluding external team annual
        // or external organisation objective
        if ((this.objective.type === ObjectiveType.Annual &&
            (this.objective.teamId === this.currentPageTeamId || this.objective.isOrganisationObjective)) ||
            (this.objective.isOrganisationObjective && this.objective.teamId !== this.currentPageTeamId)) {
            items.push({
                text: "Add supporting objective",
                icon: buttonPreset.add.iconClass,
                onClick: this.addSupportingObjective,
            });
        }

        // delete
        items.push({
            beginGroup: true,
            text: "Delete objective",
            icon: buttonPreset.deleteAndSave.iconClass,
            onClick: () => {
                this.objectivesUiService.promptToDeleteObjective(this.objective)
                    .subscribe(() => {
                        this.objectiveDeleted.emit(this.objective);
                        this.objectivesService.emitObjectiveUpdate(this.objective);
                    });
            },
        });

        // root
        this.menuItems.push({
            icon: MenuComponent.SmallRootMenu.icon,
            items,
        });
    }

    @Autobind
    private closeObjective() {
        const confirmationDialog: IConfirmationDialogData = {
            title: "Close objective",
            message: "Are you sure you want to close this objective? This will hide the objective in the standard objective tree and list views.",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        };

        this.commonDialogService.openConfirmationDialog(confirmationDialog).pipe(
            switchMap(() => {
                this.objective.status = ObjectiveStatus.Closed;
                return this.objectivesService.saveEntities([this.objective]);
            }),
            catchError((err) => {
                this.objective!.entityAspect.rejectChanges();
                return this.commonDialogService.showErrorDialog("Error Closing Objective", ErrorHandlingUtilities.getHttpResponseMessage(err));
            }),
        ).subscribe(() => this.objectivesService.emitObjectiveUpdate(this.objective));
    }

    private setStatus(status: ObjectiveStatus) {
        this.objective.status = status;
        this.objectivesService.saveEntities([this.objective]).pipe(
            catchError((err) => {
                this.objective!.entityAspect.rejectChanges();
                return this.commonDialogService.showErrorDialog("Error Updating Objective", ErrorHandlingUtilities.getHttpResponseMessage(err));
            }),
        ).subscribe(() => {
            this.objectivesService.emitObjectiveUpdate(this.objective);
        });
    }

    @Autobind
    private duplicateObjective() {
        if (this.objective.entityAspect.entityState.isAddedModifiedOrDeleted() ||
            this.objective.comments.some((o) => o.entityAspect.entityState.isModified())) {
            return this.commonDialogService.showErrorDialog("Error duplicating Objective", "Please save your changes before attempting to duplicate this objective").subscribe();
        }

        const dueDateText = this.objective.type === ObjectiveType.Annual ? "1 year" : "3 months";
        return this.commonDialogService.openConfirmationDialog({
            title: "Duplicate objective",
            message: `<p>The objective
            <b>${this.objective.title}</b>
            will be duplicated with the following changes:</p>
            <ul>
                <li>Parent objective relationship will be inherited</li>
                <li>Description and assignee will be copied</li>
                <li>Creation date will be set to the current time and due date will be set to ${dueDateText} later</li>
                <li>Key results, labels, related objective and item links will be copied</li>
                <li>Status will be changed to "To do"</li>
            </ul>
            <p>The following will NOT be copied:</p>
            <ul>
                <li>Comments</li>
                <li>Child objectives</li>
                <li>Key result values</li>
            </ul>
            <p>Please confirm that you want to duplicate the objective and redirect to the duplicated objective after the save.</p>`,
            confirmButtonPreset: "duplicateAndRedirect",
        } as IConfirmationDialogData).pipe(
            switchMap(() => this.objectivesService.cloneAndSaveObjective(this.objective)),
            switchMap((newObjective) => this.objectivesRouteService.gotoEditObjectivePageRoute(newObjective.objectiveId, newObjective.teamId)),
        ).subscribe();
    }

    @Autobind
    private addSupportingObjective() {
        this.objectivesUiService.createObjective(this.currentPageTeamId!, undefined, (objective) => {
            objective.type = this.objective.teamId === this.currentPageTeamId
                ? ObjectiveType.Quarterly
                : ObjectiveType.Annual;
            objective.parentObjective = this.objective;
        }).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }
}
