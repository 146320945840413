import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AdaptClientConfiguration, AdaptEnvironment } from "@common/configuration/adapt-client-configuration";
import { ReplaySubject, Subject, timer } from "rxjs";
import { delay, retryWhen, scan, switchMap, takeUntil, takeWhile } from "rxjs/operators";
import { buildInfo } from "../build-info/ADAPT.BuildInfo";
import { Logger } from "../logger/logger";
import { ClientReleaseInfo } from "./client-release-info";

@Injectable({
    providedIn: "root",
})
export class ReleaseNotifierService {
    private readonly logger = Logger.getLogger("ReleaseNotifierService");
    private readonly pollingInterval = 180000;

    private currentReleaseFromServer?: ClientReleaseInfo;
    private clientUpdateRequiredSubject$ = new ReplaySubject<boolean>(1);
    private eventSource?: EventSource;
    private disableReleaseCheck = false;
    private terminatePoll = new Subject<void>();

    public constructor(private httpClient: HttpClient) {
        const disabledEnvironments = [
            // disable check as development shares dev_shared storage, which will alert each other as each developer will have
            // different version
            AdaptEnvironment.Local,
            AdaptEnvironment.Devcloud,
            // Disable beta as it will never be up to date with production!
            AdaptEnvironment.ProductionBeta,
        ];
        if (disabledEnvironments.some((i) => i === AdaptClientConfiguration.AdaptEnvironment)) {
            this.disableReleaseCheck = true;
        }

        if (!this.disableReleaseCheck) {
            this.initialise();
        }
    }

    // This is to allow diagnostics section from about controller to enable release check
    public get disable() {
        return this.disableReleaseCheck;
    }

    public set disable(value: boolean) {
        this.disableReleaseCheck = value;
        this.terminatePoll.next();
        if (this.eventSource) {
            this.eventSource.close();
        }

        if (!this.disableReleaseCheck) {
            this.initialise();
        }
    }

    public get clientUpdateRequired$() {
        return this.clientUpdateRequiredSubject$.asObservable();
    }

    public get serverReleaseInfo() {
        if (this.currentReleaseFromServer) {
            return this.currentReleaseFromServer;
        } else {
            return new ClientReleaseInfo();
        }
    }

    private initialise() {
        this.getCurrentClientReleaseInfo()
            .subscribe((clientRelease) => {
                this.currentReleaseFromServer = clientRelease;
                this.checkClientRelease();
                this.logger.info("Current client release: ", clientRelease);

                // only subscribe to version changes if there is a successful get from the api
                this.pollClientReleaseInfo().subscribe((clientReleaseInfo) => {
                    this.currentReleaseFromServer = clientReleaseInfo;
                    this.logger.info("Client release info updated on server", this.currentReleaseFromServer);
                    this.checkClientRelease();
                });
            });
    }

    private checkClientRelease() {
        const sourceHashFromServer = this.currentReleaseFromServer ? this.currentReleaseFromServer.sourceHash : buildInfo.sourceHash;
        this.clientUpdateRequiredSubject$.next(sourceHashFromServer !== buildInfo.sourceHash);
    }

    private getCurrentClientReleaseInfo() {
        return this.httpClient.get<ClientReleaseInfo>(`${AdaptClientConfiguration.ServiceApiBaseUri}/api/clientreleaseinfo`)
            .pipe(
                retryWhen((errors) => errors.pipe(
                    scan((retryCount) => retryCount + 1, 0),
                    takeWhile((retryCount) => retryCount < 3),
                    delay(60000), // if serviceability api not available, retry after a minute for 3 times
                )),
            );
    }

    public pollClientReleaseInfo() {
        // poll for release change every 3 minutes
        return timer(this.pollingInterval, this.pollingInterval).pipe(
            takeUntil(this.terminatePoll),
            switchMap(() => this.getCurrentClientReleaseInfo()),
            retryWhen((errors) => errors.pipe(
                delay(10000),
            )),
        );
    }
}
